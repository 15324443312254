import React from "react"
import { ILinkShareItem, ImageType } from "@typed/entities"
import { getImagePath } from "@utilities"

type TWorkImageProps = {
	item: ILinkShareItem
}

export const WorkImage: React.FC<TWorkImageProps> = ({ item }) => {
	const imagePath = getImagePath(item.item_client_id, item.owner_cognito_identity, ImageType.detail)

	return <img src={imagePath} className="img-fluid" alt={item.artist} data-testid="work-image" />
}
