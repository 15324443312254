import * as React from "react"
import { Redirect, Route, RouteProps } from "react-router"
import { useSelector } from "react-redux"
import { getLoadingStatus, getLoggedInStatus } from "@store/ducks/auth/selectors"
import * as authActionSets from "@store/ducks/auth/actionSets"

export const PrivateRoute: React.FC<RouteProps> = (props) => {
	const isAuthenticated = useSelector(getLoggedInStatus)
	const isLoading = useSelector(getLoadingStatus(authActionSets.reAuth.request))

	if (isLoading) {
		return <></>
	} else if (!isAuthenticated) {
		const renderComponent = () => <Redirect to={{ pathname: "/login" }} />
		return <Route {...props} component={renderComponent} render={undefined} />
	} else {
		return <Route {...props} />
	}
}
