import React from "react"
import { ICollection } from "@typed/entities/InventoryCollection"

interface ICollectionsListProps {
	collections: ICollection[]
	className: string
	collectionClassName: ((collection: string) => string) | string
	onCollectionClick?: (collection: string) => void
}

export const CollectionsList: React.FC<ICollectionsListProps> = (props) => {
	const { collections, onCollectionClick, collectionClassName } = props

	return (
		<div
			className={props.className}
			onClick={(event) => {
				if (typeof onCollectionClick === "undefined") {
					return
				}

				const target = event.target as HTMLSpanElement
				const collectionKeyAttribute = target.getAttribute("data-collection")
				if (typeof collectionKeyAttribute !== "undefined" && collectionKeyAttribute !== null) {
					onCollectionClick(collectionKeyAttribute)
				}
			}}
		>
			{collections.map((collection) => {
				const className =
					typeof collectionClassName === "function" ? collectionClassName(collection.id) : collectionClassName

				return (
					<span key={collection.id} className={className} data-collection={collection.id}>
						{collection.name}
					</span>
				)
			})}
		</div>
	)
}
