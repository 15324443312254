import { createSelector, Selector } from "reselect"
import { ICollection } from "@typed/entities/InventoryCollection"
import { createBasicParametricSelector, createBasicSelector } from "@store/shared/selectors"
import { TRootState } from "@store/rootReducer"
import { TNormalizedCollectionsSchema } from "@store/ducks/collections/normalized"
import { TRequestStateNames } from "@store/shared/requestState"
import * as actionSets from "@store/ducks/collections/actionSets"

export const getItemCollections = createBasicParametricSelector<
	TNormalizedCollectionsSchema,
	{ itemId: string },
	ICollection[]
>(
	(state) => state.collections.collections,
	(collectionState, props) => {
		const result = []
		for (const collectionId of collectionState.result) {
			const currentCollection = collectionState.entities.collections[collectionId]
			if (currentCollection.item_ids?.includes(props.itemId)) {
				result.push(currentCollection)
			}
		}

		return result
	},
	(props) => props.itemId
)

export const getAllCollections = createSelector<TRootState, TNormalizedCollectionsSchema, ICollection[]>(
	[(state) => state.collections.collections],
	(collectionState) => {
		return collectionState.result.map((collectionId) => {
			return collectionState.entities.collections[collectionId]
		})
	}
)

export const getArchiveCollectionId = createSelector([getAllCollections], (collections) => {
	return collections.find((collection) => collection.kind == "archive")?.id
})

export const getArchivedItemsIdsAsSet = createSelector<
	TRootState,
	TNormalizedCollectionsSchema,
	string | undefined,
	Set<string>
>([(state) => state.collections.collections, getArchiveCollectionId], (collectionState, archiveCollectionId) => {
	const itemsIds = archiveCollectionId ? collectionState.entities.collections[archiveCollectionId].item_ids : []

	return new Set(itemsIds)
})

export const isItemArchived = createBasicParametricSelector<Set<string>, { itemId: string }, boolean>(
	getArchivedItemsIdsAsSet,
	(archivedItemsIdsAsSet, props) => {
		return archivedItemsIdsAsSet.has(props.itemId)
	},
	(props) => props.itemId
)

export const getLoadingStatus = (request: TRequestStateNames<typeof actionSets>): Selector<TRootState, boolean> => {
	return createBasicSelector((state) => {
		return state.collections.requests[request].isLoading
	})
}
