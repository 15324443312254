import inventoryWatchers from "@store/ducks/inventory/sagas"
import { fork } from "redux-saga/effects"
import authWatchers from "@store/ducks/auth/sagas"
import orgsWatcher from "@store/ducks/orgs/sagas"
import collectionsWatchers from "@store/ducks/collections/sagas"
import statusesWatcher from "@store/ducks/statuses/sagas"

export default function* rootSaga(): Generator {
	yield fork(authWatchers)
	yield fork(inventoryWatchers)
	yield fork(orgsWatcher)
	yield fork(collectionsWatchers)
	yield fork(statusesWatcher)
}
