// RequestAccessCode - http response data for checking for checking if work expired / requesting access code sms
export interface IRequestAccessCode {
	is_expired: boolean
	phone_number: string
	shared_by_name: string
}

// RequestAccessCodeInit - contains initial value for state
export const requestAccessCodeInit: IRequestAccessCode = {
	is_expired: false,
	phone_number: "",
	shared_by_name: "",
}
