import { ILinkShareDetails } from "@typed/entities"
import { IOrganisation } from "@typed/api/responses/Organisation"
import { IRequestAccessCode } from "@typed/api/responses/AccessCode"
import { IRemoteAction } from "@typed/api/responses/RemoteActions"
import { IWorkDetails } from "@typed/api/responses/WorkDetails"
import { createAxiosRequest } from "@services/api/implementations/rest/requestWrapper"
import {
	TCreateCollectionRequest,
	TCreateInventoryItemRequest,
	TDeleteInventoryItemRequest,
	TFetchInventoryRequest,
	TFetchOrganisationsRequest,
	TFetchRemoteActionsRequest,
	TFetchShareDetailsRequest,
	TGetCollectionsRequest,
	TGetInventoryItemRequest,
	TGetWorkflowsSchemaRequest,
	TImportFromArtlogicRequest,
	TManageCollectionRequest,
	TRequestAccessCodeRequest,
	TSetCognitoIdentityRequest,
	TUpsertOrgItemRequest,
} from "@services/api/definitions/common/requests"
import { environment } from "@services/environment"

export const fetchShareDetails: TFetchShareDetailsRequest = async (props) => {
	try {
		const { data } = await createAxiosRequest({
			method: "GET",
			url: `${environment.API_SERVER}/linkshare/${props.sharedKey}/${props.accessCode}`,
		})
		const works: ILinkShareDetails = data

		return works
	} catch (error) {
		throw new Error(error)
	}
}

export const requestAccessCode: TRequestAccessCodeRequest = async (props) => {
	try {
		const { data } = await createAxiosRequest({
			method: "GET",
			url: `${environment.API_SERVER}/linkshare/${props.publicKey}`,
		})
		const res: IRequestAccessCode = data
		return res
	} catch (error) {
		throw new Error(error)
	}
}

export const putInventoryItem: TCreateInventoryItemRequest = async (details) => {
	try {
		await createAxiosRequest({
			method: "POST",
			url: `${environment.API_SERVER}/inventory/push`,
			withAuth: true,
			data: [details],
		})
		return { status: true, error: null }
	} catch (error) {
		return { status: true, error }
	}
}

export const upsertOrgItem: TUpsertOrgItemRequest = async (details) => {
	try {
		await createAxiosRequest({
			method: "POST",
			url: `${environment.API_SERVER}/organisation/inventory`,
			withAuth: true,
			data: details,
		})
		return { status: true, error: null }
	} catch (error) {
		return { status: true, error }
	}
}

// TODO: delete this method and just use `putInventoryItem` within sagas
export const deleteInventoryItem: TDeleteInventoryItemRequest = async (props) => {
	await createAxiosRequest({
		method: "POST",
		url: `${environment.API_SERVER}/inventory/push`,
		data: [
			{
				client_id: props.itemClientID,
				is_deleted: true,
			},
		],
		withAuth: true,
	})
}

export const fetchInventory: TFetchInventoryRequest = async (props) => {
	const { updatedAfter } = props
	const hasUpdatedAfter = typeof updatedAfter !== "undefined"
	const { data } = await createAxiosRequest({
		method: "GET",
		url: `${environment.API_SERVER}/inventory`,
		withAuth: true,
		params: {
			get_deleted: false,
			// include_id_list: true,
			...(hasUpdatedAfter && { updated_after: updatedAfter }),
		},
	})

	return data
}

export const getInventoryItem: TGetInventoryItemRequest = async (props) => {
	const { itemId } = props
	const { data } = await createAxiosRequest({
		method: "GET",
		url: `${environment.API_SERVER}/inventory/${itemId}`,
		withAuth: true,
	})

	return data as IWorkDetails
}

export const fetchRemoteActions: TFetchRemoteActionsRequest = async () => {
	const { data } = await createAxiosRequest({
		method: "GET",
		url: `${environment.API_SERVER}/user/actions`,
		withAuth: true,
	})
	const actions: IRemoteAction[] = data
	return actions
}

export const setCognitoIdentity: TSetCognitoIdentityRequest = async (props) => {
	const { data } = await createAxiosRequest({
		method: "PUT",
		url: `${environment.API_SERVER}/user/identity`,
		data: { "cognito.identity": props.identityId },
		withAuth: true,
	})
	const actions: IRemoteAction[] = data
	return actions
}

export const importFromArtlogic: TImportFromArtlogicRequest = async (props) => {
	try {
		await createAxiosRequest({
			method: "POST",
			url: `${environment.API_SERVER}/inventory/import/artlogic`,
			data: {
				url: props.url,
				skip_stock_code: props.skipStockCode,
				ownership_distance: props.ownershipDistance,
				...(props.source && { source: props.source }),
			},
			withAuth: true,
		})
		return { status: true, error: null }
	} catch (error) {
		return { status: false, error: error.response.data }
	}
}

export const fetchOrganisations: TFetchOrganisationsRequest = async () => {
	const { data } = await createAxiosRequest({
		method: "GET",
		url: `${environment.API_SERVER}/organisation`,
		withAuth: true,
	})
	const orgs: IOrganisation[] = data
	return orgs
}

export const getCollections: TGetCollectionsRequest = async () => {
	const { data } = await createAxiosRequest({
		method: "GET",
		url: `${environment.API_SERVER}/collections`,
		withAuth: true,
	})

	return data
}

export const createCollection: TCreateCollectionRequest = async (props) => {
	const { data } = await createAxiosRequest({
		method: "POST",
		url: `${environment.API_SERVER}/collections`,
		withAuth: true,
		data: {
			name: props.name,
			...(props.coverImageUrl && { cover_image_url: props.coverImageUrl }),
		},
	})

	return data
}

export const manageCollection: TManageCollectionRequest = async (props) => {
	const { data } = await createAxiosRequest({
		method: "PUT",
		url: `${environment.API_SERVER}/collections/${props.collectionId}/items`,
		withAuth: true,
		data: {
			// there is also `items_to_set` but we are not using it
			items_to_add: props.itemsIdsToAdd,
			items_to_delete: props.itemsIdsToDelete,
		},
	})

	return data
}

export const getWorkflowsSchema: TGetWorkflowsSchemaRequest = async () => {
	const { data } = await createAxiosRequest({
		method: "GET",
		url: `${environment.API_SERVER}/workflows/schema`,
		withAuth: true,
	})

	return data
}
