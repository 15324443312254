import { environment } from "@services/environment"

const awsmobile = {
	aws_project_region: environment.AWS_REGION,
	aws_cognito_identity_pool_id: environment.AWS_COGNITO_IDENTITY_POOL,
	aws_cognito_region: environment.AWS_REGION,
	aws_user_pools_id: environment.AWS_COGNITO_USER_POOL,
	aws_user_pools_web_client_id: environment.AWS_COGNITO_WEB_CLIENT,
	oauth: {},
	aws_user_files_s3_bucket: environment.AWS_ORIGINAL_S3_BUCKET,
	aws_user_files_s3_bucket_region: environment.AWS_REGION,
}

export default awsmobile
