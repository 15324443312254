import { normalize, NormalizedSchema, schema } from "normalizr"
import { ICollection } from "@typed/entities/InventoryCollection"

const collectionsEntityKey = "collections"

const collection = new schema.Entity(collectionsEntityKey)

const collectionsArraySchema = new schema.Array(collection)

export function normalizeCollections(collectionsData: ICollection[]): TNormalizedCollectionsSchema {
	return normalize(collectionsData, collectionsArraySchema)
}

interface IUpdateCollectionDataParams {
	collectionId: string
	itemsIdsToAdd: string[]
	itemsIdsToDelete: string[]
}

export function updateCollectionData(
	prevState: TNormalizedCollectionsSchema,
	params: IUpdateCollectionDataParams
): TNormalizedCollectionsSchema {
	const result = { ...prevState }
	const collectionItemsIds = (result.entities.collections[params.collectionId].item_ids || []).slice()

	const itemsToDeleteSet = new Set(params.itemsIdsToDelete)
	const filteredCollectionItemsIds = collectionItemsIds.filter((itemId) => !itemsToDeleteSet.has(itemId))

	result.entities.collections[params.collectionId].item_ids = filteredCollectionItemsIds.concat(params.itemsIdsToAdd)
	return result
}

interface ICreateCollectionParams {
	collectionId: string
	collectionName: string
}

export function withCreatedCollection(
	prevState: TNormalizedCollectionsSchema,
	params: ICreateCollectionParams
): TNormalizedCollectionsSchema {
	const result: ICollection[] = prevState.result
		.map((collectionId) => {
			return prevState.entities[collectionsEntityKey][collectionId]
		})
		.concat({
			id: params.collectionId,
			name: params.collectionName,
			kind: "",
			cover_image_url: "",
			item_ids: [],
			pos: 0,
		})

	return normalizeCollections(result)
}

interface INormalizedCollectionsData {
	[collectionsEntityKey]: { [key: string]: ICollection }
}

export type TNormalizedCollectionsSchema = NormalizedSchema<INormalizedCollectionsData, string[]>
