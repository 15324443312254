import React from "react"
import * as styles from "./SearchContainer.module.scss"
import { ReactComponent as SearchIcon } from "@icons/SearchIcon.svg"
import { TProps } from "./types"
import { applyFilterRequest } from "@store/ducks/inventory"
import { connect } from "react-redux"
import { getSearchState } from "@store/ducks/inventory/selectors"
import TextInput from "@components/TextInput"
import { useTranslation } from "react-i18next"

const SearchContainer: React.FC<TProps["Combined"]> = (props) => {
	const { applySearchQueryRequest, searchState } = props
	const { t } = useTranslation()

	return (
		<div className={props.className}>
			<TextInput
				className={styles.searchContainer}
				focusClassName={styles.isFocused}
				placeholder={t("inventory.searchCaption")}
				value={searchState.query}
				onChange={(event) => {
					applySearchQueryRequest({
						query: event.target.value,
					})
				}}
				pre={
					<>
						<span className={styles.searchIcon}>
							<SearchIcon />
						</span>
					</>
				}
			/>
		</div>
	)
}

const mapStateToProps: TProps["State"] = (state) => {
	return {
		searchState: getSearchState(state),
	}
}

const mapDispatchToProps: TProps["Dispatch"] = (dispatch) => {
	return {
		applySearchQueryRequest: (params) => {
			dispatch(applyFilterRequest(params))
		},
	}
}

export const SearchContainerConnected = connect(mapStateToProps, mapDispatchToProps)(SearchContainer)
