import API from "@services/api"
import { AxiosRequestConfig, Method } from "axios"
import axios from "axios"

interface ICreateRequestProps<TData, TParams> {
	method: Method
	url: string
	data?: TData
	withAuth?: boolean
	params?: TParams
}

export async function createAxiosRequest<TData, TParams, TResponse>(
	props: ICreateRequestProps<TData, TParams>
): Promise<TResponse> {
	const { method, data, withAuth, params, url } = props

	const config: AxiosRequestConfig = {
		method,
		url,
		...(typeof params !== "undefined" && { params }),
		...(typeof data !== "undefined" && { data }),
	}

	if (withAuth) {
		const { accessToken } = await API.amplify.getAccessToken()
		config.headers = { Authorization: "Bearer " + accessToken }
	}

	return axios.request(config)
}
