import React, { MutableRefObject, useState } from "react"
import { IAutocompleteEntity } from "@services/autocomplete/definitions"
import { IAutocompleteHandlerProps } from "@enhancers/withAutocomplete"
import * as styles from "./AutocompleteSelection.module.scss"
import * as defaultListStyles from "@components/List/List.module.scss"
import useSuggestionsSearch from "@enhancers/useSuggestionsSearch"
import { List } from "@components/List"
import classNames from "classnames"

export type TSuggestionNodeFC = React.FC<{
	item: {
		id: string
		value: string
	}
}>

export type TCreateButtonFC = React.FC<{
	value: string
	onClick: () => void
}>

export interface IAutocompleteSelectionProps {
	className?: string
	inputPlaceholder?: string
	suggestionNodeFC: TSuggestionNodeFC
	createButtonFC?: TCreateButtonFC
	onSuggestionClick?: (id: string, value: string) => void
	onCreateButtonClick?: (inputValue: string) => void
	sortFn?: (leftCollectionId: string, rightCollectionId: string) => number
	inputRef?: MutableRefObject<HTMLInputElement>
}

/**
 * Component that can be used together with `AutocompleteService` in order to provide nice UX for selecting a tag
 *
 * After any user input we call `AutocompleteService` in order to get results for current query
 * Therefore component need `searchFn` that should be bound to some particular `AutocompleteService` service
 *
 * See `withAutocomplete` enhancer for more info
 */
export const AutocompleteSelection: React.FC<IAutocompleteSelectionProps & IAutocompleteHandlerProps> = (props) => {
	const { className, inputPlaceholder, suggestionNodeFC, createButtonFC, sortFn, inputRef } = props
	const { searchFn, getAllFn } = props
	const onSuggestionClick = typeof props.onSuggestionClick !== "undefined" ? props.onSuggestionClick : () => undefined
	const onCreateButtonClick =
		typeof props.onCreateButtonClick !== "undefined" ? props.onCreateButtonClick : () => undefined

	const [query, setQuery] = useState("")
	const sanitizedQuery = query.trim()
	const isEmptyQuery = sanitizedQuery.length == 0

	const sortedEntities = (entities: IAutocompleteEntity[]): IAutocompleteEntity[] => {
		if (typeof sortFn === "undefined") {
			return entities
		}

		return entities.sort((left, right) => sortFn(left.id, right.id))
	}

	const { suggestions, hasExactMatch } = useSuggestionsSearch({
		getAllFn,
		searchFn,
		query,
		sortFn: sortedEntities,
	})

	return (
		<div className={className}>
			<input
				className={styles.queryInput}
				placeholder={inputPlaceholder}
				value={query}
				onChange={(e) => setQuery(e.target.value)}
				ref={inputRef}
			/>
			{suggestions.length > 0 && (
				<List
					items={suggestions}
					className={classNames(defaultListStyles.listWrapper, styles.suggestionsList)}
					onItemClicked={(item) => onSuggestionClick(item.id, item.value)}
					itemContents={suggestionNodeFC}
				/>
			)}
			{!hasExactMatch &&
				!isEmptyQuery &&
				(typeof createButtonFC !== "undefined" ? (
					createButtonFC({
						value: sanitizedQuery,
						onClick: () => onCreateButtonClick(sanitizedQuery),
					})
				) : (
					<button onClick={() => onCreateButtonClick(sanitizedQuery)}>Create "{sanitizedQuery}"</button>
				))}
		</div>
	)
}
