import React from "react"
import * as styles from "./Tabs.module.scss"
import { currencies, Currency, TCurrencyStrings } from "@typed/entities"
import SelectInputField from "@components/FormInputs/SelectInputField"
import NumericInputField from "@components/FormInputs/NumericInputField"
import { ITabProps } from "@containers/WorkDetailsContainer/types"
import StatusPopover from "@containers/WorkDetailsContainer/StatusPopover"
import { FormInputWrapper } from "@components/FormInputs/FormInputWrapper"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { getCurrencySymbol } from "@utilities/currency/getCurrency"

interface IFinancialTabProps extends ITabProps {
	onAvailabilityChange: (status: string, availability: string) => void
}

const FinancialTab: React.FC<IFinancialTabProps> = (props) => {
	const { control, watch, setValue, onAvailabilityChange } = props
	const { t } = useTranslation()

	const watchGlobalCurrency = watch("fields.globalCurrency")
	const currencySymbol = getCurrencySymbol(watchGlobalCurrency)

	return (
		<div className={styles.tab}>
			<section className={styles.section}>
				<FormInputWrapper label={t("artwork.statusAndAvailability")}>
					<StatusPopover
						statusId={watch("fields.statusId")}
						availabilityId={watch("fields.availabilityId")}
						onChange={(status, availability) => {
							setValue("fields.statusId", status.id)
							setValue("fields.availabilityId", availability.id)
							onAvailabilityChange(status.name, availability.name)
						}}
					/>
				</FormInputWrapper>
			</section>
			<section className={classNames(styles.section, styles.narrow)}>
				<SelectInputField
					label={t("artwork.currency")}
					control={control}
					name="fields.globalCurrency"
					options={currencies.map((currency: TCurrencyStrings) => ({
						value: currency,
						label: Currency[currency].split(" ")[1],
					}))}
					className={styles.inputWrapper}
				/>
				<NumericInputField
					label={t("artwork.costPrice")}
					prefix={`${currencySymbol} `}
					thousandSeparator={true}
					control={control}
					name="fields.costPrice"
					className={styles.inputWrapper}
				/>
				<NumericInputField
					label={t("artwork.askingPrice")}
					prefix={`${currencySymbol} `}
					thousandSeparator={true}
					control={control}
					name="fields.retailPrice"
					className={styles.inputWrapper}
				/>
				<NumericInputField
					label={t("artwork.insuredPrice")}
					prefix={`${currencySymbol} `}
					thousandSeparator={true}
					control={control}
					name="fields.insurancePrice"
					className={styles.inputWrapper}
				/>
			</section>
		</div>
	)
}

export default FinancialTab
