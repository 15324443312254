import { normalize, NormalizedSchema, schema } from "normalizr"
import { ILocationEntity, IWorkDetails } from "@typed/api/responses/WorkDetails"
import { mergeObjectProperties } from "@store/shared/merge"
import { sortLocationEntities } from "@utilities/workProperties/locationEntities"

const artistEntityKey = "artists"
const seriesEntityKey = "series"
const workTypesEntityKey = "work_type"
const inventoryItemsEntityKey = "inventoryItems"

const artist = new schema.Entity(artistEntityKey)
const series = new schema.Entity(seriesEntityKey)
const workTypes = new schema.Entity(workTypesEntityKey)
const inventoryItem = new schema.Entity(
	inventoryItemsEntityKey,
	{
		artist: artist,
		series: series,
		work_type: workTypes,
	},
	{
		processStrategy: (item) => {
			const locationHistory: ILocationEntity[] = (item.location_history || []).slice()
			return {
				...item,
				location_history: locationHistory.sort(sortLocationEntities),
				artist: {
					id: item.artist,
				},
				series: {
					id: item.series,
				},
				work_type: {
					id: item.work_type,
				},
			}
		},
	}
)

const inventoryItems = new schema.Array(inventoryItem)

export function normalizeInventoryItems(inventoryData: IWorkDetails[]): TNormalizedInventorySchema {
	return normalize(inventoryData, inventoryItems)
}

export function putToNormalizedInventoryItems(
	oldData: TNormalizedInventorySchema,
	newData: TNormalizedInventorySchema,
	itemsIdsToDelete: string[]
): TNormalizedInventorySchema {
	const oldEntities = oldData.entities[inventoryItemsEntityKey]
	const newEntities = newData.entities[inventoryItemsEntityKey]
	const mergedEntities = mergeObjectProperties(oldEntities, newEntities) || {}
	const withoutDeletedEntities = removeDeletedItems(mergedEntities, itemsIdsToDelete)

	return normalizeInventoryItems(Object.values(withoutDeletedEntities))
}

export function removeDeletedItems(
	data: Record<string, IWorkDetails>,
	itemsIdsToDelete: string[]
): Record<string, IWorkDetails> {
	const result = { ...data }
	for (const itemIdToDelete of itemsIdsToDelete) {
		delete result[itemIdToDelete]
	}

	return result
}

/**
 * Returns new normalized state of inventory with removed item (re-normalization is used)
 * @param data - existing normalized inventory state
 * @param itemId - id of item that needs to be removed
 */
export function removeInventoryItem(data: TNormalizedInventorySchema, itemId: string): TNormalizedInventorySchema {
	const inventoryItemsEntities = data.entities[inventoryItemsEntityKey]
	if (typeof inventoryItemsEntities !== "undefined") {
		delete inventoryItemsEntities[itemId]
	}

	// simple create new array based on old one normalize it again
	return normalizeInventoryItems(Object.values(inventoryItemsEntities || {}))
}

export interface IWorkDetailsWithDict extends IWorkDetails {
	[key: string]: unknown
}

interface INormalizedInventoryData {
	[inventoryItemsEntityKey]?: { [key: string]: IWorkDetails }
	[artistEntityKey]: { [key: string]: { id: string } }
	[seriesEntityKey]: { [key: string]: { id: string } }
	[workTypesEntityKey]: { [key: string]: { id: string } }
}

export type TNormalizedInventorySchema = NormalizedSchema<INormalizedInventoryData, string[]>
