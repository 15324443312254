import React, { useCallback } from "react"
import { NestedList } from "@components/List"
import * as styles from "./StatusPopover.module.scss"
import ButtonWithPopover from "@components/ButtonWithPopover"
import { IListItem, TProps } from "./types"
import { getAvailability, getStatuses } from "@store/ducks/statuses"
import { connect } from "react-redux"
import { IStatus } from "@typed/api/responses/Workflows"
import classNames from "classnames"
import { ReactComponent as ArrowLongLeft } from "@icons/ArrowLongLeft.svg"
import { ReactComponent as ArrowLongRight } from "@icons/ArrowLongRight.svg"
import IconButton from "@components/IconButton"
import * as animationStyles from "@components/ButtonWithPopover/Animations.module.scss"
import AvailabilityLabel from "@components/AvailabilityLabel"
import * as Sentry from "@sentry/react"

// makes plain structure where statuses are HEAD
function makeLists(statuses: IStatus[]): Record<string, IListItem[]> {
	const result: Record<string, IListItem[]> = {}
	const head: IListItem[] = []
	for (const status of statuses) {
		const statusListId = `status-${status.id}`
		head.push({
			id: status.id,
			name: status.name,
			linkToList: statusListId,
		})
		result[statusListId] = status.availabilities.map((availability) => ({
			id: availability.id,
			name: availability.name,
			symbol: availability.icon?.symbol,
		}))
	}

	result["head"] = head
	return result
}

const StatusPopover: React.FC<TProps["Combined"]> = (props) => {
	const { statuses, status, availability, onChange } = props
	const lists = makeLists(statuses)

	const ButtonComponent = useCallback(
		(props) =>
			status && availability ? (
				<button className={styles.statusButton} {...props}>
					<AvailabilityLabel availabilityIcon={availability?.icon?.symbol} />
					<span>{`${status.name} - ${availability.name}`}</span>
				</button>
			) : null,
		[status, availability]
	)

	if (!status || !availability) {
		return <label>Loading...</label>
	}

	return (
		<ButtonWithPopover
			popoverClassName=""
			popoverNode={({ isShown, closePopover }) => {
				return (
					<NestedList
						lists={lists}
						onItemClicked={(path) => {
							if (path.length !== 2) {
								// normally, this shouldn't happen (although, we should be aware of such case)
								Sentry.captureMessage("<NestedList /> returned path.length != 2")
							} else {
								onChange(path[0], path[1])
							}

							closePopover()
						}}
						headerContents={({ item, onClick }) => (
							<IconButton
								key={item.id}
								icon={<ArrowLongLeft />}
								position="before"
								showLabel
								label={item.name}
								className={classNames(styles.listItem, styles.header)}
								onClick={onClick}
							/>
						)}
						itemContents={({ item, isHead }) =>
							isHead ? (
								<IconButton
									key={item.id}
									icon={<ArrowLongRight />}
									position="after"
									showLabel
									label={item.name}
									className={classNames(styles.listItem, styles.hasNestedList)}
								/>
							) : (
								<button
									key={item.id}
									className={classNames(styles.listItem, {
										[styles.hasNestedList]: isHead,
									})}
								>
									<AvailabilityLabel availabilityIcon={item.symbol} />
									<span>{item.name}</span>
								</button>
							)
						}
						className={classNames(styles.listWrapper, {
							[styles.isShown]: isShown,
						})}
					/>
				)
			}}
			transitionClasses={animationStyles}
			button={ButtonComponent}
			placement="right-start"
			offset={[-7, 3]}
		/>
	)
}

const mapStateToProps: TProps["State"] = (state, ownProps) => {
	const { statusId, availabilityId } = ownProps
	const receivedAvailability = getAvailability(state)({ statusId, availabilityId })

	return {
		statuses: getStatuses(state) || [],
		status: receivedAvailability?.status,
		availability: receivedAvailability?.availability,
	}
}

export const StatusPopoverConnected = connect(mapStateToProps)(StatusPopover)
