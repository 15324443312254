import React, { MutableRefObject, useRef, useState } from "react"
import Skeleton from "react-loading-skeleton"
import * as styles from "./SkeletonLoader.module.scss"
import * as RandomSeed from "random-seed"
import useResizeObserver from "@react-hook/resize-observer"
import classNames from "classnames"

/**
 * Simple component that is used as a loader and tries to mimic actual ListView from its sketch
 */

interface ISkeletonLoaderRowProps {
	nextRandFn: () => number
	fieldsCount: number
}

const SkeletonLoaderRow: React.FC<ISkeletonLoaderRowProps> = (props) => {
	const { nextRandFn, fieldsCount } = props

	return (
		<div className={styles.skeletonWrapper}>
			<Skeleton className={classNames(styles.skeleton, styles.checkbox)} />
			<Skeleton className={classNames(styles.skeleton, styles.thumbnail)} />
			{[...new Array(fieldsCount)]
				.map(() => nextRandFn())
				.map((width, key) => (
					<Skeleton className={classNames(styles.skeleton, styles.property)} width={width} key={key} />
				))}
		</div>
	)
}

export const SkeletonLoader: React.FC = () => {
	const containerRef = useRef() as MutableRefObject<HTMLDivElement>
	const [elementHeight, setElementHeight] = useState(0)
	// render only required number of fake rows
	const rowsCount = Math.ceil(elementHeight / 56)
	// we want layout to look the same on each render, so we use seed-based generation for lengths
	const rand = RandomSeed.create("SkeletonLoader-seed")

	useResizeObserver(containerRef, (rect) => {
		setElementHeight(rect.target.clientHeight)
	})

	return (
		<div className={styles.skeletonContainer} ref={containerRef}>
			{[...new Array(rowsCount)].map((_, key) => (
				<SkeletonLoaderRow fieldsCount={5} nextRandFn={() => rand.intBetween(40, 200)} key={key} />
			))}
		</div>
	)
}
