import { ILinkShareDetails, ILinkShareItem } from "./SharedWork"

export const linkShareItemSample: ILinkShareItem = {
	owner_cognito_identity: "eu-west-1:4d167a7e-0db5-441e-beb9-df0cfb3b8f30",
	item_client_id: "ffc570987829a1817e9f4396c9a6ecdc97187700eca863133a706298e49bee25",
	artist: "Damien Hirst",
	title: "Colours",
	medium: "Canvas",
	year: "2000",
	dim_width: "120",
	dim_height: "120",
	dim_depth: "",
	work_type: "painting",
	location: "Paris, France",
}

export const sharedWorkSample: ILinkShareDetails = {
	shared_by_given_name: "Augustinas",
	shared_by_family_name: "Malinauskas",
	expires_at: new Date(),
	expired: false,
	items: [linkShareItemSample],
}
