import React from "react"
import { IRequestAccessCode } from "@typed/api/responses/AccessCode"

type TExpiredWorkProps = {
	details: IRequestAccessCode
}

export const ExpiredWork: React.FC<TExpiredWorkProps> = ({ details }) => {
	return (
		<div>
			<h4>This share has expired</h4>
			<p>
				{" "}
				Ask {details.shared_by_name} to share it again or join Views to see this and other works{" "}
				{details.shared_by_name} has.
			</p>
		</div>
	)
}
