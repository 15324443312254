import { useEffect } from "react"
import { withRouter } from "react-router-dom"
import { History } from "history"

interface IScrollToTopProps {
	history: History
}

function ScrollToTop(props: IScrollToTopProps) {
	const { history } = props
	useEffect(() => {
		const unlisten = history.listen(() => {
			window.scrollTo(0, 0)
		})
		return () => {
			unlisten()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return null
}

export default withRouter(ScrollToTop)
