import React, { useCallback, useMemo, useState } from "react"
import * as styles from "./WorkDetailsContainer.module.scss"
import IconButton from "@components/IconButton"
import { ReactComponent as CloseIcon } from "@icons/CloseIcon.svg"
import ThumbnailImage from "@components/ThumbnailImage"
import { joinStrings } from "@utilities/strings/formatting"
import { IDetailsForm, IFormImage, IPermanentFormSegments, TProps } from "./types"
import OverviewTab from "@containers/WorkDetailsContainer/tabs/OverviewTab"
import AdditionalDetailsTab from "@containers/WorkDetailsContainer/tabs/AdditionalDetailsTab"
import ProvenanceTab from "@containers/WorkDetailsContainer/tabs/ProvenanceTab"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { tabFields, TImageNameExtractFn, transformFormRequest, validationSchema } from "./form"
import FinancialTab from "@containers/WorkDetailsContainer/tabs/FinancialTab"
import { ICreateItem } from "@typed/api/requests/CreateItem"
import { SubmitErrorHandler, SubmitHandler } from "react-hook-form/dist/types/form"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import processMagicFill from "@containers/WorkDetailsContainer/processMagicFill"
import Loader from "react-loader-spinner"
import useKeyDown from "@enhancers/useKeyDown"
import clone from "just-clone"
import compare from "just-compare"
import { Prompt } from "react-router-dom"
import useBeforeUnload from "@enhancers/useBeforeUnload"
import { useTranslation } from "react-i18next"
import LocationTab from "@containers/WorkDetailsContainer/tabs/LocationTab"
import { useCognito } from "@enhancers/useCongnito"
import { getImagePath } from "@utilities"
import { ImageType } from "@typed/entities"
import { useSelector } from "react-redux"
import { getOrganisations } from "@store/ducks/orgs"

interface IHeaderProps {
	thumbnailSrc: string | undefined
	artist: string
	title: string
	isLoading?: boolean
	onClose: () => void
	onSave: () => void
}

const Header: React.FC<IHeaderProps> = (props) => {
	const { thumbnailSrc, artist, title, isLoading, onClose, onSave } = props
	const { t } = useTranslation()

	return (
		<div className={styles.header}>
			<IconButton icon={<CloseIcon />} label={t("common.close")} className={styles.closeButton} onClick={onClose} />
			<ThumbnailImage
				alt={joinStrings([artist, title], ", ")}
				width={30}
				height={30}
				className={styles.thumbnail}
				emptyImageWidth={20}
				src={thumbnailSrc}
			/>
			<div className={styles.heading}>
				{artist && <span>{artist}</span>}
				{artist && title && ", "}
				{title && <span className={styles.title}>{title}</span>}
			</div>
			<button className={styles.saveButton} onClick={onSave} disabled={isLoading}>
				{isLoading && <Loader type="Oval" color="#c3c3c3" height={15} width={15} />}
				<span className={styles.label}>{t("artwork.saveWork")}</span>
			</button>
		</div>
	)
}

export const WorkDetailsContainer: React.FC<TProps["Combined"]> = (props) => {
	const properties = { ...props.properties }
	const {
		imagePaths,
		onSave,
		onClose,
		isDraggingFile,
		canEditImages,
		collections,
		isNewWork,
		isSavingInProgress,
		isOwnedWork,
	} = props
	const { addToCollection, createCollection, removeFromCollection, onMagicFill } = props
	const thumbnailSrc = imagePaths.length > 0 ? imagePaths[0].src : undefined

	const { t } = useTranslation()
	const [images, setImages] = useState<IFormImage[]>(
		imagePaths.map((image) => ({ src: image.src, name: image.fileName, isUploaded: true, isNew: false }))
	)
	const { cognitoIdentity } = useCognito()

	const { control, handleSubmit, watch, register, setValue } = useForm<IDetailsForm>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			fields: properties,
			temporary: {
				isMagicPasteOpened: isNewWork,
				magicPasteContents: "",
				dimUnit: "cm",
			},
		},
	})

	// this logic is required to deal with images within organisation
	const extractImageName = useCallback<TImageNameExtractFn>(
		(imageItem) => {
			// for existing images and images within owned artwork simply return filename
			if (isOwnedWork || !imageItem.isNew) {
				return imageItem.name
			}

			// otherwise, create full image path
			return getImagePath(properties.itemClientID, cognitoIdentity, imageItem.name, ImageType.fullscreen)
		},
		[cognitoIdentity, isOwnedWork, properties.itemClientID]
	)

	const initialFields = useMemo<IPermanentFormSegments>(
		() =>
			clone({
				fields: watch("fields"),
				images: images,
			}),
		[]
	)
	const noChanges = compare(initialFields, { fields: watch("fields"), images: images })
	useBeforeUnload({
		when: !noChanges,
		confirmMessage: t("warnings.unsavedChanges"),
	})

	useKeyDown("Escape", () => onClose())

	const [tabIndex, setTabIndex] = useState(0)
	const relatedOrganisation = useSelector(getOrganisations)?.[0]

	const onSubmit: SubmitHandler<IDetailsForm> = (data) => {
		const details: ICreateItem = transformFormRequest(
			properties.itemClientID,
			data,
			images,
			extractImageName,
			relatedOrganisation
		)

		onSave(details)
	}

	const onInvalid: SubmitErrorHandler<IDetailsForm> = () => {
		// TODO: for now, we only have required field on the first tab ("Overview")
		setTabIndex(0)
	}

	return (
		<>
			<div className={styles.wrapper}>
				<Header
					thumbnailSrc={thumbnailSrc}
					artist={properties.artist}
					title={isNewWork ? "New artwork" : properties.title}
					isLoading={isSavingInProgress}
					onClose={onClose}
					onSave={handleSubmit(onSubmit, onInvalid)}
				/>
				<div className={styles.contentWrapper}>
					<div className={styles.content}>
						<Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
							<TabList className={styles.tabs}>
								<Tab className={styles.tab} selectedClassName={styles.selected}>
									{t("artwork.overviewTab")}
								</Tab>
								<Tab className={styles.tab} selectedClassName={styles.selected}>
									{t("artwork.additionalDetailsTab")}
								</Tab>
								<Tab className={styles.tab} selectedClassName={styles.selected}>
									{t("artwork.provenanceTab")}
								</Tab>
								<Tab className={styles.tab} selectedClassName={styles.selected}>
									Location
								</Tab>
								<Tab className={styles.tab} selectedClassName={styles.selected}>
									{t("artwork.financialTab")}
								</Tab>
							</TabList>
							<>
								<TabPanel>
									<OverviewTab
										images={images}
										artworkId={properties.itemClientID}
										updateImagePaths={(images) => setImages(images)}
										markImageAsUploaded={(imageName) => {
											setImages((prevImages) => {
												const result = prevImages.slice()
												const imageIndex = result.findIndex((image) => image.name === imageName)
												if (imageIndex < 0) {
													return result
												}

												result[imageIndex] = { ...result[imageIndex], isUploaded: true }
												return result
											})
										}}
										control={control}
										watch={watch}
										setValue={setValue}
										register={register}
										fields={tabFields.overview}
										isDraggingFile={isDraggingFile}
										canEditImages={canEditImages}
										onMagicFillSubmit={async (query) => {
											const details = await processMagicFill(query, setValue, watch("temporary.dimUnit"))
											if (details) {
												onMagicFill(details)
											}
										}}
										relatedOrganisation={relatedOrganisation}
										isOwnedWork={isOwnedWork}
									/>
								</TabPanel>
								<TabPanel>
									<AdditionalDetailsTab
										control={control}
										watch={watch}
										setValue={setValue}
										register={register}
										fields={tabFields.additionalDetails}
										collections={collections}
										addToCollection={addToCollection}
										createCollection={createCollection}
										removeFromCollection={removeFromCollection}
										isNewWork={isNewWork}
									/>
								</TabPanel>
								<TabPanel>
									<ProvenanceTab
										control={control}
										watch={watch}
										setValue={setValue}
										register={register}
										fields={tabFields.provenance}
									/>
								</TabPanel>
								<TabPanel>
									<LocationTab
										control={control}
										watch={watch}
										setValue={setValue}
										register={register}
										fields={tabFields.provenance}
									/>
								</TabPanel>
								<TabPanel>
									<FinancialTab
										control={control}
										watch={watch}
										setValue={setValue}
										register={register}
										fields={tabFields.financial}
										onAvailabilityChange={props.onAvailabilityChange}
									/>
								</TabPanel>
							</>
						</Tabs>
					</div>
				</div>
			</div>
			<Prompt when={!noChanges && !isSavingInProgress} message={t("warnings.unsavedChanges")} />
		</>
	)
}
