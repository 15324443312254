import { useEffect } from "react"

interface IBeforeUnloadHookProps {
	when: boolean
	confirmMessage: string
}

export default function useBeforeUnload(props: IBeforeUnloadHookProps): void {
	const { when, confirmMessage } = props

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			event.preventDefault()
			event.returnValue = confirmMessage
			return confirmMessage
		}

		if (when) {
			window.addEventListener("beforeunload", handleBeforeUnload)
		}

		return () => window.removeEventListener("beforeunload", handleBeforeUnload)
	}, [when, confirmMessage])
}
