import { InventoryActionTypes } from "./types"
import { createAction } from "typesafe-actions"
import { ICreateItem } from "@typed/api/requests/CreateItem"
import { TOptional } from "@utilities/objects/deepMerge"
import { IWorkDetails } from "@typed/api/responses/WorkDetails"
import { TSearchState } from "@store/ducks/inventory"

export const fetchInventoryRequest = createAction(InventoryActionTypes.FetchInventoryRequest)()
export const fetchInventorySuccess = createAction(InventoryActionTypes.FetchInventorySuccess)()
export const fetchInventoryFailure = createAction(InventoryActionTypes.FetchInventoryFailure)()
export const mergeDataIntoInventory = createAction(
	InventoryActionTypes.MergeDataIntoInventory,
	(itemsToPut, itemsIdsToDelete, updatedTimestamp?) => ({ itemsToPut, itemsIdsToDelete, updatedTimestamp })
)<{
	itemsToPut: IWorkDetails[]
	itemsIdsToDelete: string[]
	updatedTimestamp: string | undefined
}>()

export const getInventoryItemRequest = createAction(InventoryActionTypes.GetInventoryItemRequest, (itemId) => ({
	itemId,
}))<{
	itemId: string
}>()
export const getInventoryItemFailure = createAction(InventoryActionTypes.GetInventoryItemFailure, (itemId) => ({
	itemId,
}))<{
	itemId: string
}>()

export const deleteItemRequest = createAction(InventoryActionTypes.DeleteItemRequest, (itemClientID) => ({
	itemClientID,
}))<{
	itemClientID: string
}>()
export const deleteItemSuccess = createAction(InventoryActionTypes.DeleteItemSuccess, (itemClientID) => ({
	itemClientID,
}))<{
	itemClientID: string
}>()
export const deleteItemFailure = createAction(InventoryActionTypes.DeleteItemFailure, (error) => ({
	error,
}))<{
	error: string
}>()

export const putItemRequest = createAction(InventoryActionTypes.PutItemRequest, (itemDetails) => ({
	itemDetails,
}))<{
	itemDetails: ICreateItem
}>()
export const putItemSuccess = createAction(
	InventoryActionTypes.PutItemSuccess,
	(itemDetails, isNewItem, isOrganisationItem) => ({
		itemDetails,
		isNewItem,
		isOrganisationItem,
	})
)<{
	itemDetails: ICreateItem
	isNewItem: boolean
	isOrganisationItem: boolean
}>()
export const putItemFailure = createAction(InventoryActionTypes.PutItemFailure, (error) => ({
	error,
}))<{
	error: string
}>()

export const setSearchQueryRequest = createAction(InventoryActionTypes.SetSearchQueryRequest, (query) => ({ query }))<{
	query: string
}>()
export const setSearchQuerySuccess = createAction(InventoryActionTypes.SetSearchQuerySuccess, (query) => ({ query }))<{
	query: string
}>()

export const setItemSelected = createAction(InventoryActionTypes.SetItemSelected, (itemId, selectedStatus) => ({
	itemId,
	selectedStatus,
}))<{
	itemId: string
	selectedStatus: boolean
}>()

export const applyFilterRequest = createAction(
	InventoryActionTypes.ApplyFilterRequest,
	(params?: TOptional<TSearchState>) => params
)<TOptional<TSearchState> | undefined>()
export const setFilterResult = createAction(InventoryActionTypes.SetFilterResult, (itemIds, isSearch) => ({
	itemIds,
	isSearch,
}))<{
	itemIds: string[]
	isSearch: boolean
}>()

export const setFilterItemsSelected = createAction(InventoryActionTypes.SetFilterItemsSelected, (selectedStatus) => ({
	selectedStatus,
}))<{
	selectedStatus: boolean
}>()

export const clearItemsSelection = createAction(InventoryActionTypes.ClearItemsSelection)()

export const processItemClick = createAction(InventoryActionTypes.ProcessItemClick, (itemId, clickType) => ({
	itemId,
	clickType,
}))<{
	itemId: string
	clickType: "single" | "double"
}>()

export const setItemForPreview = createAction(InventoryActionTypes.SetItemForPreview, (itemId) => ({
	itemId,
}))<{
	itemId: string
}>()
export const clearItemPreview = createAction(InventoryActionTypes.ClearItemPreview)()

export const setupAutocomplete = createAction(InventoryActionTypes.SetupAutocomplete)()
export const insertIntoAutocomplete = createAction(InventoryActionTypes.InsertIntoAutocomplete, (unitKey, data) => ({
	unitKey,
	data,
}))<{
	unitKey: string
	data: { id: string; value: string }[]
}>()
export const resetServices = createAction(InventoryActionTypes.ResetServices)()
