import mixpanel from "mixpanel-browser"
import { environment } from "@services/environment"
import { TAnalyticsEventEmitter } from "@services/analytics/definitions"

export const initialise = (): void => {
	mixpanel.init(environment.MIXPANEL_TOKEN, {
		api_host: "https://api-eu.mixpanel.com",
	})
}

export const identify = (cognitoSub: string, userName: string, userEmail: string): void => {
	// set must be called with identify in order for events to be attached to profile
	mixpanel.identify(cognitoSub)
	mixpanel.people.set({
		$name: userName,
		$email: userEmail,
	})
}

export const trackEvent: TAnalyticsEventEmitter = (event) => {
	mixpanel.track(event.name, event.payload)
}

export const reset = (): void => {
	mixpanel.reset()
}
