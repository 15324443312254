import { createContext } from "react"

/**
 * Embedded mode is used within our iOS app
 * We generally want designs to differ a bit from the usual website in order to enhance UX
 * Read more in https://arttechnologies.atlassian.net/browse/VT-710
 */

interface IEmbeddedContext {
	isEmbedded: boolean
}

export function isEmbeddedMode(query: URLSearchParams): boolean {
	return query.get("utm_source") === "ios-app"
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const EmbeddedContext = createContext<IEmbeddedContext>({ isEmbedded: false })
export default EmbeddedContext
