import { AnyAction } from "redux"
import { action } from "typesafe-actions"
import { TypeConstant } from "typesafe-actions/dist/type-helpers"

export interface IGenericError {
	code?: number | undefined
	message?: string | undefined
}

export interface IStateWithError {
	readonly error: IGenericError | null
}

export function errorReducer<T extends IStateWithError, K extends AnyAction>(state: T, action: K): T {
	if (!action.error) {
		return {
			...state,
			error: null,
		}
	}

	return {
		...state,
		error: {
			...action.payload,
		},
	}
}

/**
 * Action creator of a strongly typed error action
 */
interface IErrorActionCreator<T extends TypeConstant> {
	(payload: IGenericError): {
		type: T
		payload: IGenericError
		error: true
	}
}

/**
 * This interface is not required, but with it we can stick to `createAction` notation
 */
interface IErrorActionCreatorLambda<T extends TypeConstant> {
	(): IErrorActionCreator<T>
}

export function createErrorAction<T extends TypeConstant>(type: T): IErrorActionCreatorLambda<T> {
	return () => (payload) => action(type, payload, undefined, true)
}
