const inchToCmRatio = 2.56 // rounded ratio meaning 1 inch = 2.56 cms
const fixedDigitsAmount = 1 // amount of digits after floating points
type TDimensionsUnit = "cm" | "ins"

export const cmToInches = (valueInCm: number): number => +(valueInCm / inchToCmRatio).toFixed(fixedDigitsAmount)
export const inchToCm = (valueInInch: number): number => +(valueInInch * inchToCmRatio).toFixed(fixedDigitsAmount)

interface IDimensions<T> {
	height?: T
	width?: T
	depth?: T
}

/**
 * Simply transform dimensions from strings to numbers, if possible
 * @param dimensions
 */
export const readDimensions = (dimensions: IDimensions<string | number>): IDimensions<number> => {
	const { height: h, width: w, depth: d } = dimensions
	const [height, width, depth] = [h, w, d].map((value) => {
		return typeof value !== "undefined" ? +value : value
	})

	return { height, width, depth }
}

/**
 * Builds beautiful presentation of item dimensions in cm
 * All parameters should be provided in cm
 */
export const dimensionsStringCm = (dimensions: IDimensions<string | number>): string => {
	return dimensionsString(readDimensions(dimensions), "cm")
}

/**
 * Same as dimensionsStringCm, but also converts cm to inches
 */
export const dimensionsStringIns = (dimensions: IDimensions<string | number>): string => {
	const { height, width, depth } = readDimensions(dimensions)
	const [heightInch, widthInch, depthInch] = [height, width, depth].map((value) => value && value / inchToCmRatio)

	return dimensionsString(
		{
			height: heightInch,
			width: widthInch,
			depth: depthInch,
		},
		"ins"
	)
}

const dimensionsString = (dimensions: IDimensions<number>, unit: TDimensionsUnit): string => {
	const { height, width, depth } = dimensions

	if (typeof height === "undefined" || typeof width === "undefined") {
		return ""
	}

	if (isNaN(height) || isNaN(width)) {
		return ""
	}

	if (height == 0 || width == 0) {
		return ""
	}

	const [heightFmt, widthFmt, depthFmt] = [height, width, depth].map(
		(value) => value && +value.toFixed(fixedDigitsAmount)
	)

	if (typeof depth === "undefined" || isNaN(depth) || depth === 0) {
		return `${heightFmt} x ${widthFmt} ${unit}`
	}
	return `${heightFmt} x ${widthFmt} x ${depthFmt} ${unit}`
}
