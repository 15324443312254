import React, { MutableRefObject, RefObject, useEffect, useRef } from "react"

interface IAsButtonProps {
	button: React.FC<{
		ref: RefObject<HTMLButtonElement>
	}>
	onClick: () => void
}

/**
 * This component can be useful when you want to use some custom component as button with your own clicking logic
 * without any additional DOM elements
 */
export const AsButton: React.FC<IAsButtonProps> = (props) => {
	const { button, onClick } = props
	const ref = useRef() as MutableRefObject<HTMLButtonElement>

	useEffect(() => {
		ref.current.addEventListener("click", onClick)

		return () => ref.current?.removeEventListener("click", onClick)
	}, [ref.current, onClick])

	return button({ ref })
}
