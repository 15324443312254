import React, { useEffect } from "react"
import { connect, useSelector } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { OrganisationMembersList } from "@components/Organisations/OrganisationMembersList"
import { StaticRoutes } from "@routes"
import { fetchUserOrgsRequest, getOrganisations, hasOrganisations } from "@store/ducks/orgs"
import PageLayout from "@layouts/PageLayout"

interface IReduxProps {
	fetchUserOrgsRequest: () => void
}

type TUnifiedProps = IReduxProps & RouteComponentProps

const Organisations: React.FC<TUnifiedProps> = (props) => {
	const orgs = useSelector(getOrganisations)
	const hasOrgs = useSelector(hasOrganisations)

	// fetch organisations on page open
	useEffect(() => {
		props.fetchUserOrgsRequest()

		// if user has no organisations redirect
		// TODO: probably move to saga
		if (!hasOrgs) {
			props.history.push(StaticRoutes.AccountPage)
		}
	}, [])

	return (
		<PageLayout>
			<div className="container pt-5">
				<h1 className="display-4">My organisations</h1>
				{orgs.map((org) => (
					<div key={org.uid} className="row mt-3 shadow-sm">
						<div className="col-12 ">
							<OrganisationMembersList title={org.name} org={org} />
						</div>
					</div>
				))}
			</div>
		</PageLayout>
	)
}

export default connect(null, { fetchUserOrgsRequest })(withRouter(Organisations))
