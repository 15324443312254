import { Reducer } from "redux"
import { AuthActionTypes, TAuthAction, TAuthActionsSets } from "./types"
import * as actionSets from "./actionSets"
import { defaultRequestState, commonReducerCreator, TStateWithRequestStateGroup } from "@store/shared/requestState"

export interface IAuthStoreState extends TStateWithRequestStateGroup<TAuthActionsSets> {
	readonly isLoggedIn: boolean
	readonly given_name: string
	readonly access_token: string
	readonly cognito_identity: string
	readonly cognito_sub: string
}

export const initialAuthState: IAuthStoreState = {
	isLoggedIn: false,
	given_name: "",
	access_token: "",
	cognito_identity: "",
	cognito_sub: "",
	requests: defaultRequestState(actionSets),
}

const commonReducer = commonReducerCreator<IAuthStoreState, TAuthAction>(actionSets)

export const AuthReducer: Reducer<IAuthStoreState, TAuthAction> = (state = initialAuthState, action: TAuthAction) => {
	switch (action.type) {
		case AuthActionTypes.ReAuthSuccess:
		case AuthActionTypes.LoginSuccess:
			return {
				...commonReducer(state, action),
				isLoggedIn: true,
				given_name: action.payload.given_name,
				access_token: action.payload.access_token,
				cognito_sub: action.payload.cognito_sub,
			}
		case AuthActionTypes.GetCognitoIdentitySuccess:
			return {
				...commonReducer(state, action),
				cognito_identity: action.payload.identity,
			}
		case AuthActionTypes.GetCognitoIdentityFailure:
			return {
				...commonReducer(state, action),
				cognito_identity: "",
			}
		case AuthActionTypes.ReAuthFailure:
		case AuthActionTypes.LogoutSuccess:
		case AuthActionTypes.SignupFailure:
			return {
				...commonReducer(state, action),
				isLoggedIn: false,
				given_name: "",
				access_token: "",
				cognito_sub: "",
			}
		default:
			return commonReducer(state, action)
	}
}
