import { useEffect } from "react"
import { IWorkDetails } from "@typed/api/responses/WorkDetails"
import { useDispatch, useSelector } from "react-redux"
import { getInventoryItem, isInventoryItemNotFound } from "@store/ducks/inventory/selectors"
import { getInventoryItemRequest } from "@store/ducks/inventory"
import { getCollectionsRequest } from "@store/ducks/collections"
import { getItemCollections } from "@store/ducks/collections/selectors"
import { ICollection } from "@typed/entities/InventoryCollection"
import { fetchStatusesRequest } from "@store/ducks/statuses"

export type TInventoryItemHookResult = {
	item: IWorkDetails | undefined
	collections: ICollection[]
	notFound: boolean
}

/**
 * This hook will either return info about particular `itemId` or notFound sign
 * @param itemId
 */
export default function useInventoryItem(itemId: string): TInventoryItemHookResult {
	const dispatch = useDispatch()
	const item = useSelector(getInventoryItem(itemId))
	const collections = useSelector(getItemCollections)({ itemId })
	const notFound = useSelector(isInventoryItemNotFound(itemId))

	useEffect(() => {
		if (typeof item === "undefined" && !notFound) {
			dispatch(getInventoryItemRequest(itemId))
			dispatch(getCollectionsRequest())
			dispatch(fetchStatusesRequest())
		}
	}, [item, notFound])

	return { item, collections, notFound }
}
