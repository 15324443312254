import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import API from "@services/api"
import * as CacheService from "@services/CacheService"
import { ILinkShareDetails, sharedWorkSample } from "@typed/entities/SharedWork"
import { HeadMetaTags } from "@components/HeadMetaTags/HeadMetaTags"
import { IRequestAccessCode, requestAccessCodeInit } from "@typed/api/responses/AccessCode"
import ExpiredWork from "@components/ExpiredWork"
import SubmitAccessCode from "@components/SubmitAccessCode"
import LinkShareItems from "@components/LinkShare"
import ErrorMessage from "@components/ErrorMessage"

enum PreviewState {
	init,
	askVerificationCode,
	showDetails,
	expired,
}

interface IURLParams {
	sharedKey: string
}

const Preview: React.FC = () => {
	// currently selected work
	const [share, setShare] = useState<ILinkShareDetails>(sharedWorkSample)
	const [publicDetails, setPublicDetails] = useState<IRequestAccessCode>(requestAccessCodeInit)
	const [uiState, setUIState] = useState<PreviewState>(PreviewState.init)
	const [errorMessage, setErrorMesssage] = useState("")

	// sharedKey - url parameter identifying share
	const { sharedKey } = useParams<IURLParams>()

	// submit access code
	const submitAccessCode = async (accessCode: string) => {
		setErrorMesssage("")
		try {
			const res = await API.common.fetchShareDetails({ sharedKey, accessCode })
			if (!res.expired) {
				setShare(res)
				setUIState(PreviewState.showDetails)
				CacheService.cacheAccessCode(sharedKey, accessCode)
			} else {
				CacheService.deleteCachedKey(sharedKey)
				// this will fetch public details for expired work
				checkValidity()
			}
		} catch (err) {
			setErrorMesssage("Incorrect access code.")
			CacheService.deleteCachedKey(sharedKey)
			console.log(err)
		}
	}

	// checks if share is not expired, fetches public details and requests access code
	const checkValidity = async () => {
		console.log("check validity")
		setErrorMesssage("")
		try {
			const details = await API.common.requestAccessCode({ publicKey: sharedKey })
			setPublicDetails(details)

			if (!details.is_expired) {
				setUIState(PreviewState.askVerificationCode)
			} else {
				setUIState(PreviewState.expired)
			}
		} catch (err) {
			setErrorMesssage("Shared work was not found.")
			console.log(err)
		}
	}

	useEffect(() => {
		const cachedAccessCode = CacheService.getCachedAccessCode(sharedKey)
		if (cachedAccessCode == null) {
			checkValidity()
		} else {
			submitAccessCode(cachedAccessCode)
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<HeadMetaTags
				site_name="x"
				title="Jamie Gourlay is sharing with you"
				description="Find out details of this amazing work"
				image={true}
			/>
			<div className="container pt-5">
				{(() => {
					switch (uiState) {
						case PreviewState.askVerificationCode:
							return <SubmitAccessCode submit={submitAccessCode} details={publicDetails} />
						case PreviewState.showDetails:
							return <LinkShareItems items={share.items} />
						case PreviewState.expired:
							return <ExpiredWork details={publicDetails} />
					}
				})()}
				{errorMessage && <ErrorMessage message={errorMessage} />}
			</div>
		</>
	)
}

export default Preview
