import { FieldValues } from "react-hook-form/dist/types/fields"
import { IHookFormInput } from "@components/FormInputs/types"
import { Controller } from "react-hook-form"
import { FormInputWrapper } from "@components/FormInputs/FormInputWrapper"
import DatePicker from "react-datepicker"
import React from "react"
import "react-datepicker/dist/react-datepicker.css"
import * as styles from "./FormInputs.module.scss"

interface IInputFieldProps<TFieldValues extends FieldValues> extends IHookFormInput<TFieldValues> {
	label: string
	dateFormat: string
	calendarStartDay: number
}

// TODO: we should check that we receive Date as number parameter (not string etc.)
function DateInputField<TFieldValues extends FieldValues>(props: IInputFieldProps<TFieldValues>): JSX.Element {
	const { label, control, name, dateFormat, className, calendarStartDay } = props

	return (
		<Controller
			render={({ field, fieldState }) => (
				<FormInputWrapper className={className} isInvalid={fieldState.invalid} label={label}>
					<DatePicker
						className={styles.input}
						popperClassName={styles.calendarPopper}
						selected={field.value}
						onChange={(value) => {
							field.onChange(value)
						}}
						dateFormat={dateFormat}
						calendarStartDay={calendarStartDay}
					/>
				</FormInputWrapper>
			)}
			name={name}
			control={control}
		/>
	)
}

export default DateInputField
