// Combining reducers
import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"
import { AuthActionTypes, AuthReducer, TAuthAction } from "@store/ducks/auth"
import { InventoryReducer, TInventoryAction } from "@store/ducks/inventory"
import { OrganisationReducer, TOrganisationAction } from "@store/ducks/orgs"
import { CollectionsReducer, TCollectionsAction } from "@store/ducks/collections"
import { StatusesReducer } from "@store/ducks/statuses"
import { History } from "history"
import { TAnalyticsAction } from "@store/ducks/analytics/types"

// here TypeScript is able to auto-derive all typings
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createAppReducer = (history: History) =>
	combineReducers({
		router: connectRouter(history),
		auth: AuthReducer,
		inventory: InventoryReducer,
		organisations: OrganisationReducer,
		collections: CollectionsReducer,
		statuses: StatusesReducer,
	})

export type TRootState = ReturnType<ReturnType<typeof createAppReducer>>
export type TAnyAction = TInventoryAction | TAuthAction | TOrganisationAction | TAnalyticsAction | TCollectionsAction

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createRootReducer = (history: History) => (state: TRootState | undefined, action: TAnyAction) => {
	if (action?.type === AuthActionTypes.LogoutSuccess) {
		return createAppReducer(history)(undefined, action)
	}

	return createAppReducer(history)(state, action)
}

export default createRootReducer
