import React, { ChangeEventHandler } from "react"
import * as styles from "./TextArea.module.scss"

interface ITextAreaProps {
	value: string
	onChange?: ChangeEventHandler<HTMLTextAreaElement>
	className?: string
	placeholder?: string
}

/**
 * Generally, the only reason for having such component is Safari bug that placeholder doesn't respect
 * new lines (\n)
 * @param props
 */
export const TextArea: React.FC<ITextAreaProps> = (props) => {
	const { value, onChange, className, placeholder } = props
	const shouldShowPlaceholder = typeof placeholder !== "undefined" && !value?.length

	return (
		<div className={styles.textareaWrapper}>
			<textarea value={value} onChange={onChange} className={className} />
			{shouldShowPlaceholder && <span className={styles.placeholder}>{placeholder}</span>}
		</div>
	)
}
