import API from "@services/api"
import { call, put, select, takeLatest } from "redux-saga/effects"
import { StatusesActionTypes } from "./types"
import { fetchStatusesFailure, fetchStatusesSuccess } from "./actions"
import { IWorkflowsSchema } from "@typed/api/responses/Workflows"
import { getStatuses } from "@store/ducks/statuses/selectors"
import { removeActionsFromStatuses } from "@store/ducks/statuses/mutators"

function* fetchStatusesWorker() {
	const statuses = yield select(getStatuses)

	// we only fetch for statuses once
	if (typeof statuses !== "undefined") {
		return
	}

	try {
		const workflowsSchema: IWorkflowsSchema = yield call(API.common.getWorkflowsSchema)
		yield put(fetchStatusesSuccess(removeActionsFromStatuses(workflowsSchema.statuses)))
	} catch (error) {
		yield put(fetchStatusesFailure(error))
	}
}

// TODO: add cleanup

export default function* statusesWatcher(): Generator {
	yield takeLatest(StatusesActionTypes.FetchStatusesRequest, fetchStatusesWorker)
}
