import React, { useState } from "react"
import { useForm } from "react-hook-form"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

type TLoginFormProps = {
	submit: (username: string, password: string) => void
}

interface IFormFields {
	password: string
}

const validationSchema = yup.object().shape({
	password: yup.string().required(),
})

export const LoginForm: React.FC<TLoginFormProps> = ({ submit }) => {
	const [username, setUsername] = useState<string>("")

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormFields>({
		resolver: yupResolver(validationSchema),
	})

	const onSubmit = (data: IFormFields) => submit(username, data.password)

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="form-group">
				<label>Phone number</label>
				{/* <input type="text" className="form-control" name="phone" ref={register} /> */}
				<PhoneInput
					country={"gb"}
					value={username}
					onChange={(phone) => setUsername("+" + phone)}
					inputClass="form-input-auth"
					preferredCountries={["gb", "us"]}
					disableDropdown={false}
				/>
				{/* <small className="form-text text-danger">{errors.phone?.message}</small> */}
			</div>
			<div className="form-group">
				<label>Password</label>
				<input type="password" className="form-control form-input-auth" {...register("password")} />
				<small className="form-text text-danger">{errors.password?.message}</small>
			</div>
			<button type="submit" className="btn btn-primary btn-block">
				Login
			</button>
		</form>
	)
}
