/**
 * Compares two sets for equality
 * Function is independent of elements order, it also doesn't perform deep object comparison
 * @param leftSet
 * @param rightSet
 */
export default function areSetsEqual<T>(leftSet: Set<T>, rightSet: Set<T>): boolean {
	if (leftSet.size !== rightSet.size) {
		return false
	}

	for (const leftItem of leftSet) {
		if (!rightSet.has(leftItem)) {
			return false
		}
	}

	return true
}
