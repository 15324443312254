import React, { useState } from "react"
import API from "@services/api"
import PageLayout from "@layouts/PageLayout"
import Loader from "@components/Loader"
import ErrorMessage from "@components/ErrorMessage"
import { TAnalyticsEventEmitter } from "@services/analytics/definitions"
import { connect } from "react-redux"
import { emitAnalyticsEvent } from "@store/ducks/analytics/actions"
import * as checkboxStyles from "@styles/modules/Checkbox.module.scss"
import * as styles from "./ImportArtLogic.module.scss"
import TextInputField from "@components/FormInputs/TextInputField"
import { useForm } from "react-hook-form"
import SelectInputField from "@components/FormInputs/SelectInputField"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { getOwnershipDistanceOptions } from "@typed/entities/OwnershipDistance"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

type TReduxDispatchProps = {
	emitAnalyticsEvent: TAnalyticsEventEmitter
}

interface IArtLogicImportForm {
	url: string
	ownershipDistance: string
	source: string
	importStockNumbers: boolean
}

const validationSchema = yup.object().shape({
	url: yup.string().required(),
	ownershipDistance: yup.string(),
	source: yup.string(),
	importStockNumbers: yup.boolean(),
})

const ImportArtlogic: React.FC<TReduxDispatchProps> = (props) => {
	const { emitAnalyticsEvent } = props
	const { t, ready } = useTranslation(undefined, { useSuspense: false })

	const [isLoading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")
	const [done, setDone] = useState(false)

	const submit = async (form: IArtLogicImportForm) => {
		const { url, importStockNumbers, source, ownershipDistance } = form

		setErrorMessage("")
		setDone(false)
		setLoading(true)

		const { status, error } = await API.common.importFromArtlogic({
			url,
			skipStockCode: !importStockNumbers,
			source: source,
			ownershipDistance: ownershipDistance,
		})
		setLoading(false)
		setDone(status)

		if (!status) {
			setErrorMessage(error || "Unknown error")
		} else {
			emitAnalyticsEvent({
				name: "Import Works",
				payload: {
					"Import Type": "Artlogic URL",
				},
			})
		}
	}

	const { control, handleSubmit, register } = useForm<IArtLogicImportForm>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			ownershipDistance: "unknown",
		},
	})

	if (!ready) {
		return <></>
	}

	return (
		<PageLayout>
			<div className={styles.page}>
				<h1 className={styles.header}>{t("importFiles.artlogicImportTitle")}</h1>
				<p className={styles.description}>{t("importFiles.artlogicImportDesc")}</p>

				<TextInputField
					label={t("importFiles.privateViewsLink")}
					control={control}
					name="url"
					className={classNames(styles.inputWrapper, styles.url)}
					placeholder="https://privateviews.artlogic.net/2/0b94bca8b4c9155e"
					disabled={isLoading}
				/>
				<SelectInputField
					label={t("artwork.connectionToWork")}
					control={control}
					name="ownershipDistance"
					options={getOwnershipDistanceOptions(t)}
					className={classNames(styles.inputWrapper, styles.ownershipDistance)}
					disabled={isLoading}
				/>
				<TextInputField
					label={t("artwork.source")}
					control={control}
					name="source"
					className={classNames(styles.inputWrapper, styles.source)}
					disabled={isLoading}
				/>

				<div className={styles.checkboxWrapper}>
					<input
						className={checkboxStyles.checkbox}
						type="checkbox"
						id="importStockNumbers"
						{...register("importStockNumbers")}
						disabled={isLoading}
					/>
					<label className={styles.label} htmlFor="importStockNumbers">
						{t("importFiles.importStockNumbers")}
					</label>
				</div>
				<button className={styles.actionButton} onClick={handleSubmit(submit)} disabled={isLoading}>
					{t("importFiles.import")}
				</button>

				{isLoading && <Loader />}
				{errorMessage && <ErrorMessage message={errorMessage} />}
				{done && !errorMessage && (
					<div className="alert alert-success mt-5" role="alert">
						{t("importFiles.importSuccess")}
					</div>
				)}
			</div>
		</PageLayout>
	)
}

export const ImportArtlogicConnected = connect(undefined, { emitAnalyticsEvent })(ImportArtlogic)
