import React, { ChangeEventHandler, useState } from "react"
import classNames from "classnames"

interface ITextInputProps {
	className?: string
	focusClassName?: string
	placeholder?: string
	onChange?: ChangeEventHandler<HTMLInputElement>
	value?: string
	pre?: React.ReactNode
	post?: React.ReactNode
}

export const TextInput: React.FC<ITextInputProps> = (props) => {
	const { pre, post, className, focusClassName, placeholder, onChange, value } = props
	const [wrapperClassName, setWrapperClassName] = useState<string | undefined>(className)

	return (
		<div className={wrapperClassName}>
			{pre}
			<input
				onFocus={() => {
					if (typeof focusClassName !== "undefined") {
						setWrapperClassName(classNames(className, focusClassName))
					}
				}}
				onBlur={() => {
					if (typeof focusClassName !== "undefined") {
						setWrapperClassName(className)
					}
				}}
				placeholder={placeholder}
				onChange={onChange}
				value={value || ""}
			/>
			{post}
		</div>
	)
}
