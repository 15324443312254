import { ITranslatedFunction } from "@services/i18n"

export function getItemsPerRowCount(containerWidth: number, minItemWidth: number): number {
	// just trying to fit as many minimum items as possible
	// width range for N items is [minItemWidth, (N + 1) * minItemWidth / N - 1]
	return Math.floor(containerWidth / minItemWidth)
}

// TODO: probably duplicating i18n from ItemsListRow
export function getItemSelectionTitle(checked: boolean): ITranslatedFunction<string> {
	return (t) => {
		return checked ? t("itemActions.deselect") : t("itemActions.select")
	}
}
