import React, { MouseEvent } from "react"
import * as styles from "./IconButton.module.scss"
import classNames from "classnames"

/**
 * Wrapper for a single action button
 */

interface IIconButtonProps {
	onClick?: (e: MouseEvent<HTMLButtonElement>) => void
	icon: React.ReactNode
	label: string // label is required for a11y
	id?: string
	type?: "submit" | "reset" | "button"
	"aria-expanded"?: boolean
	"aria-controls"?: string
	className?: string
	showLabel?: boolean
	disabled?: boolean
	position?: "before" | "after"
}

export const IconButton = React.forwardRef<HTMLButtonElement, IIconButtonProps>((props, ref) => {
	const { onClick, icon, label, className, showLabel, disabled, position, id, type } = props
	const combinedClassName = classNames(styles.iconButton, {
		[styles.isSingleIcon]: !showLabel,
		[`${className}`]: !!className,
	})

	return (
		<button
			ref={ref}
			id={id}
			type={type}
			aria-expanded={props["aria-expanded"]}
			aria-controls={props["aria-controls"]}
			className={combinedClassName}
			onClick={(e) => {
				if (typeof onClick === "undefined") {
					return
				}

				e.stopPropagation()
				onClick(e)
			}}
			aria-label={label}
			title={label}
			disabled={disabled}
		>
			{position != "after" && icon}
			{showLabel && <span>{label}</span>}
			{position == "after" && icon}
		</button>
	)
})
