import { TRootState } from "@store/store"
import { createBasicParametricSelector, createBasicSelector } from "@store/shared/selectors"
import { createSelector, Selector } from "reselect"
import { TRequestStateNames } from "@store/shared/requestState"
import * as actionSets from "./actionSets"
import { IWorkDetails } from "@typed/api/responses/WorkDetails"

export const getInventoryItemsEntities = createBasicSelector((state) => {
	return state.inventory.items.entities?.inventoryItems || {}
})

export const getInventoryItemsIds = createBasicSelector((state) => {
	return state.inventory.items.result
})

/**
 * Selects either all items available in the state, or items with provided `itemsIds`
 * @param itemsIds - if provided, will return only items with following ids
 */
export const getInventoryItems = createBasicParametricSelector<
	Record<string, IWorkDetails>,
	{ itemsIds?: string[] },
	IWorkDetails[]
>(
	(state) => getInventoryItemsEntities(state),
	(inventoryItems, props) => {
		if (typeof props.itemsIds === "undefined") {
			return Object.values(inventoryItems)
		}

		const selectedInventoryItems: IWorkDetails[] = []
		for (const itemId of props.itemsIds) {
			const inventoryItem = inventoryItems[itemId]
			if (typeof inventoryItem !== "undefined") {
				selectedInventoryItems.push(inventoryItems[itemId])
			}
		}

		return selectedInventoryItems
	}
)

export const getInventoryItemsCount = createSelector([getInventoryItems], (getInventoryItems) => {
	return getInventoryItems({}).length
})

export const getInventoryItem = (itemId: string): Selector<TRootState, IWorkDetails> => {
	return createSelector([getInventoryItemsEntities], (inventoryItems) => {
		return inventoryItems[itemId]
	})
}

export const isInventoryItemNotFound = (itemId: string): Selector<TRootState, boolean> => {
	return createBasicSelector((state) => {
		return typeof state.inventory.notFoundItems[itemId] !== "undefined"
	})
}

export const isInventoryItemSelected = (itemId: string): Selector<TRootState, boolean> => {
	return createBasicSelector((state) => {
		return Boolean(state.inventory.selectedItems[itemId])
	})
}

export const isInventoryItemInPreview = (itemId: string): Selector<TRootState, boolean> => {
	return createBasicSelector((state) => {
		return Boolean(state.inventory.itemIdInPreview === itemId)
	})
}

// Returns inventory search query
export const getSearchQuery = createBasicSelector((state) => state.inventory.search)

export const getLastFetchTimestamp = createBasicSelector((state) => state.inventory.lastItemsFetchTimestamp)

export const getLoadingStatus = (request: TRequestStateNames<typeof actionSets>): Selector<TRootState, boolean> => {
	return createBasicSelector((state) => {
		return state.inventory.requests[request].isLoading
	})
}

export const getErrorMsg = (
	request: TRequestStateNames<typeof actionSets>
): Selector<TRootState, string | undefined> => {
	return createBasicSelector((state) => {
		return state.inventory.requests[request].error?.message
	})
}

export const getSelectedItemsAsDict = createBasicSelector((state) => {
	return state.inventory.selectedItems
})

export const getSelectedItemsAsArray = createSelector([getSelectedItemsAsDict], (selectedItemsAsDict) => {
	return Object.keys(selectedItemsAsDict).reverse()
})

export const getFilteredItems = createBasicSelector((state) => {
	return state.inventory.filter.result
})

export const areAnyFilterItemsSelected = createSelector(
	[getSelectedItemsAsDict, getFilteredItems],
	(selectedItemsAsDict, filteredItems) => {
		for (const itemId of filteredItems) {
			if (selectedItemsAsDict[itemId]) {
				return true
			}
		}

		return false
	}
)

export const areAllFilterItemsSelected = createSelector(
	[getSelectedItemsAsDict, getFilteredItems],
	(selectedItemsAsDict, filteredItems) => {
		if (filteredItems.length === 0) {
			return false
		}

		for (const itemId of filteredItems) {
			if (!selectedItemsAsDict[itemId]) {
				return false
			}
		}

		return true
	}
)

export const getSearchState = createBasicSelector((state) => {
	return state.inventory.filter.params
})

export const itemIdInPreview = createBasicSelector((state) => {
	return state.inventory.itemIdInPreview
})

export const getArtistItemsIds = createBasicParametricSelector<
	Record<string, IWorkDetails>,
	{ artist: string },
	string[]
>(
	(state) => state.inventory.items.entities.inventoryItems || {},
	(items, props) => {
		const result = []

		const itemsArray = Object.values(items)
		for (const item of itemsArray) {
			if (item.artist == props.artist) {
				result.push(item.id)
			}
		}

		return result
	},
	(props) => props.artist
)

export const getAllLocations = createSelector([getInventoryItemsEntities], (inventoryItemsEntities) => {
	const itemsAsArray = Object.values(inventoryItemsEntities)
	const result: Record<string, string> = {}
	itemsAsArray.forEach((item) => {
		item.location_history?.forEach((historyEntity) => {
			result[historyEntity.name] = historyEntity.name
		})
	})

	return result
})
