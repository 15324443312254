import React from "react"
import Disclosure from "@components/Disclosure"
import * as styles from "./MagicFill.module.scss"
import IconButton from "@components/IconButton"
import { ReactComponent as ArrowDownIcon } from "@icons/ArrowDownIcon.svg"
import { ReactComponent as ArrowUpIcon } from "@icons/ArrowUpIcon.svg"
import { useTranslation } from "react-i18next"
import TextArea from "@components/TextArea"

interface IMagicFillProps {
	onSubmit: (contents: string) => void
	className?: string
	placeholder?: string
	open: boolean
	setOpen: (state: boolean) => void
	contents: string
	setContents: (contents: string) => void
}

const MagicFill: React.FC<IMagicFillProps> = (props) => {
	const { onSubmit, className, placeholder, open, setOpen, contents, setContents } = props
	const { t } = useTranslation()
	const emptyContents = contents.trim().length == 0

	return (
		<div className={className}>
			<Disclosure
				open={open}
				setOpen={setOpen}
				button={({ open, onClick }) => (
					<IconButton
						label={t("artwork.magicPasteTitle")}
						icon={open ? <ArrowUpIcon /> : <ArrowDownIcon />}
						showLabel
						position="after"
						className={styles.disclosureButton}
						onClick={onClick}
					/>
				)}
				contents={
					<div className={styles.magicPaste}>
						<div className={styles.description}>{t("artwork.magicPasteDesc")}</div>
						<TextArea
							value={contents}
							onChange={(e) => setContents(e.target.value)}
							className={styles.textarea}
							placeholder={placeholder}
						/>
						<button className={styles.submit} onClick={() => onSubmit(contents)} disabled={emptyContents}>
							{t("artwork.magicPasteSubmit")}
						</button>
					</div>
				}
			/>
		</div>
	)
}

export default MagicFill
