import { call, put, takeLatest, select, throttle } from "redux-saga/effects"
import API from "@services/api"
import {
	deleteItemRequest,
	deleteItemFailure,
	deleteItemSuccess,
	fetchInventorySuccess,
	fetchInventoryFailure,
	mergeDataIntoInventory,
	setSearchQueryRequest,
	setSearchQuerySuccess,
	putItemRequest,
	putItemFailure,
	putItemSuccess,
	setFilterResult,
	applyFilterRequest,
	clearItemPreview,
	setItemSelected,
	processItemClick,
	setItemForPreview,
	getInventoryItemRequest,
	getInventoryItemFailure,
	insertIntoAutocomplete,
} from "@store/ducks/inventory/actions"
import { InventoryActionTypes } from "@store/ducks/inventory/types"
import { StaticRoutes } from "@routes"
import { push } from "connected-react-router"
import { getFormattedTimestamp } from "@utilities"
import {
	getSearchState,
	getInventoryItem,
	getInventoryItems,
	getInventoryItemsIds,
	getLastFetchTimestamp,
	getSelectedItemsAsDict,
	itemIdInPreview,
} from "@store/ducks/inventory/selectors"
import * as inventorySearchService from "@services/search"
import { IWorkDetails } from "@typed/api/responses/WorkDetails"
import { ItemOrigin } from "@typed/entities/ItemOrigin"
import * as autocompleteService from "@services/autocomplete"
import { getCollectionsRequest } from "@store/ducks/collections"
import { fetchStatusesRequest } from "@store/ducks/statuses"
import { TOldInventory } from "@typed/api/responses/Inventory"
import * as mutators from "./mutators"
import { getWorkTypesValues } from "@typed/entities"
import { TSearchState } from "@store/ducks/inventory"

function* setupAutocompleteWorker() {
	try {
		const data: string[] = yield call(API.static.getArtists)
		yield put(
			insertIntoAutocomplete(
				"artists",
				data.map((artist) => ({ id: artist, value: artist }))
			)
		)
	} catch {
		console.error("Unable to get data for autocomplete")
	}

	const workTypes = getWorkTypesValues()
	yield put(
		insertIntoAutocomplete(
			"workType",
			workTypes.map((workType) => ({ id: workType, value: workType }))
		)
	)
}

function* insertIntoAutocompleteWorker(action: ReturnType<typeof insertIntoAutocomplete>) {
	const { unitKey, data } = action.payload
	yield call(autocompleteService.insert, unitKey, data)
}

function* fetchInventoryWorker() {
	const updatedAfter = yield select(getLastFetchTimestamp)
	const hasUpdatedAfter = typeof updatedAfter !== "undefined"

	try {
		const inventoryResponse: TOldInventory = yield call(API.common.fetchInventory, {
			...(hasUpdatedAfter && { updatedAfter }),
		})
		const mappedInventoryResponse = mutators.mappedWorkType(inventoryResponse)
		const currentTimestamp = getFormattedTimestamp(new Date())
		yield put(mergeDataIntoInventory(mappedInventoryResponse, [], currentTimestamp))

		// after success request, also fetch collections & statuses
		yield put(getCollectionsRequest())
		yield put(fetchStatusesRequest())
	} catch (error) {
		yield put(fetchInventoryFailure())
	}
}

function* getInventoryItemWorker(action: ReturnType<typeof getInventoryItemRequest>) {
	const { itemId } = action.payload

	try {
		const item: IWorkDetails = yield call(API.common.getInventoryItem, { itemId })
		const [mappedItem] = mutators.mappedWorkType([item])
		yield put(mergeDataIntoInventory([mappedItem], []))
	} catch (error) {
		yield put(getInventoryItemFailure(itemId))
	}
}

function* mergeDataIntoInventoryWorker(action: ReturnType<typeof mergeDataIntoInventory>) {
	const mergedItemsFn = yield select(getInventoryItems)
	const mergedItems = mergedItemsFn({
		itemsIds: action.payload.itemsToPut.map((item) => item.id),
	})

	yield call(inventorySearchService.removeItems, action.payload.itemsIdsToDelete)
	yield call(inventorySearchService.putItems, mergedItems)

	const state = yield select()
	yield call(autocompleteService.update, state)

	// here we only state that `fetchInventory` succeeded when we have filtered results
	// TODO: this probably could be re-organized (maybe having `isLoading` state for filtering engine should work)
	if (typeof action.payload.updatedTimestamp !== "undefined") {
		yield put(fetchInventorySuccess())
	}

	yield put(applyFilterRequest())
}

function* putItemWorker(action: ReturnType<typeof putItemRequest>) {
	const { itemDetails } = action.payload

	const existingItem: IWorkDetails = yield select(getInventoryItem(itemDetails.client_id))
	const isNewItem = typeof existingItem === "undefined"
	const isOrganisationItem = existingItem?.origin === ItemOrigin.org

	try {
		yield call(API.common.putInventoryItem, itemDetails)
		yield put(push(StaticRoutes.UserInventory))
		yield put(putItemSuccess(itemDetails, isNewItem, isOrganisationItem))
	} catch (error) {
		yield put(putItemFailure(error))
	}
}

function* deleteItemWorker(action: ReturnType<typeof deleteItemRequest>) {
	const { itemClientID } = action.payload

	// clearing current preview before removing item
	yield put(setItemSelected(itemClientID, false))
	const currentItemIdInPreview = yield select(itemIdInPreview)
	if (currentItemIdInPreview === itemClientID) {
		yield put(clearItemPreview())
	}

	try {
		// applying locally
		yield put(mergeDataIntoInventory([], [itemClientID]))

		// sending API request
		yield call(API.common.deleteInventoryItem, { itemClientID })
		yield put(deleteItemSuccess(itemClientID))
		//yield put(push(StaticRoutes.UserInventory))
	} catch (error) {
		yield put(deleteItemFailure(error))
	}
}

function* resetServicesWorker() {
	yield call(inventorySearchService.clear)
	yield call(autocompleteService.clear)
}

function* setSearchQueryWorker(action: ReturnType<typeof setSearchQueryRequest>) {
	yield put(setSearchQuerySuccess(action.payload.query))
}

function* applySearchQueryWorker(action: ReturnType<typeof applyFilterRequest>) {
	const searchState: TSearchState = yield select(getSearchState)
	const searchParams = mutators.buildSearchParams(searchState)

	const isSearch = typeof action.payload !== "undefined"
	if (inventorySearchService.hasEmptyParams(searchParams)) {
		const allItemsIds: string[] = yield select(getInventoryItemsIds)
		yield put(setFilterResult(allItemsIds, isSearch))
	} else {
		const results = yield call(inventorySearchService.search, searchParams)
		yield put(setFilterResult(results, isSearch))
	}
}

function* itemClickWorker(action: ReturnType<typeof processItemClick>) {
	const { itemId, clickType } = action.payload
	const selectedItems = yield select(getSelectedItemsAsDict)

	if (Object.values(selectedItems).length > 0) {
		yield put(setItemSelected(itemId, !selectedItems[itemId]))
	} else if (clickType === "double") {
		yield put(push(`${StaticRoutes.EditWork}/${itemId}`))
	} else {
		yield put(setItemForPreview(itemId))
	}
}

export default function* inventoryWatchers(): Generator {
	yield takeLatest(InventoryActionTypes.FetchInventoryRequest, fetchInventoryWorker)
	yield takeLatest(InventoryActionTypes.GetInventoryItemRequest, getInventoryItemWorker)
	yield takeLatest(InventoryActionTypes.PutItemRequest, putItemWorker)
	yield takeLatest(InventoryActionTypes.DeleteItemRequest, deleteItemWorker)
	yield takeLatest(InventoryActionTypes.ResetServices, resetServicesWorker)
	yield takeLatest(InventoryActionTypes.SetSearchQueryRequest, setSearchQueryWorker)
	yield takeLatest(InventoryActionTypes.MergeDataIntoInventory, mergeDataIntoInventoryWorker)
	yield takeLatest(InventoryActionTypes.ProcessItemClick, itemClickWorker)
	yield takeLatest(InventoryActionTypes.SetupAutocomplete, setupAutocompleteWorker)
	yield takeLatest(InventoryActionTypes.InsertIntoAutocomplete, insertIntoAutocompleteWorker)
	yield throttle(500, InventoryActionTypes.ApplyFilterRequest, applySearchQueryWorker)
}
