import React from "react"
import { Controller } from "react-hook-form"
import * as styles from "./FormInputs.module.scss"
import { IHookFormInput } from "@components/FormInputs/types"
import { FieldValues } from "react-hook-form/dist/types/fields"
import { deriveErrorMessage } from "@components/FormInputs/common"
import { FormInputWrapper } from "@components/FormInputs/FormInputWrapper"
import classNames from "classnames"

interface IInputFieldProps<TFieldValues extends FieldValues> extends IHookFormInput<TFieldValues> {
	label: string
	multiline?: boolean
}

function TextInputField<TFieldValues extends FieldValues>(props: IInputFieldProps<TFieldValues>): JSX.Element {
	const { label, control, name, className, multiline, placeholder, disabled } = props

	return (
		<Controller
			render={({ field, fieldState }) => (
				<FormInputWrapper className={className} label={label} isInvalid={fieldState.invalid}>
					{multiline ? (
						<textarea
							className={classNames(styles.input, styles.multiline)}
							value={field.value || ""}
							onChange={(e) => field.onChange(e.target.value)}
							placeholder={placeholder}
							disabled={disabled}
						/>
					) : (
						<input
							className={styles.input}
							value={field.value || ""}
							onChange={(e) => field.onChange(e.target.value)}
							placeholder={placeholder}
							disabled={disabled}
						/>
					)}
					{fieldState.error && <span className={styles.errorMessage}>{deriveErrorMessage(fieldState.error)}</span>}
				</FormInputWrapper>
			)}
			name={name}
			control={control}
		/>
	)
}

export default TextInputField
