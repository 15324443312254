import React from "react"
import * as tabsStyles from "@containers/WorkDetailsContainer/tabs/Tabs.module.scss"
import { TProps } from "./types"
import { insertIntoAutocomplete } from "@store/ducks/inventory"
import { connect } from "react-redux"
import TextInputField from "@components/FormInputs/TextInputField"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import LocationHistoryTable from "@containers/LocationHistoryTable"
import TemporaryAdmissionHistoryTable from "@containers/TemporaryAdmissionHistoryTable"
import * as styles from "./LocationTab.module.scss"

const LocationTab: React.FC<TProps["Combined"]> = (props) => {
	const { control } = props
	const { t } = useTranslation()
	const { insertLocationIntoAutocomplete } = props

	return (
		<>
			<div className={classNames(tabsStyles.tab, styles.locationTab)}>
				<section className={classNames(tabsStyles.section, tabsStyles.fullWidth)}>
					<LocationHistoryTable control={control} insertLocationIntoAutocomplete={insertLocationIntoAutocomplete} />
				</section>
				<section className={classNames(tabsStyles.section, tabsStyles.wide, styles.notesSection)}>
					<TextInputField
						label={t("artwork.locationNotes")}
						control={control}
						name="fields.location"
						className={tabsStyles.inputWrapper}
						multiline
					/>
				</section>
				<section className={classNames(tabsStyles.section, tabsStyles.largeMargin, tabsStyles.fullWidth)}>
					<TemporaryAdmissionHistoryTable control={control} />
				</section>
				<section className={classNames(tabsStyles.section, tabsStyles.wide, styles.notesSection)}>
					<TextInputField
						label={t("artwork.temporaryAdmissionNotes")}
						control={control}
						name="fields.temporaryAdmissionNotes"
						className={tabsStyles.inputWrapper}
						multiline
					/>
				</section>
			</div>
		</>
	)
}

const mapDispatchToProps: TProps["Dispatch"] = (dispatch) => {
	return {
		// as long as we store location data in a temporary form state,
		// we still need some way to pass added names into autocomplete indexes
		insertLocationIntoAutocomplete: (locationName) =>
			dispatch(
				// TODO: for some reason, we don't get type-checking here
				insertIntoAutocomplete("locationNames", [
					{
						id: locationName,
						value: locationName,
					},
				])
			),
	}
}

export const LocationTabConnected = connect(undefined, mapDispatchToProps)(LocationTab)
