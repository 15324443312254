import React, { useCallback, useMemo } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { ICreateItem } from "@typed/api/requests/CreateItem"
import { connect } from "react-redux"
import { putItemRequest } from "@store/ducks/inventory"
import { emitAnalyticsEvent } from "@store/ducks/analytics/actions"
import { TAnalyticsEventEmitter } from "@services/analytics/definitions"
import { useDropzone } from "react-dropzone"
import getCurrency from "@utilities/currency/getCurrency"
import { v4 as uuidv4 } from "uuid"
import { StaticRoutes } from "@routes"
import WorkDetailsContainer, { IFormFields } from "@containers/WorkDetailsContainer"
import useStatuses from "@enhancers/useStatuses"
import * as standaloneEvents from "@services/analytics/implementations/standaloneEvents"
import getDefaultStatus from "@utilities/statuses/getDefaultStatus"
import { TRootState } from "@store/rootReducer"
import * as inventorySelectors from "@store/ducks/inventory/selectors"
import * as inventoryActionSets from "@store/ducks/inventory/actionSets"
import { useTranslation } from "react-i18next"

const defaultWork = (itemClientID: string, statusId: string, availabilityId: string): IFormFields => ({
	itemClientID,
	artist: "",
	title: "",
	medium: "",
	year: "",
	dimWidth: "",
	dimHeight: "",
	dimDepth: "",
	dimsOther: "",
	localDimUnit: "cm",
	notes: "",
	accessDistance: "own",
	source: "",
	costPrice: "",
	retailPrice: "",
	provenance: "",
	exhibitionHistory: "",
	literature: "",
	stockCode: "",
	insurancePrice: "",
	confidentialNote: "",
	location: "",
	workType: "",
	globalCurrency: getCurrency(),
	series: "",
	statusId,
	availabilityId,
	editionDetails: "",
	locationHistory: [],
	temporaryAdmission: [],
	temporaryAdmissionNotes: "",
	sharedWithOrganisation: true,
})

interface IReduxStateProps {
	isSaving: boolean
}

interface IReduxProps {
	putItemRequest: (details: ICreateItem) => void
	emitAnalyticsEvent: TAnalyticsEventEmitter
}

type TCombinedProps = IReduxStateProps & IReduxProps & RouteComponentProps

const NewWork: React.FC<TCombinedProps> = (props) => {
	const itemClientID = useMemo(() => uuidv4(), [])
	const { history, emitAnalyticsEvent, isSaving } = props

	const performCreate = async (details: ICreateItem) => {
		props.putItemRequest(details)
	}

	const { getRootProps, isDragActive } = useDropzone()
	const { statuses } = useStatuses()

	const handleClose = useCallback(() => history.push(StaticRoutes.UserInventory), [])
	const handleMagicFill = useCallback((details) => standaloneEvents.magicFill(emitAnalyticsEvent, details), [])
	const handleAvailabilityChange = useCallback(
		(status, availability) => standaloneEvents.availabilityChange(emitAnalyticsEvent, { status, availability }),
		[]
	)
	const handleUnsupportedFeature = useCallback(() => undefined, [])
	const { ready } = useTranslation(undefined, { useSuspense: false })

	if (typeof statuses === "undefined") {
		return <></>
	}

	if (!ready) {
		return <></>
	}

	const { defaultStatusId, defaultAvailabilityId } = getDefaultStatus(statuses)

	return (
		<div {...getRootProps()}>
			<WorkDetailsContainer
				isNewWork={true}
				isOwnedWork={true}
				properties={defaultWork(itemClientID, defaultStatusId, defaultAvailabilityId)}
				imagePaths={[]}
				collections={[]}
				canEditImages={true}
				isDraggingFile={isDragActive}
				onSave={performCreate}
				onClose={handleClose}
				onMagicFill={handleMagicFill}
				onAvailabilityChange={handleAvailabilityChange}
				addToCollection={handleUnsupportedFeature}
				removeFromCollection={handleUnsupportedFeature}
				createCollection={handleUnsupportedFeature}
				isSavingInProgress={isSaving}
			/>
		</div>
	)
}

function mapStateToProps(state: TRootState): IReduxStateProps {
	return {
		isSaving: inventorySelectors.getLoadingStatus(inventoryActionSets.putItem.request)(state),
	}
}

export default connect(mapStateToProps, { putItemRequest, emitAnalyticsEvent })(withRouter(NewWork))
