import { createBasicParametricSelector, createBasicSelector } from "@store/shared/selectors"
import { IStatus, IStatusWithAvailability } from "@typed/api/responses/Workflows"

export const getStatuses = createBasicSelector((state) => state.statuses.statuses)

export const getAvailability = createBasicParametricSelector<
	IStatus[] | undefined,
	{ statusId: string; availabilityId: string },
	IStatusWithAvailability | undefined
>(
	getStatuses,
	(statuses, props) => {
		if (typeof statuses === "undefined") {
			return undefined
		}

		const status = statuses.find((status) => status.id == props.statusId)
		if (typeof status === "undefined") {
			return undefined
		}

		const availability = status.availabilities.find((availability) => availability.id === props.availabilityId)
		if (typeof availability === "undefined") {
			return undefined
		}

		return { status, availability }
	},
	(props) => `${props.statusId}:${props.availabilityId}`
)
