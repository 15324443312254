export const cacheAccessCode = (sharedKey: string, accessCode: string): void => {
	localStorage.setItem(`s-${sharedKey}`, accessCode)
}

export const getCachedAccessCode = (sharedKey: string): string | null => {
	return localStorage.getItem(`s-${sharedKey}`)
}

export const deleteCachedKey = (sharedKey: string): void => {
	localStorage.removeItem(`s-${sharedKey}`)
}
