import { Index, IIndex } from "flexsearch"
import { IAutocompleteEntity, ISearchResults, TResultFilterFn, TResultSortFn } from "@services/autocomplete/definitions"

/**
 * A simple class that represents a wrapper over `flexsearch` index
 *
 * We can simply put new items, remove existing ones and get suggestions based on query
 */

export class AutocompleteIndex {
	private index: IIndex
	private readonly map: Map<string, string>

	constructor() {
		this.index = AutocompleteIndex.createIndex()
		this.map = new Map()
	}

	private static createIndex(): IIndex {
		return new Index({
			tokenize: "forward",
			preset: "memory",
		})
	}

	public async clear(): Promise<void> {
		this.index = AutocompleteIndex.createIndex()
		this.map.clear()
	}

	public async put(id: string, value: string, useAsync?: boolean): Promise<void> {
		this.map.set(id, value)
		if (this.index.contain(id)) {
			return useAsync ? this.index.updateAsync(id, value) : this.index.update(id, value)
		}

		return useAsync ? this.index.addAsync(id, value) : this.index.add(id, value)
	}

	public async remove(id: string): Promise<void> {
		return this.index.removeAsync(id)
	}

	public async search(query: string, filterFn?: TResultFilterFn, sortFn?: TResultSortFn): Promise<ISearchResults> {
		const res = await this.index.searchAsync(query)
		const entities = res
			.map((id) => ({
				id,
				value: String(this.map.get(id)),
			}))
			.filter(({ id, value }) => (filterFn ? filterFn(id, value) : true))

		if (sortFn) {
			entities.sort(sortFn)
		}

		return {
			entities,
			hasExactMatch: entities.some((entity) => entity.value.toLowerCase() === query.toLowerCase()),
		}
	}

	public async getAll(filter?: TResultFilterFn, sortFn?: TResultSortFn): Promise<IAutocompleteEntity[]> {
		const entities = [...this.map.entries()]
			.map(([id, value]) => ({ id, value }))
			.filter(({ id, value }) => (filter ? filter(id, value) : true))

		if (sortFn) {
			entities.sort(sortFn)
		}

		return entities
	}
}
