import { createBasicSelector } from "@store/shared/selectors"
import { Selector } from "reselect"
import { TRootState } from "@store/rootReducer"
import { TRequestStateNames } from "@store/shared/requestState"
import * as actionSets from "./actionSets"

export const getAccessToken = createBasicSelector((state) => state.auth.access_token)
export const getLoggedInStatus = createBasicSelector((state) => state.auth.isLoggedIn)
export const getCognitoIdentity = createBasicSelector((state) => state.auth.cognito_identity)
export const getCognitoSub = createBasicSelector((state) => state.auth.cognito_sub)

export const getLoadingStatus = (request: TRequestStateNames<typeof actionSets>): Selector<TRootState, boolean> => {
	return createBasicSelector((state) => {
		return state.auth.requests[request].isLoading
	})
}

export const getErrorMsg = (
	request: TRequestStateNames<typeof actionSets>
): Selector<TRootState, string | undefined> => {
	return createBasicSelector((state) => {
		return state.auth.requests[request].error?.message
	})
}
