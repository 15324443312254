import React from "react"
import TextInputField from "@components/FormInputs/TextInputField"
import * as styles from "@containers/WorkDetailsContainer/tabs/Tabs.module.scss"
import { ReactComponent as CloseIcon } from "@icons/CloseIcon.svg"
import { ReactComponent as PlusIcon } from "@icons/PlusIcon.svg"
import IconButton from "@components/IconButton"
import AddCollectionPopover from "@containers/PreviewContainer/AddCollectionPopover"
import SuggestionTextInputField from "@components/FormInputs/SuggestionTextInputField"
import { TProps } from "./types"
import { connect } from "react-redux"
import { getArtistItemsIds, getInventoryItems } from "@store/ducks/inventory/selectors"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

const AdditionalDetailsTab: React.FC<TProps["Combined"]> = (props) => {
	const { control, watch, collections, isNewWork } = props
	const { addToCollection, createCollection, removeFromCollection } = props
	const { seriesAutocompleteFilter } = props
	const { t } = useTranslation()

	return (
		<div className={styles.tab}>
			<section className={styles.section}>
				<TextInputField
					label={t("artwork.stockNumber")}
					control={control}
					name="fields.stockCode"
					className={styles.inputWrapper}
				/>
				<TextInputField
					label={t("artwork.notes")}
					control={control}
					name="fields.notes"
					className={styles.inputWrapper}
					multiline
				/>
				<SuggestionTextInputField
					label={t("artwork.series")}
					control={control}
					autocompleteUnitKey="series"
					name="fields.series"
					className={styles.inputWrapper}
					filter={seriesAutocompleteFilter(watch("fields.artist"))}
					showAll
				/>
				<TextInputField
					label={t("artwork.editionDetails")}
					control={control}
					name="fields.editionDetails"
					className={styles.inputWrapper}
				/>
				<TextInputField
					label={t("artwork.otherDimensions")}
					placeholder={t("artwork.otherDimensionsPlaceholder")}
					control={control}
					name="fields.dimsOther"
					className={styles.inputWrapper}
					multiline
				/>
				<TextInputField
					label={t("artwork.confidentialNotes")}
					placeholder={t("artwork.confidentialNotesPlaceholder")}
					control={control}
					name="fields.confidentialNote"
					className={styles.inputWrapper}
					multiline
				/>
			</section>

			{!isNewWork && (
				<section className={classNames(styles.section, styles.wide)}>
					<div className={styles.collectionsWrapper}>
						<span className={styles.title}>{t("inventory.collections")}</span>
						<div className={styles.collectionsList}>
							{collections.map((collection) => (
								<div className={styles.collectionBlock} key={collection.id}>
									<span>{collection.name}</span>
									<IconButton
										icon={<CloseIcon />}
										label={t("artwork.removeFromCollection")}
										className={styles.removeButton}
										onClick={() => removeFromCollection(collection.id)}
									/>
								</div>
							))}
							<AddCollectionPopover
								buttonIcon={<PlusIcon />}
								buttonClassName={styles.addButton}
								onAddToCollection={addToCollection}
								onCreateCollection={createCollection}
								label={t("artwork.addToCollection")}
								showLabel
							/>
						</div>
					</div>
				</section>
			)}
		</div>
	)
}

const mapStateToProps: TProps["State"] = (state) => {
	return {
		seriesAutocompleteFilter: (artist) => {
			// if no artist is specified, show all suggestions
			if (artist.trim().length == 0) {
				return () => true
			}

			const itemsIds = getArtistItemsIds(state)({ artist })
			const artistItems = getInventoryItems(state)({ itemsIds })
			// storing as Set for faster access
			// TODO: can be moved to selectors
			const seriesSet = new Set(artistItems.map((item) => item.series).filter((series) => series && series.length > 0))

			return (key) => seriesSet.has(key)
		},
	}
}

export const AdditionalDetailsTabConnected = connect(mapStateToProps)(AdditionalDetailsTab)
