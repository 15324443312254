import { ActionType } from "typesafe-actions"
import * as actions from "./actions"
import * as actionSets from "./actionSets"

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-namespace */

export namespace CollectionsActionTypes {
	export const GetCollectionsRequest = actionSets.getCollections.request
	export const GetCollectionsSuccess = actionSets.getCollections.success
	export const GetCollectionsFailure = actionSets.getCollections.failure
	export const UpdateCollectionsRequest = "collections/UPDATE_COLLECTIONS_DATA"
	export const SetCollectionsData = "collections/SET_COLLECTIONS_DATA"

	export const ManageCollection = "collections/MANAGE_COLLECTION"
	export const ManageArchive = "collections/MANAGE_ARCHIVE"
	export const CreateCollection = "collections/CREATE_COLLECTION"
	export const ApplyNewCollection = "collections/APPLY_NEW_COLLECTION"
}

export type TCollectionsAction = ActionType<typeof actions>
export type TCollectionsActionsSets = typeof actionSets
