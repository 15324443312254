import { FieldValues } from "react-hook-form/dist/types/fields"
import { Controller } from "react-hook-form"
import * as styles from "./FormInputs.module.scss"
import * as listStyles from "./SuggestionTextInputField.module.scss"
import React, { useState } from "react"
import { IHookFormInput } from "@components/FormInputs/types"
import withAutocomplete, { IAutocompleteHandlerProps } from "@enhancers/withAutocomplete"
import { usePopper } from "react-popper"
import useSuggestionsSearch from "@enhancers/useSuggestionsSearch"
import { List } from "@components/List"
import * as defaultListStyles from "@components/List/List.module.scss"
import { deriveErrorMessage } from "@components/FormInputs/common"
import { TResultFilterFn } from "@services/autocomplete/definitions"
import { FormInputWrapper } from "@components/FormInputs/FormInputWrapper"

interface ISuggestionTextInputFieldProps<TFieldValues extends FieldValues> extends IHookFormInput<TFieldValues> {
	label: string
	autocompleteUnitKey: string
	filter?: TResultFilterFn
	showAll?: boolean
}

function SuggestionController<TFieldValues extends FieldValues>(
	props: ISuggestionTextInputFieldProps<TFieldValues> & IAutocompleteHandlerProps
): JSX.Element {
	const { label, control, name, className, searchFn, showAll, getAllFn } = props

	// positioning suggestions
	const [referenceElement, setReferenceElement] = useState<HTMLInputElement | null>(null)
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
	const { styles: currentStyles, attributes: currentAttributes } = usePopper(referenceElement, popperElement, {
		placement: "bottom-start",
		modifiers: [
			{
				name: "offset",
				options: { offset: [0, 1] },
			},
		],
	})

	return (
		<Controller
			render={({ field, fieldState }) => {
				const [isFocused, setIsFocused] = useState(false)
				const query = field.value
				const { suggestions, hasExactMatch } = useSuggestionsSearch({
					getAllFn: showAll ? getAllFn : undefined,
					searchFn,
					query: `${query}`,
				})

				const showSuggestions = isFocused && suggestions.length > 0 && (!hasExactMatch || suggestions.length > 1)

				return (
					<FormInputWrapper className={className} label={label} isInvalid={fieldState.invalid}>
						<input
							className={styles.input}
							value={query}
							onChange={(e) => {
								setIsFocused(true)
								field.onChange(e.target.value)
							}}
							ref={setReferenceElement}
							onBlur={(event) => {
								const relatedTarget = event.relatedTarget as HTMLElement | null
								if (!popperElement?.contains(relatedTarget)) {
									setIsFocused(false)
								}
							}}
							onFocus={() => setIsFocused(true)}
						/>
						{fieldState.error && <span className={styles.errorMessage}>{deriveErrorMessage(fieldState.error)}</span>}
						{showSuggestions && (
							<div
								ref={setPopperElement}
								style={{
									...currentStyles.popper,
									width: referenceElement?.getBoundingClientRect().width,
								}}
								{...currentAttributes.popper}
								className={listStyles.list}
							>
								<List
									items={suggestions}
									className={defaultListStyles.listWrapper}
									onItemClicked={(item) => {
										field.onChange(item.value)
										setIsFocused(false)
									}}
									itemContents={({ item }) => <button className={`${defaultListStyles.listItem}`}>{item.value}</button>}
								/>
							</div>
						)}
					</FormInputWrapper>
				)
			}}
			name={name}
			control={control}
		/>
	)
}

function SuggestionTextInputField<TFieldValues extends FieldValues>(
	props: ISuggestionTextInputFieldProps<TFieldValues>
): JSX.Element | null {
	const { autocompleteUnitKey, filter } = props
	return withAutocomplete<ISuggestionTextInputFieldProps<TFieldValues>>(
		autocompleteUnitKey,
		SuggestionController,
		filter
	)(props)
}

export default SuggestionTextInputField
