import { IGenericError } from "@store/shared/errorsHandling"

/**
 * It is quite common to use REQUEST / SUCCESS / FAILURE action
 * Therefore it makes sense to organize within our type system to reduce boilerplate
 */

// phases of requests: "REQUEST" -> "SUCCESS" or "FAILURE"
export const requestAction = "REQUEST"
export const successAction = "SUCCESS"
export const failureAction = "FAILURE"

// organise phases as TypeScript template literal types
export type TRequestAction<TD extends string, TRN extends string> = `${TD}/${TRN}/${typeof requestAction}`
export type TSuccessAction<TD extends string, TRN extends string> = `${TD}/${TRN}/${typeof successAction}`
export type TFailureAction<TD extends string, TRN extends string> = `${TD}/${TRN}/${typeof failureAction}`

// set of actions for request will have all phases
export type TRequestActionsSet<TD extends string, TRN extends string> = {
	request: TRequestAction<TD, TRN>
	success: TSuccessAction<TD, TRN>
	failure: TFailureAction<TD, TRN>
}

// set of multiple RequestActionsSets
export type TRequestActionsSetGroup = {
	[key: string]: TRequestActionsSet<string, string>
}

export type TRequestState = {
	readonly isLoading: boolean
	readonly error: IGenericError | null
}

export type TRequestStateGroup<T extends TRequestActionsSetGroup> = {
	[Property in keyof T as `${T[Property]["request"]}`]: TRequestState
}

export type TRequestStateNames<T extends TRequestActionsSetGroup> = keyof TRequestStateGroup<T>

export type TStateWithRequestStateGroup<T extends TRequestActionsSetGroup> = {
	requests: TRequestStateGroup<T>
}

export type TRequestStateInfo = {
	name: string
	phase: typeof requestAction | typeof successAction | typeof failureAction
}
