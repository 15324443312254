import React from "react"
import TextInputField from "@components/FormInputs/TextInputField"
import * as styles from "./Tabs.module.scss"
import { ITabProps } from "@containers/WorkDetailsContainer/types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

const ProvenanceTab: React.FC<ITabProps> = (props) => {
	const { control } = props
	const { t } = useTranslation()

	return (
		<div className={styles.tab}>
			<section className={classNames(styles.section, styles.wide)}>
				<TextInputField
					label={t("artwork.provenance")}
					control={control}
					name="fields.provenance"
					className={styles.inputWrapper}
					multiline
				/>
				<TextInputField
					label={t("artwork.exhibitions")}
					control={control}
					name="fields.exhibitionHistory"
					className={styles.inputWrapper}
					multiline
				/>
				<TextInputField
					label={t("artwork.literature")}
					control={control}
					name="fields.literature"
					className={styles.inputWrapper}
					multiline
				/>
			</section>
		</div>
	)
}

export default ProvenanceTab
