import Amplify from "@aws-amplify/core"
import Auth from "@aws-amplify/auth"
import Storage from "@aws-amplify/storage"
import { v4 as uuidv4 } from "uuid"

import aws_exports from "@services/environment/aws"
import { ICognitoError } from "@typed/api/responses/CognitoError"
import { ICognitoUserSessionX } from "@typed/api/responses/Cognito"
import {
	TForgotPasswordRequest,
	TForgotPasswordSubmitRequest,
	TGetAccessTokenRequest,
	TGetAuthenticatedUserRequest,
	TGetCognitoIdentityRequest,
	TReauthenticateRequest,
	TSignInRequest,
	TSignOutRequest,
	TSignUpRequest,
	TUploadFileRequest,
	TVerifyUserRequest,
} from "@services/api/definitions/amplify/requests"

Amplify.configure(aws_exports)

// Amplify.configure({
//     Auth: {
//         identityPoolId: 'eu-west-1:a580b77b-f1b9-4a3f-9932-399274ca4b6e',
//         region: 'eu-west-1',
//         userPoolId: 'eu-west-1_yVaNaVqNt',
//         userPoolWebClientId: '6ovjam6s04jlbtqrgapk0ngag4',
//     },
//     Storage: {
//         AWSS3: {
//             bucket: 'art-tech-bucket',
//             region: 'eu-west-1',
//         }
//     }
// });

export const signIn: TSignInRequest = async (props) => {
	return await Auth.signIn(props.username, props.password)
}

export const verifyUser: TVerifyUserRequest = async (props) => {
	return await Auth.confirmSignUp(props.username, props.code)
}

export const signOut: TSignOutRequest = async (props) => {
	try {
		await Auth.signOut({ global: props.global || false })
	} catch (error) {
		console.log("error signing out: ", error)
	}
}

export const reauthenticate: TReauthenticateRequest = async () => {
	// TODO: suspicious `as unknown` conversion
	return (await Auth.currentSession()) as unknown as ICognitoUserSessionX
}

export const getAuthenticatedUser: TGetAuthenticatedUserRequest = async () => {
	return await Auth.currentAuthenticatedUser()
}

export const getCognitoIdentity: TGetCognitoIdentityRequest = async () => {
	const res = await Auth.currentCredentials()
	return { identityId: res.identityId }
}

export const getAccessToken: TGetAccessTokenRequest = async () => {
	const res = (await reauthenticate()) as unknown as ICognitoUserSessionX
	return { accessToken: res.accessToken.jwtToken }
}

export const forgotPassword: TForgotPasswordRequest = async (props) => {
	try {
		const res = await Auth.forgotPassword(props.username)
		console.log(res)
		return { status: true, error: null }
	} catch (error) {
		return { status: false, error }
	}
}

export const forgotPasswordSubmit: TForgotPasswordSubmitRequest = async (props) => {
	try {
		await Auth.forgotPasswordSubmit(props.username, props.code, props.newPassword)
		return { status: true, error: null }
	} catch (error) {
		const err: ICognitoError = error
		return { status: false, error: err }
	}
}

export const signUp: TSignUpRequest = async (details) => {
	return await Auth.signUp({
		username: uuidv4(),
		password: details.password,
		attributes: {
			email: details.email,
			phone_number: details.phoneNumber,
			name: details.givenName,
			family_name: details.familyName,
		},
	})
}

export const uploadFile: TUploadFileRequest = async (props) => {
	console.log(props.uploadPath)
	console.log("uploading")

	try {
		await Storage.put(props.uploadPath, props.file, {
			level: props.level,
			contentType: props.contentType,
			progressCallback(progress: { loaded: number; total: number }) {
				props.updateProgress(progress.loaded / progress.total)
			},
		})
		return { status: true }
	} catch (error) {
		console.log(error)
		return { status: false }
	}
}
