type TSplittedIntoChunks<T> = {
	chunks: Record<number, T[]>
	chunksCount: number
}

/**
 * Given an array of size N, splits it into chunks where each chunk has size of `chunkSize`
 * @param array
 * @param chunkSize
 *
 * @returns TSplittedIntoChunks (dictionary of chunks and total number of chunks)
 */
export default function splitIntoChunks<T>(array: T[], chunkSize: number): TSplittedIntoChunks<T> {
	const chunks: Record<number, T[]> = {}

	const chunksCount = Math.ceil(array.length / chunkSize)
	for (let chunkIdx = 0; chunkIdx < chunksCount; chunkIdx++) {
		chunks[chunkIdx] = array.slice(chunkIdx * chunkSize, chunkIdx * chunkSize + chunkSize)
	}

	return { chunks, chunksCount }
}
