import { createSelector, OutputSelector, Selector } from "reselect"
import { TRootState } from "@store/rootReducer"
import memoize from "memoizee"

const defaultMaxCaches = 500

/**
 * Most basic case when we simply want to map within one value (but still want to have types)
 * @param selector - basic transform function
 */
export function createBasicSelector<TResult>(
	selector: Selector<TRootState, TResult>
): OutputSelector<TRootState, TResult, (res: TResult) => TResult> {
	return createSelector<TRootState, TResult, TResult>([selector], (value) => value)
}

export function createBasicParametricSelector<TSubState, TProps, TResult>(
	selector: Selector<TRootState, TSubState>,
	combinerFunction: (subState: TSubState, props: TProps) => TResult,
	hashFn?: (props: TProps) => string,
	maxCaches?: number
): OutputSelector<TRootState, (props: TProps) => TResult, (res: TSubState) => (props: TProps) => TResult> {
	return createSelector<TRootState, TSubState, (props: TProps) => TResult>(
		[selector],
		(subState) =>
			typeof hashFn === "undefined"
				? (props) => combinerFunction(subState, props)
				: memoize((props) => combinerFunction(subState, props), {
						max: typeof maxCaches === "undefined" ? defaultMaxCaches : maxCaches,
						normalizer: (args) => hashFn(args[0]),
					}) // prettier-ignore
	)
}
