export type TEnvString = "development-local" | "development" | "production"

export interface IEnv {
	ENVIRONMENT: TEnvString
	API_SERVER: string
	IMAGES_CDN: string
	AWS_REGION: string
	AWS_COGNITO_IDENTITY_POOL: string
	AWS_COGNITO_USER_POOL: string
	AWS_COGNITO_WEB_CLIENT: string
	AWS_ORIGINAL_S3_BUCKET: string
	ML_ENTITY_PREDICT_ENDPOINT: string
	MIXPANEL_TOKEN: string
	SENTRY_DSN: string
	HOTJAR_ID?: number
	HOTJAR_SV?: number
	HUBSPOT_HUB_ID: number
}

const common = {
	MIXPANEL_TOKEN: "40326bb926c4de908d3d7036a92822d3",
	SENTRY_DSN: "https://6083dc5307dc4af78b0a25aa1506e7a1@o413464.ingest.sentry.io/5912846",
	HUBSPOT_HUB_ID: 21050428,
}

const production: IEnv = {
	...common,
	ENVIRONMENT: "production",
	API_SERVER: "https://server.viewsart.com",
	IMAGES_CDN: "https://dyxjv67mt186u.cloudfront.net",
	AWS_REGION: "eu-west-1",
	AWS_COGNITO_IDENTITY_POOL: "eu-west-1:721c98df-0f51-4767-bb71-0c7732419299",
	AWS_COGNITO_USER_POOL: "eu-west-1_XMxUpTwf9",
	AWS_COGNITO_WEB_CLIENT: "3bs97hms87rqoeak4u3f54rmsm",
	AWS_ORIGINAL_S3_BUCKET: "viewsart-bucket-production",
	ML_ENTITY_PREDICT_ENDPOINT: "https://server.viewsart.com/predict",
	HOTJAR_ID: 2753743, // hjid
	HOTJAR_SV: 6, // htsv
}

const developmentLocal: IEnv = {
	...common,
	ENVIRONMENT: "development",
	API_SERVER: "http://localhost:3000",
	IMAGES_CDN: "https://d1zr3jiovrff0o.cloudfront.net",
	AWS_REGION: "eu-west-1",
	AWS_COGNITO_IDENTITY_POOL: "eu-west-1:a0856954-6a5b-464f-a513-4b1ecde8aa3a",
	AWS_COGNITO_USER_POOL: "eu-west-1_HntAyQHyg",
	AWS_COGNITO_WEB_CLIENT: "65jtib1ng9qgul6ki6mah94u9b",
	AWS_ORIGINAL_S3_BUCKET: "viewsart-bucket-development",
	ML_ENTITY_PREDICT_ENDPOINT: "https://dev1.dev.viewsart.com/predict",
}

const developmentRemote: IEnv = {
	...common,
	ENVIRONMENT: "development",
	API_SERVER: "https://dev1.dev.viewsart.com",
	IMAGES_CDN: "https://d376fxjy7ldjab.cloudfront.net",
	AWS_REGION: "eu-west-1",
	AWS_COGNITO_IDENTITY_POOL: "eu-west-1:65367c98-60eb-456e-917d-a4711bd5686e",
	AWS_COGNITO_USER_POOL: "eu-west-1_Z6We1uozi",
	AWS_COGNITO_WEB_CLIENT: "2nd33uq7ta1dm23lf35budjbra",
	AWS_ORIGINAL_S3_BUCKET: "viewsart-bucket-development-dev1",
	ML_ENTITY_PREDICT_ENDPOINT: "https://dev1.dev.viewsart.com/predict",
}

let env: IEnv = developmentLocal
if (process.env.REACT_APP_ENV === "production") {
	env = production
} else if (process.env.REACT_APP_ENV === "development") {
	env = developmentRemote
}

export const environment = {
	...env,
}

export function getEnvironmentString(): TEnvString {
	if (window.location.hostname === "localhost") {
		return "development-local"
	}

	return environment.ENVIRONMENT
}
