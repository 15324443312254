import { TAnalyticsEventEmitter, TAnalyticsPayload } from "@services/analytics/definitions"
import { IPredictedArtwork } from "@typed/api/responses/MLPredictArtwork"
import * as MagicFillTools from "@utilities/workProperties/MagicFillTools"

/**
 * There are special cases when we fire events from logic, not deriving it from redux
 * This file contains various ready-to-use event emitters
 */

function createStandaloneEventEmitter<TProps>(eventName: string, payloadMapper?: (props: TProps) => TAnalyticsPayload) {
	return function emitEvent(emitterFn: TAnalyticsEventEmitter, props?: TProps): void {
		if (typeof payloadMapper === "undefined" || typeof props === "undefined") {
			emitterFn({ name: eventName })
		} else {
			emitterFn({
				name: eventName,
				payload: payloadMapper(props),
			})
		}
	}
}

export const magicFill = createStandaloneEventEmitter<IPredictedArtwork>("Magic Paste", (details) => ({
	"Identified Fields": MagicFillTools.getIdentifiedFields(details),
}))

export const availabilityChange = createStandaloneEventEmitter<{ status: string; availability: string }>(
	"Change Status / Availability",
	(props) => ({
		"Status & Availability": `${props.status} - ${props.availability}`,
	})
)
