import { PointerSensor } from "@dnd-kit/core"
import { PointerEvent } from "react"

const onPointerDownEvent = "onPointerDown"

/**
 * We have a button attached to every artwork image. Therefore we need to prevent dragging if user clicked on the button
 * We do this by iterating the path of elements and searching for at least one interactive element
 */
export default class CustomPointerSensor extends PointerSensor {
	static activators = [
		{
			eventName: onPointerDownEvent as "onPointerDown",
			handler: ({ nativeEvent: event }: PointerEvent): boolean => {
				const hasInteractiveElement = event.composedPath().some((el) => isInteractiveElement(el))
				return !hasInteractiveElement
			},
		},
	]
}

function isInteractiveElement(eventTarget: EventTarget) {
	const interactiveElements = ["button", "input", "textarea", "select", "option"]

	const element = eventTarget as Element
	return element?.tagName && interactiveElements.includes(element.tagName.toLowerCase())
}
