import { createBrowserHistory } from "history"
import * as HotjarService from "@services/analytics/implementations/hotjar"

enum StaticRoutes {
	Home = "/",
	Registration = "/register",
	PostRegistration = "/welcome",
	Login = "/login",
	VerifyUser = "/verify",
	UserInventory = "/inventory",
	CreateNewWork = "/create",
	EditWork = "/edit",
	ImportFromArtLogic = "/import/art-logic",
	ImportFromFile = "/import/from-file",
	PreviewSharedWork = "/s",
	AccountPage = "/account",
	PasswordResetRequest = "/password-reset",
	Organisations = "/orgs",
}

const browserHistory = createBrowserHistory()
browserHistory.listen((location) => {
	HotjarService.onRouteChange(location.pathname)
})

export { StaticRoutes, browserHistory }
