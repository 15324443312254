import React from "react"
import classNames from "classnames"
import * as styles from "@components/FormInputs/FormInputs.module.scss"

interface IFormInputWrapperProps {
	label: string
	isInvalid?: boolean
	className?: string
}

export const FormInputWrapper: React.FC<IFormInputWrapperProps> = (props) => {
	const { label, isInvalid, className } = props

	return (
		<div
			className={classNames(styles.inputWrapper, {
				[styles.isInvalid]: isInvalid,
				[`${className}`]: !!className,
			})}
		>
			<span className={styles.title}>{label}</span>
			{props.children}
		</div>
	)
}
