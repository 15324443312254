import React, { MutableRefObject, useEffect, useRef, useState } from "react"
import * as styles from "./ItemInSelectionPreview.module.scss"
import { getInventoryItem } from "@store/ducks/inventory/selectors"
import { connect } from "react-redux"
import { TProps } from "./types"
import ThumbnailImage from "@components/ThumbnailImage"
import { CSSTransition } from "react-transition-group"
import { setItemForPreview } from "@store/ducks/inventory"
import { joinStrings } from "@utilities/strings/formatting"

const ItemInSelectionPreview: React.FC<TProps["Combined"]> = (props) => {
	const { style, setItemForPreview } = props
	const { author, title, year, thumbnailSrc } = props
	const hasYear = year && year.length > 0

	const containerRef = useRef() as MutableRefObject<HTMLDivElement>
	const [isMounted, setIsMounted] = useState(false)
	useEffect(() => setIsMounted(true), [])

	return (
		<CSSTransition
			classNames={{ enter: styles.animationEnter, enterDone: styles.animationEnterDone }}
			timeout={100}
			in={isMounted}
			nodeRef={containerRef}
		>
			<div ref={containerRef} className={styles.container} style={style} onClick={() => setItemForPreview()}>
				<div className={styles.innerWrapper}>
					<div className={styles.thumbnailWrapper}>
						<ThumbnailImage
							src={thumbnailSrc}
							width={"100%"}
							height={"100%"}
							emptyImageWidth={30}
							alt={joinStrings([author, title], ", ")}
							className={styles.thumbnail}
						/>
					</div>
					<div className={styles.description}>
						<section className={styles.author}>{author}</section>
						<section>
							<i>{title}</i>
							{hasYear && `, ${year}`}
						</section>
					</div>
				</div>
			</div>
		</CSSTransition>
	)
}

const mapStateToProps: TProps["State"] = (state, ownProps) => {
	const row = getInventoryItem(ownProps.itemId)(state)

	return {
		thumbnailSrc: row?.images ? row?.images[0]?.thumbnail : undefined,
		author: row?.artist,
		title: row?.title,
		year: row?.year,
	}
}

const mapDispatchToProps: TProps["Dispatch"] = (dispatch, ownProps) => {
	return {
		setItemForPreview: () => dispatch(setItemForPreview(ownProps.itemId)),
	}
}

export const ItemInSelectionPreviewConnected = connect(mapStateToProps, mapDispatchToProps)(ItemInSelectionPreview)
