import React from "react"

type TErrorMessageProps = {
	message: string
}

export const ErrorMessage: React.FC<TErrorMessageProps> = ({ message }) => {
	return (
		<div className="pt-3">
			<div className="alert alert-dark m-0" role="alert">
				{message}
			</div>
		</div>
	)
}
