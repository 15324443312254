import { Reducer } from "redux"
import { CollectionsActionTypes, TCollectionsAction, TCollectionsActionsSets } from "./types"
import * as actionSets from "./actionSets"
import { defaultRequestState, commonReducerCreator, TStateWithRequestStateGroup } from "@store/shared/requestState"
import {
	normalizeCollections,
	TNormalizedCollectionsSchema,
	updateCollectionData,
	withCreatedCollection,
} from "@store/ducks/collections/normalized"

export interface ICollectionsStoreState extends TStateWithRequestStateGroup<TCollectionsActionsSets> {
	readonly collections: TNormalizedCollectionsSchema
}

export const initCollectionsState: ICollectionsStoreState = {
	collections: normalizeCollections([]),
	requests: defaultRequestState(actionSets),
}

const commonReducer = commonReducerCreator<ICollectionsStoreState, TCollectionsAction>(actionSets)

export const CollectionsReducer: Reducer<ICollectionsStoreState, TCollectionsAction> = (
	state = initCollectionsState,
	action: TCollectionsAction
) => {
	switch (action.type) {
		case CollectionsActionTypes.SetCollectionsData:
			return {
				...commonReducer(state, action),
				collections: normalizeCollections(action.payload.collectionsToPut),
			}
		case CollectionsActionTypes.ManageCollection:
			return {
				...commonReducer(state, action),
				collections: updateCollectionData(state.collections, action.payload),
			}
		case CollectionsActionTypes.ApplyNewCollection:
			return {
				...commonReducer(state, action),
				collections: withCreatedCollection(state.collections, action.payload),
			}
		default:
			return commonReducer(state, action)
	}
}
