import { Control } from "react-hook-form/dist/types/form"
import { IDetailsForm } from "@containers/WorkDetailsContainer/types"
import React, { useCallback } from "react"
import FieldArrayTable, { TFieldArrayDialogFunction } from "@components/FieldArrayTable"
import { ITemporaryAdmissionEntity } from "@typed/api/responses/WorkDetails"
import format from "date-fns/format"
import * as styles from "./TemporaryAdmissionHistoryTable.module.scss"
import { formatPrice, unpackPrice } from "@utilities/currency/unifiedPrice"
import classNames from "classnames"
import { ITemporaryAdmissionEntityForm } from "./types"
import { getDefaultTemporaryAdmissionEntity, sortTemporaryAdmissionEntities, temporaryAdmissionMappings } from "./utils"
import { TemporaryAdmissionDialog } from "./TemporaryAdmissionDialog"

interface ILocationHistoryTableProps {
	control: Control<IDetailsForm>
}

export const TemporaryAdmissionHistoryTable: React.FC<ILocationHistoryTableProps> = (props) => {
	const { control } = props

	const getTemporaryAdmissionDialogDialog = useCallback<TFieldArrayDialogFunction<ITemporaryAdmissionEntityForm>>(
		(entity, onSubmit, onRemove, entityId) =>
			({ closeDialog }) =>
				(
					<TemporaryAdmissionDialog
						entity={entity}
						entityId={entityId}
						onSubmit={onSubmit}
						onDelete={onRemove}
						onClose={closeDialog}
					/>
				),
		[]
	)

	const temporaryAdmissionEntityAppearance = useCallback((locationEntity: ITemporaryAdmissionEntity, props) => {
		const entryDateFormatted = locationEntity.entry_date && format(new Date(locationEntity.entry_date), "MMM d, yyyy")
		const returnDateFormatted =
			locationEntity.return_date && format(new Date(locationEntity.return_date), "MMM d, yyyy")
		const declaredValueFmt = locationEntity.declared_value && formatPrice(unpackPrice(locationEntity.declared_value))
		const importDutyFmt = locationEntity.import_duty && formatPrice(unpackPrice(locationEntity.import_duty))

		return (
			<div className={classNames(styles.row, styles.entity)} {...props}>
				<div className={styles.cell}>
					<span className={styles.primary}>{entryDateFormatted || "—"}</span>
					<span className={styles.secondary}>{locationEntity.import_id || "—"}</span>
				</div>
				<div className={styles.cell}>
					<span className={styles.primary}>{returnDateFormatted || "—"}</span>
					<span className={styles.secondary}>{locationEntity.export_id || "—"}</span>
				</div>
				<div className={styles.cell}>
					<span className={styles.primary}>{declaredValueFmt || "—"}</span>
				</div>
				<div className={styles.cell}>
					<span className={styles.primary}>{importDutyFmt || "—"}</span>
				</div>
			</div>
		)
	}, [])

	const header = useCallback(({ entities }) => {
		if (entities.length == 0) {
			return null
		}

		return (
			<div className={classNames(styles.row, styles.header)}>
				<span className={styles.cell}>Import</span>
				<span className={styles.cell}>Export</span>
				<span className={styles.cell}>Declared value</span>
				<span className={styles.cell}>Import duty</span>
			</div>
		)
	}, [])

	return (
		<FieldArrayTable
			formControl={control}
			formName="fields.temporaryAdmission"
			captions={{
				title: "Temporary admission",
				empty: "No temporary admissions",
			}}
			className={styles.table}
			header={header}
			mappers={temporaryAdmissionMappings}
			sort={sortTemporaryAdmissionEntities}
			entityAppearance={temporaryAdmissionEntityAppearance}
			defaultEntity={getDefaultTemporaryAdmissionEntity()}
			dialog={getTemporaryAdmissionDialogDialog}
		/>
	)
}
