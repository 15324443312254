import React, { MutableRefObject, useCallback, useEffect, useRef } from "react"
import { TBasicListItem, TGenericItemFC } from "./types"
import * as styles from "./List.module.scss"
import useKeyDown from "@enhancers/useKeyDown"
import useKeyboardNavigation from "@enhancers/useKeyboardNavigation"
import scrollIntoView from "scroll-into-view-if-needed"
import { useMouseMove } from "@enhancers/useMouseMove"

interface IListProps<TListItem extends TBasicListItem> {
	items: TListItem[]
	onItemClicked: (item: TListItem) => void
	itemContents: TGenericItemFC<TListItem>
	className?: string
}

export function List<TListItem extends TBasicListItem>(props: IListProps<TListItem>): JSX.Element {
	const { items, itemContents, onItemClicked, className } = props

	const getItemIdxById = (id: string) => items.findIndex((item) => item.id == id)
	const getItemIdFromElement = (el: HTMLElement) => el.closest("[data-item-id]")?.getAttribute("data-item-id")
	const wrapperRef = useRef() as MutableRefObject<HTMLDivElement>
	const [activeNavState, setActiveNavState] = useKeyboardNavigation(items)

	const handleEnterKey = useCallback(() => {
		onItemClicked(items[activeNavState.idx])
	}, [onItemClicked, items, activeNavState])

	const globalClickHandler = (event: React.MouseEvent<HTMLElement>) => {
		const itemId = getItemIdFromElement(event.target as HTMLElement)
		if (itemId) {
			onItemClicked(items[getItemIdxById(itemId)])
		}
	}

	useMouseMove(wrapperRef.current, (event) => {
		const itemId = getItemIdFromElement(event.target as HTMLElement)
		if (itemId) {
			setActiveNavState({
				idx: getItemIdxById(itemId),
				reason: "mousemove",
			})
		}
	})

	useKeyDown("Enter", handleEnterKey)

	useEffect(() => {
		// we don't want to autoscroll when user just hover elements - this is quite bad UX
		if (activeNavState.reason != "keydown") {
			return
		}

		const activeListItem = wrapperRef.current?.querySelector(`[data-active=true]`)
		if (activeListItem) {
			scrollIntoView(activeListItem, { scrollMode: "if-needed", block: "nearest" })
		}
	}, [activeNavState])

	return (
		<div className={className} onClick={globalClickHandler} ref={wrapperRef}>
			<ul className={styles.ulContainer}>
				{items.map((item, index) => {
					return (
						<li key={item.id} data-active={activeNavState.idx == index} data-item-id={item.id} tabIndex={index}>
							{itemContents({
								item,
							})}
						</li>
					)
				})}
			</ul>
		</div>
	)
}
