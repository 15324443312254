import { TSearchParams, TSorting } from "@typed/entities/Search"
import { ISanitizedWorkDetails } from "./InventorySearchService"
import { IWorkDetailsWithDict } from "@store/ducks/inventory/normalized"
import { IWorkDetails } from "@typed/api/responses/WorkDetails"

export function hasEmptyQuery(params: TSearchParams): boolean {
	return typeof params.query === "undefined" || params.query.trim().length == 0
}

export function hasEmptyCollections(params: TSearchParams): boolean {
	return typeof params.collectionsIds === "undefined" || params.collectionsIds.length == 0
}

export function hasEmptyFilters(params: TSearchParams): boolean {
	return typeof params.filters === "undefined"
}

export function hasEmptySorting(params: TSearchParams): boolean {
	return Object.values(params.sorting).length === 0
}

export function hasEmptyParams(params: TSearchParams): boolean {
	return hasEmptyQuery(params) && hasEmptyCollections(params) && hasEmptyFilters(params) && hasEmptySorting(params)
}

export function sanitizeWorkDetails(item: IWorkDetails, keys: TGetKeyFn<IWorkDetails>[]): ISanitizedWorkDetails {
	return {
		id: item.id,
		content: createCombinedSearchField(item as IWorkDetailsWithDict, keys),
		lastUpdated: item.lastUpdated,
		createdAt: item.createdAt,
		artist: item.artist,
	}
}

/**
 * Concatenates all non-undefined values of all properties specified in `keys` parameter
 * @param item
 * @param keys - we can either provide a direct property of an item, or a function to retrieve string
 */
export type TGetKeyFn<TEntity> = ((entity: TEntity) => string | number) | string
export function createCombinedSearchField<T extends Record<string, unknown>>(item: T, keys: TGetKeyFn<T>[]): string {
	const valuesToJoin: string[] = []
	for (const key of keys) {
		let retrievedValue
		if (typeof key === "function") {
			retrievedValue = key(item)
		} else if (Object.prototype.hasOwnProperty.call(item, key)) {
			retrievedValue = item[key]
		}

		if (typeof retrievedValue === "string" && retrievedValue.length > 0) {
			valuesToJoin.push(retrievedValue)
		} else if (typeof retrievedValue === "number") {
			valuesToJoin.push(retrievedValue.toString())
		}
	}

	return valuesToJoin.join(" ")
}

/**
 * Universal comparison function that support various types
 * Both left and right item must e of the same type, otherwise 0 (equality) is returned
 * @param leftItem
 * @param rightItem
 */
export function compareValues<T = number | string | Date>(leftItem: T, rightItem: T): number {
	if (typeof leftItem === "number" && typeof rightItem === "number") {
		return rightItem - leftItem
	}

	if (leftItem instanceof Date && rightItem instanceof Date) {
		return +rightItem - +leftItem
	}

	if (typeof leftItem === "string" && typeof rightItem === "string") {
		return rightItem.localeCompare(leftItem)
	}

	return 0
}

/**
 * Given two items to compare and `sortingOptions`, performs sorting based on multiple properties
 * Properties are compared with exactly the same order they appear within `sortingOptions`
 * If a given property doesn't exist on any of two items, we skip comparison
 * @param leftItem
 * @param rightItem
 * @param sortingOptions
 *
 * @returns number first non-zero comparison result, otherwise 0
 */
export function compareByMultipleFields<T extends Record<string, unknown>>(
	leftItem: T,
	rightItem: T,
	sortingOptions: TSorting<T>
): number {
	let result = 0
	for (const sortingKey in sortingOptions) {
		if (
			Object.prototype.hasOwnProperty.call(sortingOptions, sortingKey) &&
			Object.prototype.hasOwnProperty.call(leftItem, sortingKey) &&
			Object.prototype.hasOwnProperty.call(rightItem, sortingKey)
		) {
			const orderMultiplier = sortingOptions[sortingKey] === "DESC" ? 1 : -1
			result = orderMultiplier * compareValues(leftItem[sortingKey], rightItem[sortingKey])
			if (result !== 0) {
				break
			}
		}
	}

	return result
}
