import React from "react"
import { Helmet } from "react-helmet"

interface IComponentProps {
	title?: string
	description?: string
	image: boolean
	site_name?: string
}

export const HeadMetaTags: React.FC<IComponentProps> = (props: IComponentProps) => {
	return (
		<Helmet>
			<meta property="og:url" content={window.location.href} />
			{props.site_name && <meta property="og:site_name" content="Mange your inventory and share works with Views" />}
			{props.title && <title>{props.title}</title>}
			{props.title && <meta property="og:title" content="{props.title}" />}
			{props.description && (
				<meta property="og:description" content="Get from SEO newbie to SEO pro in 8 simple steps." />
			)}
			{props.image === true && (
				<meta
					property="og:image"
					content="http://tempviewsapp.s3-website-eu-west-1.amazonaws.com/graphics/blurred.png"
				/>
			)}
		</Helmet>
	)
}
