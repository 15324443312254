export enum ItemPermissions {
	canEdit = "canEdit",
	canEditImages = "canEditImages",
	canShare = "canShare",
	canDelete = "canDelete",
	canAddToOrg = "canAddToOrg",
	canDeleteFromOrg = "canDeleteFromOrg",
}

export const allItemPermissions = [
	ItemPermissions.canEdit,
	ItemPermissions.canEditImages,
	ItemPermissions.canShare,
	ItemPermissions.canDelete,
	ItemPermissions.canAddToOrg,
	ItemPermissions.canDeleteFromOrg,
]
