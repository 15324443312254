import React from "react"
import * as styles from "./ItemsListRow.module.scss"
import * as checkboxStyles from "@styles/modules/Checkbox.module.scss"
import { getItemSelectionTitle } from "./functions"
import { dimensionsStringCm } from "@utilities"
import ThumbnailImage from "@components/ThumbnailImage"
import { useTranslation } from "react-i18next"
import { connect, shallowEqual } from "react-redux"
import {
	getInventoryItem,
	getSelectedItemsAsArray,
	isInventoryItemInPreview,
	isInventoryItemSelected,
} from "@store/ducks/inventory/selectors"
import { processItemClick, setItemSelected } from "@store/ducks/inventory"
import { TProps } from "./types"
import CollectionsList from "@components/CollectionsList"
import classNames from "classnames"
import { joinStrings } from "@utilities/strings/formatting"
import { getItemCollections } from "@store/ducks/collections/selectors"
import useDoubleClick from "@enhancers/useDoubleClick"
import { getAvailability } from "@store/ducks/statuses"
import AvailabilityLabel from "@components/AvailabilityLabel"

/**
 * Single row for ListView of inventory
 * TODO: consider implementing as table (according to design guidelines)
 */

export const ItemsListRow: React.FC<TProps["Combined"]> = React.memo(
	(props) => {
		const { thumbnailSrc, stockCode, author, title, year, material, dims, collections, status, availability } = props
		const { isItemSelected, isItemInPreview, onCheckboxClicked, onItemClicked, onItemDoubleClicked } = props
		const hasStockCode = (stockCode?.length || 0) > 0
		const hasYear = year && year.length > 0
		const hasMaterial = !!material
		const { t } = useTranslation()
		const doubleClickAttrs = useDoubleClick({
			onClick: onItemClicked,
			onDoubleClick: onItemDoubleClicked,
			timeout: 300,
		})

		return (
			<div
				className={classNames(styles.tableRow, {
					[styles.inPreview]: isItemInPreview,
					[styles.isSelected]: isItemSelected,
				})}
				{...doubleClickAttrs}
			>
				<div role="cell" className={classNames(styles.tableData, styles.checkboxCell)}>
					<input
						className={checkboxStyles.checkbox}
						type="checkbox"
						checked={isItemSelected}
						title={getItemSelectionTitle(isItemSelected)(t)}
						onChange={(event) => {
							onCheckboxClicked(event.target.checked)
						}}
						onClick={(event) => event.stopPropagation()}
					/>
				</div>
				<div role="cell" className={classNames(styles.tableData, styles.thumbnailImageCell)}>
					<ThumbnailImage
						width={40}
						height={40}
						emptyImageWidth={20}
						className={styles.thumbnailImage}
						alt={joinStrings([author, title], ", ")}
						src={thumbnailSrc}
					/>
				</div>
				<div role="cell" className={classNames(styles.tableData, styles.statusCell)}>
					<abbr
						title={status && availability ? `${status?.name} - ${availability?.name}` : ""}
						className={styles.itemStatusAbbr}
					>
						<AvailabilityLabel availabilityIcon={availability?.icon?.symbol} />
					</abbr>
				</div>
				{hasStockCode && (
					<div role="cell" className={classNames(styles.tableData, styles.stockCodeCell)}>
						{stockCode}
					</div>
				)}
				<div role="cell" className={classNames(styles.tableData, styles.metaInfoCell)}>
					<span className={classNames(styles.itemDetail, styles.itemAuthor)}>{author}</span>
					<span className={classNames(styles.itemDetail, styles.itemTitle)}>{title}</span>
					{hasYear && <span className={styles.itemDetail}>{year}</span>}
					{hasMaterial && <span className={styles.itemDetail}>{material}</span>}
					<span className={styles.itemDetail}>{dimensionsStringCm(dims)}</span>
				</div>
				<div role="cell" className={classNames(styles.tableData, styles.collectionsCell)}>
					<CollectionsList
						collections={collections}
						className={styles.itemCollectionsList}
						collectionClassName={(collectionId) =>
							classNames(styles.itemCollection, {
								// TODO: [VT-370] support "archived" collection
								[styles.isArchivedCollection]: collectionId === "",
							})
						}
					/>
				</div>
			</div>
		)
	},
	(prev, next) => {
		// we check if the item was removed
		// (in this case we still need to show it for a small period of time to achieve animation)
		if (prev.isRemoved === false && prev.isRemoved !== next.isRemoved) {
			return true
		}

		return shallowEqual(prev, next)
	}
)

const mapStateToProps: TProps["State"] = (state, ownProps) => {
	const row = getInventoryItem(ownProps.itemId)(state)
	const isItemSelected = isInventoryItemSelected(ownProps.itemId)(state)
	const isItemInPreview = isInventoryItemInPreview(ownProps.itemId)(state)
	const hasSelectedItems = getSelectedItemsAsArray(state).length > 0
	const collections = getItemCollections(state)({ itemId: ownProps.itemId })

	let availDetails
	if (row?.status && row?.availability) {
		availDetails = getAvailability(state)({ statusId: row.status, availabilityId: row.availability })
	}

	return {
		thumbnailSrc: row?.images ? row?.images[0]?.thumbnail : undefined,
		stockCode: row?.stock_code,
		author: row?.artist,
		title: row?.title,
		year: row?.year,
		material: row?.medium,
		collections,
		dims: {
			width: row?.dim_width,
			height: row?.dim_height,
			depth: row?.dim_depth,
		},
		isItemSelected,
		isItemInPreview: !hasSelectedItems && isItemInPreview,
		isRemoved: typeof row === "undefined",
		status: availDetails?.status,
		availability: availDetails?.availability,
	}
}

const mapDispatchToProps: TProps["Dispatch"] = (dispatch, ownProps) => {
	return {
		onCheckboxClicked: (checked: boolean) => dispatch(setItemSelected(ownProps.itemId, checked)),
		onItemClicked: () => dispatch(processItemClick(ownProps.itemId, "single")),
		onItemDoubleClicked: () => dispatch(processItemClick(ownProps.itemId, "double")),
	}
}

export const ItemsListRowConnected = connect(mapStateToProps, mapDispatchToProps)(ItemsListRow)
