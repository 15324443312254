import React, { useCallback } from "react"
import * as styles from "./ArtworkImages.module.scss"
import { useDropzone } from "react-dropzone"
import { DndContext, closestCenter, useSensor, useSensors } from "@dnd-kit/core"
import { Sortable } from "@components/ArtworkImages/Sortable"
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable"
import { DragEndEvent } from "@dnd-kit/core/dist/types"
import { ReactComponent as UploadIcon } from "@icons/UploadIcon.svg"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import warnConfirmDialog from "@components/WarnConfirmDialog"
import CustomPointerSensor from "@components/ArtworkImages/CustomPointerSensor"

export interface IImageItem {
	// could be a fileName, for example
	name: string
	// link to a concrete image (preferably thumbnail variation)
	src: string
	// if user adds new image, it won't be uploaded instantly
	isUploaded: boolean
	// is image was added within current session
	isNew: boolean
}

interface IArtworkImagesProps {
	images: IImageItem[]
	// invokes every time when array of images changes
	onUpdateFn: (items: IImageItem[]) => void
	// invokes only when a new image is added (so we can perform an upload, for example)
	onNewImageFn: (files: File[], artworkId: string) => void
	artworkId: string
	shouldHighlight?: boolean
	readonly?: boolean
	className?: string
}

function withElementReordered<T>(array: T[], sourceIndex: number, destinationIndex: number): T[] {
	const result = Array.from(array)
	const [removed] = result.splice(sourceIndex, 1)
	result.splice(destinationIndex, 0, removed)

	return result
}

function withElementRemoved<T>(array: T[], sourceIndex: number): T[] {
	const result = Array.from(array)
	result.splice(sourceIndex, 1)

	return result
}

/**
 * This component is responsible for viewing and re-ordering images
 * Simply, it is combination of Drag-n-drop (using dnd-kit) and dropzone (react-dropzone)
 */
export const ArtworkImages: React.FC<IArtworkImagesProps> = (props) => {
	const { images, onUpdateFn, onNewImageFn, readonly, shouldHighlight, artworkId, className } = props
	const { t } = useTranslation()

	const findImageIndex = (imageSrc: string) => images.findIndex((image) => image.src === imageSrc)

	// simply moving element to the new position and updating the resulted array
	const onDragEnd = useCallback(
		(dropResult: DragEndEvent) => {
			const { active, over } = dropResult
			if (over === null || typeof over === "undefined") {
				return
			}
			const oldIndex = findImageIndex(active.id)
			const newIndex = findImageIndex(over.id)

			const reordered = withElementReordered(images, oldIndex, newIndex)
			onUpdateFn(reordered)
		},
		[images]
	)

	const onImageDelete = useCallback(
		(imageId: string) => {
			const index = findImageIndex(imageId)
			const deleted = withElementRemoved(images, index)
			onUpdateFn(deleted)
		},
		[images]
	)

	const onDrop = useCallback(
		async (originalFiles: File[]) => {
			onNewImageFn(originalFiles, artworkId)
		},
		[images, artworkId]
	)

	const { getRootProps, getInputProps, open } = useDropzone({
		accept: "image/*",
		multiple: true,
		noClick: true,
		noKeyboard: true,
		onDrop: onDrop,
		disabled: readonly,
	})

	const deleteConfirmFC = (imageId: string) =>
		warnConfirmDialog({
			title: t("warnings.deleteImageConfirmTitle"),
			description: t("warnings.deleteImageConfirmDesc"),
			cancelText: t("common.cancel"),
			confirmText: t("common.delete"),
			onConfirm: () => onImageDelete(imageId),
		})

	const sensors = useSensors(useSensor(CustomPointerSensor))

	return (
		<div {...getRootProps()}>
			<input {...getInputProps()} />
			<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
				<div
					className={classNames(styles.droppable, {
						[styles.readonly]: readonly,
						[styles.highlighted]: shouldHighlight,
						[`${className}`]: !!className,
					})}
				>
					<SortableContext items={images.map((image) => image.src)} strategy={rectSortingStrategy}>
						{images.map((image) => (
							<Sortable key={image.src} image={image} getDialogNode={deleteConfirmFC} />
						))}
					</SortableContext>
					{!readonly && (
						<button className={styles.uploadButton} onClick={open}>
							<div className={styles.iconWrapper}>
								<UploadIcon />
							</div>
							<div className={styles.captionWrapper}>{t("images.uploadImages")}</div>
						</button>
					)}
				</div>
			</DndContext>
		</div>
	)
}
