import { IFieldArrayTableMappers } from "@components/FieldArrayTable"
import { IDetailsForm } from "@containers/WorkDetailsContainer/types"
import { packPrice, unpackPrice } from "@utilities/currency/unifiedPrice"
import formatISO from "date-fns/formatISO"
import { ITemporaryAdmissionEntity } from "@typed/api/responses/WorkDetails"
import { ITemporaryAdmissionEntityForm } from "./types"

export const getDefaultTemporaryAdmissionEntity = (): ITemporaryAdmissionEntityForm => ({
	importId: "",
	exportId: "",
	entryDate: undefined,
	returnDate: undefined,
	declaredValue: undefined,
	declaredValueCurrency: "USD",
	importDuty: undefined,
	importDutyCurrency: "USD",
})

export const temporaryAdmissionMappings: IFieldArrayTableMappers<
	IDetailsForm,
	"fields.temporaryAdmission",
	ITemporaryAdmissionEntityForm
> = {
	toDialogEntity: (entity) => {
		const declaredValueUnpacked = entity.declared_value ? unpackPrice(entity.declared_value) : null
		const importDutyUnpacked = entity.import_duty ? unpackPrice(entity.import_duty) : null

		return {
			importId: entity.import_id,
			exportId: entity.export_id,
			entryDate: !entity.entry_date ? undefined : new Date(entity.entry_date),
			returnDate: !entity.return_date ? undefined : new Date(entity.return_date),
			declaredValue: declaredValueUnpacked?.value || undefined,
			declaredValueCurrency: declaredValueUnpacked?.currency || "USD",
			importDuty: importDutyUnpacked?.value || undefined,
			importDutyCurrency: importDutyUnpacked?.currency || "USD",
		}
	},
	toEntity: (dialogEntity) => {
		return {
			import_id: dialogEntity.importId,
			export_id: dialogEntity.exportId,
			entry_date: dialogEntity.entryDate ? formatISO(new Date(dialogEntity.entryDate)) : null,
			return_date: dialogEntity.returnDate ? formatISO(new Date(dialogEntity.returnDate)) : null,
			declared_value: packPrice(dialogEntity.declaredValue, dialogEntity.declaredValueCurrency),
			import_duty: packPrice(dialogEntity.importDuty, dialogEntity.importDutyCurrency),
			// we don't have notes field, although backend does send empty notes field
			notes: "",
		}
	},
}

export const sortTemporaryAdmissionEntities = (
	leftEntity: ITemporaryAdmissionEntity,
	rightEntity: ITemporaryAdmissionEntity
): number => {
	if (leftEntity.entry_date && rightEntity.entry_date) {
		if (leftEntity.entry_date == rightEntity.entry_date) {
			if (leftEntity.return_date && rightEntity.return_date) {
				return +new Date(rightEntity.return_date) - +new Date(leftEntity.return_date)
			} else if (leftEntity.return_date && !rightEntity.return_date) {
				return -1
			} else if (!leftEntity.return_date && rightEntity.return_date) {
				return 1
			}
		}
		return +new Date(rightEntity.entry_date) - +new Date(leftEntity.entry_date)
	} else if (leftEntity.entry_date && !rightEntity.entry_date) {
		return -1
	} else if (!leftEntity.entry_date && rightEntity.entry_date) {
		return 1
	} else {
		return 0
	}
}
