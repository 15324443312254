import React from "react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import Image from "@components/Image"
import * as styles from "@components/ArtworkImages/ArtworkImages.module.scss"
import * as animationStyles from "@components/ButtonWithDialog/Animations.module.scss"
import { ReactComponent as RemoveIcon } from "@icons/RemoveIcon.svg"
import Loader from "react-loader-spinner"
import classNames from "classnames"
import { IImageItem } from "@components/ArtworkImages/ArtworkImages"
import ButtonWithDialog, { TDialogNode } from "@components/ButtonWithDialog"
import IconButton from "@components/IconButton"
import { useTranslation } from "react-i18next"

interface IDraggableProps {
	image: IImageItem
	getDialogNode: (imageId: string) => TDialogNode
}

/**
 * This component represents single item in images gridview
 */
export const Sortable: React.FC<IDraggableProps> = (props) => {
	const { image, getDialogNode } = props
	const { t } = useTranslation()

	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id: image.src,
	})

	const style = {
		transform: CSS.Transform.toString(transform) || "",
		transition: transition || "",
		zIndex: isDragging ? 1 : 0,
	}

	return (
		<div
			ref={setNodeRef}
			style={style}
			{...listeners}
			{...attributes}
			className={classNames(styles.draggable, {
				[styles.isDragging]: isDragging,
				[styles.isUploading]: !image.isUploaded,
			})}
		>
			<Image src={image.src} alt="" height="100%" width="100%" className={styles.image} />
			<ButtonWithDialog
				button={({ ref }) => (
					<IconButton ref={ref} className={styles.removeIcon} icon={<RemoveIcon />} label={t("common.delete")} />
				)}
				dialogNode={getDialogNode(image.src)}
				transitionClasses={animationStyles}
			/>
			<div className={styles.loadingSpinner}>
				<Loader type="Oval" color="#737373" height={60} width={60} />
			</div>
		</div>
	)
}
