import { Reducer } from "redux"
import { OrganisationActionTypes, TOrganisationAction, TOrganisationActionsSets } from "./types"
import { IOrganisation } from "@typed/api/responses/Organisation"
import { defaultRequestState, commonReducerCreator, TStateWithRequestStateGroup } from "@store/shared/requestState"
import * as actionSets from "./actionSets"

export interface IOrganisationStoreState extends TStateWithRequestStateGroup<TOrganisationActionsSets> {
	readonly orgs: IOrganisation[]
}

export const initOrganisationStoreState: IOrganisationStoreState = {
	orgs: [],
	requests: defaultRequestState(actionSets),
}

const commonReducer = commonReducerCreator<IOrganisationStoreState, TOrganisationAction>(actionSets)

export const OrganisationReducer: Reducer<IOrganisationStoreState, TOrganisationAction> = (
	state = initOrganisationStoreState,
	action: TOrganisationAction
) => {
	switch (action.type) {
		case OrganisationActionTypes.FetchUserOrgsSuccess:
			return {
				...commonReducer(state, action),
				orgs: action.payload.orgs,
			}
		case OrganisationActionTypes.FetchUserOrgsFailure:
			return {
				...commonReducer(state, action),
				orgs: [],
			}
		default:
			return commonReducer(state, action)
	}
}
