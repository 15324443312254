import React from "react"
import { ILinkShareItem } from "@typed/entities/SharedWork"
import { dimensionsStringCm } from "@utilities"

type TWorkDetailsProps = {
	item: ILinkShareItem
}

export const WorkDetails: React.FC<TWorkDetailsProps> = ({ item }) => {
	return (
		<>
			<div>{item.artist}</div>
			<div>{item.title}</div>
			<div>{item.medium}</div>
			<div>
				{dimensionsStringCm({
					height: item.dim_height,
					width: item.dim_width,
					depth: item.dim_depth,
				})}
			</div>
			<div>{item.year}</div>
		</>
	)
}
