import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { fetchStatusesRequest, getStatuses } from "@store/ducks/statuses"
import { IStatus } from "@typed/api/responses/Workflows"

export type TStatusesHookResult = {
	statuses: IStatus[] | undefined
}

export default function useStatuses(): TStatusesHookResult {
	const dispatch = useDispatch()
	const statuses = useSelector(getStatuses)

	useEffect(() => {
		if (typeof statuses === "undefined") {
			dispatch(fetchStatusesRequest())
		}
	}, [statuses])

	return { statuses }
}
