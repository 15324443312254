import React from "react"
import { TAvailabilityIcon, TAvailabilityIconMapping } from "@typed/entities/AvailabilityIcons"

import { ReactComponent as DotFilledYellowIcon } from "@icons/statuses/DotFilledYellowIcon.svg"
import { ReactComponent as DotFilledRedIcon } from "@icons/statuses/DotFilledRedIcon.svg"
import { ReactComponent as DotFilledPurpleIcon } from "@icons/statuses/DotFilledPurpleIcon.svg"
import { ReactComponent as DotFilledGreyIcon } from "@icons/statuses/DotFilledGreyIcon.svg"
import { ReactComponent as DotFilledGreenIcon } from "@icons/statuses/DotFilledGreenIcon.svg"
import { ReactComponent as DotBlankPurpleIcon } from "@icons/statuses/DotBlankPurpleIcon.svg"
import { ReactComponent as DotBlankGreenIcon } from "@icons/statuses/DotBlankGreenIcon.svg"
import { ReactComponent as DotBlankBlueIcon } from "@icons/statuses/DotBlankBlueIcon.svg"
import { ReactComponent as CrossRedIcon } from "@icons/statuses/CrossRedIcon.svg"
import { ReactComponent as ArrowRightGreyIcon } from "@icons/statuses/ArrowRightGreyIcon.svg"

const availabilityIconMapping: TAvailabilityIconMapping = {
	dotFilledYellow: () => <DotFilledYellowIcon />,
	dotFilledRed: () => <DotFilledRedIcon />,
	dotFilledPurple: () => <DotFilledPurpleIcon />,
	dotFilledGrey: () => <DotFilledGreyIcon />,
	dotFilledGreen: () => <DotFilledGreenIcon />,
	dotBlankPurple: () => <DotBlankPurpleIcon />,
	dotBlankGreen: () => <DotBlankGreenIcon />,
	dotBlankBlue: () => <DotBlankBlueIcon />,
	crossRed: () => <CrossRedIcon />,
	arrowRightGrey: () => <ArrowRightGreyIcon />,
}

interface IAvailabilityLabelProps {
	availabilityIcon: TAvailabilityIcon | undefined
}

export const AvailabilityLabel: React.FC<IAvailabilityLabelProps> = (props) => {
	let availIcon = props.availabilityIcon
	// extra check: we need to know that we have this icon
	if (availIcon && !(availIcon in availabilityIconMapping)) {
		availIcon = undefined
	}
	return (availIcon && availabilityIconMapping[availIcon]()) || null
}
