import { IWorkDetails } from "@typed/api/responses/WorkDetails"
import { TWorkTypeStrings, WorkType } from "@typed/entities"
import { TSearchState } from "@store/ducks/inventory"
import { TSearchParams } from "@typed/entities/Search"

/**
 * We are using `Optimistic UI` approach which means we first apply changed locally and ten wait for
 * backend
 *
 * Therefore we want pure functions that will be able to transform some data
 */

export function deleted(items: IWorkDetails[]): IWorkDetails[] {
	return items.map((item) => ({
		...item,
		is_deleted: true,
	}))
}

export function mappedWorkType(items: IWorkDetails[]): IWorkDetails[] {
	return items.map((item) => {
		const mappedWorkType = WorkType[item.work_type as TWorkTypeStrings] as string | undefined
		return {
			...item,
			work_type: mappedWorkType ?? item.work_type,
		}
	})
}

export function buildSearchParams(searchState: TSearchState): TSearchParams {
	const { query, collectionsIds, filters, sorting } = searchState

	return {
		query,
		collectionsIds,
		filters,
		sorting: {
			[sorting.property]: sorting.order,
			id: "DESC",
		},
	}
}
