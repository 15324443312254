import { ActionType } from "typesafe-actions"
import * as actions from "./actions"
import * as actionSets from "./actionSets"
import { TFilters, TOrder } from "@typed/entities/Search"
import { IWorkDetails } from "@typed/api/responses/WorkDetails"

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-namespace */

export namespace InventoryActionTypes {
	export const FetchInventoryRequest = actionSets.fetchInventory.request
	export const FetchInventorySuccess = actionSets.fetchInventory.success
	export const FetchInventoryFailure = actionSets.fetchInventory.failure
	export const MergeDataIntoInventory = "inventory/MERGE_DATA_INTO_INVENTORY"
	export const GetInventoryItemRequest = "inventory/GET_INVENTORY_ITEM_REQUEST"
	export const GetInventoryItemFailure = "inventory/GET_INVENTORY_ITEM_FAILURE"
	export const PutItemRequest = actionSets.putItem.request
	export const PutItemSuccess = actionSets.putItem.success
	export const PutItemFailure = actionSets.putItem.failure
	export const DeleteItemRequest = actionSets.deleteItem.request
	export const DeleteItemSuccess = actionSets.deleteItem.success
	export const DeleteItemFailure = actionSets.deleteItem.failure
	export const SetSearchQueryRequest = "inventory/SET_SEARCH_QUERY_REQUEST" // will be obsolete soon
	export const SetSearchQuerySuccess = "inventory/SET_SEARCH_QUERY_SUCCESS" // will be obsolete soon
	export const ApplyFilterRequest = "inventory/APPLY_FILTER_REQUEST"
	export const SetFilterResult = "inventory/SET_FILTER_RESULT"
	export const SetItemSelected = "inventory/SET_ITEM_SELECTED"
	export const SetFilterItemsSelected = "inventory/SET_FILTER_ITEMS_SELECTED"
	export const ClearItemsSelection = "inventory/CLEAR_ITEM_SELECTION"
	export const ProcessItemClick = "inventory/PROCESS_ITEM_CLICK"
	export const SetItemForPreview = "inventory/SET_ITEM_FOR_PREVIEW"
	export const ClearItemPreview = "inventory/CLEAR_ITEM_PREVIEW"
	export const SetupAutocomplete = "inventory/SETUP_AUTOCOMPLETE"
	export const InsertIntoAutocomplete = "inventory/INSERT_INTO_AUTOCOMPLETE"
	export const ResetServices = "inventory/RESET_SERVICES"
}

export type TInventoryAction = ActionType<typeof actions>
export type TInventoryActionsSets = typeof actionSets

export type TSearchState = {
	query?: string
	collectionsIds?: string[]
	filters: TFilters
	sorting: {
		property: keyof IWorkDetails
		order: TOrder
	}
}
