import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef } from "react"
import * as styles from "./AddCollectionPopover.module.scss"
import * as defaultListStyles from "@components/List/List.module.scss"
import * as animationStyles from "@components/ButtonWithPopover/Animations.module.scss"
import ButtonWithPopover from "@components/ButtonWithPopover"
import withAutocomplete from "@enhancers/withAutocomplete"
import AutocompleteSelection from "@components/AutocompleteSelection"
import IconButton from "@components/IconButton"
import { ReactComponent as PlusIcon } from "@icons/PlusIcon.svg"
import { useTranslation } from "react-i18next"
import { IAutocompleteSelectionProps } from "@components/AutocompleteSelection/AutocompleteSelection"
import classNames from "classnames"

interface IAddCollectionPopoverProps {
	buttonIcon: React.ReactNode
	buttonClassName: string
	label: string
	showLabel?: boolean
	onAddToCollection?: (collectionId: string) => void
	onRemoveFromCollection?: (collectionId: string) => void
	onCreateCollection?: (collectionName: string) => void
	appliedCollectionsIds?: string[]
}

const CollectionAutocompleteSelection = withAutocomplete<IAutocompleteSelectionProps>(
	"collections",
	AutocompleteSelection
)

interface IPopoverContentsProps {
	onAddToCollection?: (collectionId: string) => void
	onRemoveFromCollection?: (collectionId: string) => void
	onCreateCollection?: (collectionName: string) => void
	appliedCollectionsIds?: string[]
	closePopover: () => void
}

const PopoverContents: React.FC<IPopoverContentsProps> = (props) => {
	const { appliedCollectionsIds } = props
	const { closePopover, onAddToCollection, onRemoveFromCollection, onCreateCollection } = props
	const { t } = useTranslation()

	const appliedCollectionsIdsSet = appliedCollectionsIds && new Set(appliedCollectionsIds)
	// using memo with empty deps
	// because we want to fix the set of collections that was applied when the popup was opened
	const initialCollectionsIdsSet = useMemo(() => appliedCollectionsIdsSet, [])

	// focus input on component open
	const inputRef = useRef() as MutableRefObject<HTMLInputElement>
	useEffect(() => inputRef.current.focus(), [])

	return (
		<div className={styles.inputWrapper}>
			<CollectionAutocompleteSelection
				inputPlaceholder={t("itemActions.collectionInputPlaceholder")}
				inputRef={inputRef}
				suggestionNodeFC={({ item }) => {
					return (
						<button
							className={classNames(defaultListStyles.listItem, styles.suggestion)}
							key={item.id}
							onClick={() => {
								if (typeof appliedCollectionsIdsSet === "undefined") {
									closePopover()
								}
							}}
						>
							{typeof appliedCollectionsIdsSet !== "undefined" && (
								<input
									className={styles.checkbox}
									type="checkbox"
									checked={appliedCollectionsIdsSet.has(item.id)}
									readOnly
								/>
							)}
							{item.value}
						</button>
					)
				}}
				createButtonFC={(props) => {
					return (
						<IconButton
							className={classNames(defaultListStyles.listItem, styles.suggestion, styles.newSuggestion)}
							icon={<PlusIcon />}
							label={t("inventory.createCollectionCaption", { collectionName: props.value })}
							showLabel={true}
							onClick={() => props.onClick()}
						/>
					)
				}}
				onSuggestionClick={(id) => {
					if (appliedCollectionsIdsSet?.has(id)) {
						typeof onRemoveFromCollection !== "undefined" && onRemoveFromCollection(id)
					} else {
						typeof onAddToCollection !== "undefined" && onAddToCollection(id)
					}
				}}
				onCreateButtonClick={(value) => {
					typeof onCreateCollection !== "undefined" && onCreateCollection(value)
					closePopover()
				}}
				sortFn={(left, right) => {
					if (initialCollectionsIdsSet?.has(left) == initialCollectionsIdsSet?.has(right)) {
						return left.localeCompare(right)
					} else if (initialCollectionsIdsSet?.has(left)) {
						return -1
					} else {
						return 1
					}
				}}
			/>
		</div>
	)
}

export const AddCollectionPopover: React.FC<IAddCollectionPopoverProps> = (props) => {
	const { buttonIcon, buttonClassName, appliedCollectionsIds, label, showLabel } = props
	const { onAddToCollection, onRemoveFromCollection, onCreateCollection } = props

	const InnerElement = useCallback(
		({ closePopover }) => (
			<PopoverContents
				closePopover={closePopover}
				appliedCollectionsIds={appliedCollectionsIds}
				onAddToCollection={onAddToCollection}
				onRemoveFromCollection={onRemoveFromCollection}
				onCreateCollection={onCreateCollection}
			/>
		),
		[onAddToCollection, onRemoveFromCollection, onCreateCollection, appliedCollectionsIds]
	)

	return (
		<ButtonWithPopover
			button={(props) => (
				<IconButton
					className={buttonClassName}
					icon={buttonIcon}
					label={label || ""}
					showLabel={showLabel}
					{...props}
				/>
			)}
			popoverNode={InnerElement}
			popoverClassName={styles.popover}
			transitionClasses={animationStyles}
			placement="auto-start"
		/>
	)
}
