import React from "react"
import ButtonWithPopover, { TPopoverNode } from "@components/ButtonWithPopover"
import * as styles from "./ImportArtworksPopover.module.scss"
import * as animationStyles from "@components/ButtonWithPopover/Animations.module.scss"
import { Link } from "react-router-dom"
import { StaticRoutes } from "@routes"
import { ReactComponent as DropdownTriangleIcon } from "@icons/DropdownTriangleIcon.svg"
import { useTranslation } from "react-i18next"
import IconButton from "@components/IconButton"
import classNames from "classnames"

interface IImportArtworksPopoverProps {
	className: string
}

export const ImportArtworksPopover: React.FC<IImportArtworksPopoverProps> = (props) => {
	const { className } = props
	const { t } = useTranslation()

	const innerElement: TPopoverNode = () => (
		<div className={styles.wrapper}>
			<Link to={StaticRoutes.ImportFromArtLogic} className={styles.linkItem}>
				{t("inventory.importFromArtLogic")}
			</Link>
			<Link to={StaticRoutes.ImportFromFile} className={styles.linkItem}>
				{t("inventory.importFromCSV")}
			</Link>
		</div>
	)

	return (
		<ButtonWithPopover
			button={(props) => (
				<IconButton
					className={classNames(styles.button, className)}
					icon={<DropdownTriangleIcon />}
					label={t("inventory.import")}
					showLabel
					{...props}
				/>
			)}
			popoverNode={innerElement}
			popoverClassName=""
			transitionClasses={animationStyles}
			placement="bottom-end"
		/>
	)
}
