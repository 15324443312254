import { IStatus } from "@typed/api/responses/Workflows"

interface IDefaultStatusInfo {
	defaultStatusId: string
	defaultAvailabilityId: string
}

export default function getDefaultStatus(statuses: IStatus[]): IDefaultStatusInfo {
	return {
		defaultStatusId: statuses[1].id,
		defaultAvailabilityId: statuses[1].availabilities[0].id,
	}
}
