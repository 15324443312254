import React, { CSSProperties, MutableRefObject, useRef, useState } from "react"
import { areEqual, VariableSizeList, ListChildComponentProps } from "react-window"
import ItemsListRow from "./ItemsListRow"
import * as styles from "./ItemsListContainer.module.scss"
import { connect } from "react-redux"
import useResizeObserver from "@react-hook/resize-observer"
import { TProps, TRenderRowData } from "./types"
import { getFilteredItems, getLastFetchTimestamp } from "@store/ducks/inventory/selectors"
import areArraysEqual from "@utilities/arrays/areArraysEqual"
import classNames from "classnames"

const itemHeight = 56
const topPadding = 12

const RenderRow = React.memo<ListChildComponentProps<TRenderRowData>>(({ data, index, style }) => {
	const row = data[index]
	const additionalStyles: CSSProperties =
		index == 0 ? { display: "flex", flexDirection: "column", justifyContent: "flex-end" } : {}

	return (
		<div style={{ ...style, ...additionalStyles }}>
			<ItemsListRow itemId={row.id} />
		</div>
	)
}, areEqual)

const ItemsListContainer: React.FC<TProps["Combined"]> = React.memo(
	(props) => {
		const { items, className } = props
		const combinedClassNames = className ? classNames(styles.table, className) : styles.table
		const getItemHeight = (index: number) => (index == 0 ? itemHeight + topPadding : itemHeight)

		// using ResizeObserver API to subscribe on height change
		// we need this while `react-window` requires element height
		// TODO: probably add debounce for ResizeObserver
		const [elementHeight, setElementHeight] = useState(0)
		const containerRef = useRef() as MutableRefObject<HTMLDivElement>
		useResizeObserver(containerRef, (rect) => {
			setElementHeight(rect.target.clientHeight)
		})

		return (
			<div className={combinedClassNames} ref={containerRef}>
				<div>
					<VariableSizeList
						height={elementHeight}
						itemCount={items.length}
						itemData={items}
						itemSize={getItemHeight}
						width={"100%"}
					>
						{RenderRow}
					</VariableSizeList>
				</div>
			</div>
		)
	},
	(prev, next) =>
		areArraysEqual(
			prev.items.map((item) => item.id),
			next.items.map((item) => item.id)
		)
)

const mapStateToProps: TProps["State"] = (state) => {
	const lastFetch = getLastFetchTimestamp(state)
	return {
		items: lastFetch ? getFilteredItems(state).map((itemId) => ({ id: itemId })) : [],
	}
}

export const ItemsListContainerConnected = connect(mapStateToProps)(ItemsListContainer)
