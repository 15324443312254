import React from "react"

type TButtonNode = React.FC<{
	open: boolean
	onClick: () => void
}>

interface IDisclosureProps {
	open: boolean
	setOpen: (state: boolean) => void
	button: TButtonNode
	contents: React.ReactNode
}

export const Disclosure: React.FC<IDisclosureProps> = (props) => {
	const { open, button, contents, setOpen } = props
	const onClick = () => setOpen(!open)

	return (
		<>
			{button({ open, onClick })}
			{open && contents}
		</>
	)
}
