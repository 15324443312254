import { createStore, applyMiddleware } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import createSagaMiddleware from "redux-saga"
import rootSaga from "@store/rootSaga"
import createRootReducer from "@store/rootReducer"
import { routerMiddleware as createRouterMiddleware } from "connected-react-router"
import { browserHistory } from "@routes"
import { composeWithDevTools } from "redux-devtools-extension"
import createAnalyticsMiddleware from "@store/middleware/analyticsMiddleware"
import * as MixpanelService from "@services/analytics/implementations/mixpanel"
import * as Sentry from "@sentry/react"
import { getEnvironmentString } from "@services/environment"

// Adding persistence to auth details
const persistConfig = {
	key: "root",
	storage,
	whitelist: ["auth"],
	version: 1,
}

const persistedReducer = persistReducer(persistConfig, createRootReducer(browserHistory))

const sagaMiddleware = createSagaMiddleware({
	onError: (error) => {
		Sentry.captureException(error)
		console.error(error)
	},
})
const routerMiddleware = createRouterMiddleware(browserHistory)
const mixpanelMiddleware = createAnalyticsMiddleware(
	MixpanelService.createEvent,
	MixpanelService.trackEvent,
	1000,
	process.env.REACT_APP_ENV === "development"
)

const appliedMiddleware = applyMiddleware(routerMiddleware, sagaMiddleware, mixpanelMiddleware)

export const store = createStore(
	persistedReducer,
	getEnvironmentString() === "development-local" ? composeWithDevTools(appliedMiddleware) : appliedMiddleware
)
sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)
export type { TRootState } from "./rootReducer"
