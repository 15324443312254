import { StatusesActionTypes } from "./types"
import { createAction } from "typesafe-actions"
import { createErrorAction } from "@store/shared/errorsHandling"
import { IStatus } from "@typed/api/responses/Workflows"

export const fetchStatusesRequest = createAction(StatusesActionTypes.FetchStatusesRequest)()
export const fetchStatusesSuccess = createAction(StatusesActionTypes.FetchStatusesSuccess, (statuses) => ({
	statuses,
}))<{
	statuses: IStatus[]
}>()
export const fetchStatusesFailure = createErrorAction(StatusesActionTypes.FetchStatusesFailure)()
