import React, { ChangeEvent, useCallback, useMemo } from "react"
import ButtonWithPopover, { TPopoverNode } from "@components/ButtonWithPopover"
import * as animationStyles from "@components/ButtonWithPopover/Animations.module.scss"
import * as styles from "./SortPopover.module.scss"
import { TProps } from "./types"
import { getSearchState } from "@store/ducks/inventory/selectors"
import { applyFilterRequest } from "@store/ducks/inventory"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { ReactComponent as SortIcon } from "@icons/SortIcon.svg"
import IconButton from "@components/IconButton"
import { IWorkDetails } from "@typed/api/responses/WorkDetails"
import { TOrder } from "@typed/entities/Search"

type TSortOption = {
	label: string
	order: TOrder
}

type TSortOptionsRecord = {
	[Property in keyof IWorkDetails]?: TSortOption
}
type TSortOptionsArray = [string, TSortOption][]

const sortOptions: TSortOptionsRecord = {
	createdAt: {
		label: "Date added",
		order: "DESC",
	},
	lastUpdated: {
		label: "Last update",
		order: "DESC",
	},
	artist: {
		label: "Artist",
		order: "ASC",
	},
}

export const SortPopover: React.FC<TProps["Combined"]> = (props) => {
	const {
		className,
		searchState: { sorting },
	} = props
	const { applySearchQueryRequest } = props
	const { t } = useTranslation()

	const optionValues = useMemo<TSortOptionsArray>(() => {
		return Object.entries(sortOptions) as unknown as TSortOptionsArray
	}, [sortOptions])

	const onChangeCallback = useCallback<(ev: ChangeEvent<HTMLInputElement>) => void>(
		(event) => {
			const itemProperty = event.target.value as keyof IWorkDetails
			const sortOption = sortOptions[itemProperty]
			if (!sortOption) {
				return
			}

			const order = sortOption.order
			applySearchQueryRequest({
				sorting: {
					property: itemProperty,
					order: order,
				},
			})
		},
		[applySearchQueryRequest]
	)

	const innerElement: TPopoverNode = useCallback(
		() => (
			<div className={styles.sortPopover}>
				<h2 className={styles.header}>Sort by</h2>
				<section>
					{optionValues.map(([itemProperty, { label }]) => (
						<label htmlFor={itemProperty} className={styles.option} key={itemProperty}>
							<span className={styles.label}>{label}</span>
							<input
								name="sort"
								value={itemProperty}
								type="radio"
								id={itemProperty}
								className={styles.radio}
								checked={sorting.property == itemProperty}
								onChange={onChangeCallback}
							/>
						</label>
					))}
				</section>
			</div>
		),
		[optionValues, sorting, onChangeCallback]
	)

	return (
		<ButtonWithPopover
			button={(props) => (
				<IconButton
					className={classNames(styles.iconButton, className)}
					icon={<SortIcon />}
					label={t("inventory.sortingOptions")}
					{...props}
				/>
			)}
			popoverNode={innerElement}
			popoverClassName=""
			transitionClasses={animationStyles}
			placement="bottom-start"
			offset={[0, 14]}
		/>
	)
}

const mapStateToProps: TProps["State"] = (state) => {
	return {
		searchState: getSearchState(state),
	}
}

const mapDispatchToProps: TProps["Dispatch"] = (dispatch) => {
	return {
		applySearchQueryRequest: (params) => {
			dispatch(applyFilterRequest(params))
		},
	}
}

export const SortPopoverConnected = connect(mapStateToProps, mapDispatchToProps)(SortPopover)
