export enum WorkType {
	none = "",
	unknown_work_type = "Other",
	painting = "Painting",
	multiple_works = "Print/Edition",
	on_paper = "Work On Paper",
	installation = "Installation",
	photography = "Photography",
	sculpture = "Sculpture",
}

export function getWorkTypesValues(): string[] {
	return Object.values(WorkType).filter((value) => value != "")
}

export type TWorkTypeStrings = keyof typeof WorkType
