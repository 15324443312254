import React from "react"
import * as styles from "./ItemsGridCell.module.scss"
import { connect, shallowEqual } from "react-redux"
import { areAnyFilterItemsSelected, getInventoryItem, isInventoryItemSelected } from "@store/ducks/inventory/selectors"
import { processItemClick, setItemSelected } from "@store/ducks/inventory"
import { TProps } from "./types"
import ThumbnailImage from "@components/ThumbnailImage"
import { dimensionsStringIns } from "@utilities"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { getItemSelectionTitle } from "@containers/ItemsGridContainer/ItemsGridCell/functions"
import { joinStrings } from "@utilities/strings/formatting"
import useDoubleClick from "@enhancers/useDoubleClick"
import { getAvailability } from "@store/ducks/statuses"
import AvailabilityLabel from "@components/AvailabilityLabel"

/**
 * Single cell for GridView of inventory
 */

export const ItemsGridCell: React.FC<TProps["Combined"]> = React.memo(
	(props) => {
		const { style, thumbnailSrc, title, author, year, material, dims, status, availability } = props
		const { isItemSelected, forceShowCheckbox, onCheckboxClicked, onItemClicked, onItemDoubleClicked } = props
		const hasYear = year && year.length > 0
		const { t } = useTranslation()
		const doubleClickAttrs = useDoubleClick({
			onClick: onItemClicked,
			onDoubleClick: onItemDoubleClicked,
			timeout: 300,
		})

		return (
			<button
				style={style}
				className={classNames(styles.gridCell, {
					[styles.forceShowCheckbox]: forceShowCheckbox,
				})}
				{...doubleClickAttrs}
			>
				<div className={styles.itemThumbnailWrapper}>
					<div className={styles.checkboxWrapper}>
						<input
							type="checkbox"
							className={styles.checkbox}
							checked={isItemSelected}
							title={getItemSelectionTitle(isItemSelected)(t)}
							onChange={(event) => {
								onCheckboxClicked(event.target.checked)
							}}
							onClick={(event) => event.stopPropagation()}
						/>
					</div>
					<ThumbnailImage
						width={style.width}
						height={style.width}
						emptyImageWidth={92}
						className={styles.thumbnailImage}
						alt={joinStrings([author, title], ", ")}
						src={thumbnailSrc}
					/>
				</div>
				<div className={styles.itemDetails}>
					<div className={styles.itemDetail}>
						<abbr
							title={status && availability ? `${status?.name} - ${availability?.name}` : ""}
							className={styles.status}
						>
							<AvailabilityLabel availabilityIcon={availability?.icon?.symbol} />
						</abbr>
					</div>
					<span className={classNames(styles.itemDetail, styles.itemAuthor)}>{author}</span>
					<div className={styles.itemDetail}>
						<span className={styles.itemTitle}>{title}</span>
						{hasYear && `, ${year}`}
					</div>
					<span className={`${styles.itemDetail}`}>{material}</span>
					<span className={`${styles.itemDetail}`}>{dimensionsStringIns(dims)}</span>
				</div>
			</button>
		)
	},
	(prev, next) => {
		// we check if the item was removed
		// (in this case we still need to show it for a small period of time to achieve animation)
		if (prev.isRemoved === false && prev.isRemoved !== next.isRemoved) {
			return true
		}

		return shallowEqual(prev, next)
	}
)

const mapStateToProps: TProps["State"] = (state, ownProps) => {
	const row = getInventoryItem(ownProps.itemId)(state)

	let availDetails
	if (row?.status && row?.availability) {
		availDetails = getAvailability(state)({ statusId: row.status, availabilityId: row.availability })
	}

	return {
		thumbnailSrc: row?.images ? row?.images[0]?.detail : undefined,
		inventoryId: row?.org_uid,
		author: row?.artist,
		title: row?.title,
		year: row?.year,
		material: row?.medium,
		dims: {
			width: row?.dim_width,
			height: row?.dim_height,
			depth: row?.dim_depth,
		},
		isItemSelected: isInventoryItemSelected(ownProps.itemId)(state),
		forceShowCheckbox: areAnyFilterItemsSelected(state),
		style: ownProps.style,
		status: availDetails?.status,
		availability: availDetails?.availability,
		isRemoved: typeof row === "undefined",
	}
}

const mapDispatchToProps: TProps["Dispatch"] = (dispatch, ownProps) => {
	return {
		onCheckboxClicked: (checked: boolean) => dispatch(setItemSelected(ownProps.itemId, checked)),
		onItemClicked: () => dispatch(processItemClick(ownProps.itemId, "single")),
		onItemDoubleClicked: () => dispatch(processItemClick(ownProps.itemId, "double")),
	}
}

export const ItemsGridCellConnected = connect(mapStateToProps, mapDispatchToProps)(ItemsGridCell)
