import { useEffect } from "react"

export default function useKeyDown(
	targetKey: string,
	handler: () => void,
	target?: HTMLElement | Document | Window | null
): void {
	const actualTarget: HTMLElement | Document | Window = target || document
	function handleKeyDown(ev: KeyboardEvent) {
		if (ev.key === targetKey) {
			ev.preventDefault()
			ev.stopPropagation()
			handler()
		}
	}

	useEffect(() => {
		actualTarget.addEventListener("keydown", handleKeyDown as EventListener)
		return () => {
			actualTarget.removeEventListener("keydown", handleKeyDown as EventListener)
		}
	}, [handler, actualTarget])
}
