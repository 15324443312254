import React from "react"
import { ILinkShareItem } from "@typed/entities"
import WorkImage from "@components/WorkImage"
import WorkDetails from "@components/WorkDetails"

type TShareDetailsProps = {
	item: ILinkShareItem
}

export const ShareDetails: React.FC<TShareDetailsProps> = ({ item }) => {
	return (
		<div className="row">
			<div className="col-6">
				<WorkImage item={item} />
			</div>
			<div className="col-6">
				<WorkDetails item={item} />
			</div>
		</div>
	)
}
