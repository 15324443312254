import { AuthActionTypes } from "./types"
import { IAuthDetails, ISignUpDetails } from "@typed/entities"
import { createAction } from "typesafe-actions"
import { createErrorAction } from "@store/shared/errorsHandling"

export const initialise = createAction(AuthActionTypes.Initialise)()

export const loginRequest = createAction(AuthActionTypes.LoginRequest, (username, password) => ({
	username,
	password,
}))<{
	username: string
	password: string
}>()
export const loginSuccess = createAction(AuthActionTypes.LoginSuccess)<IAuthDetails>()
export const loginFailure = createErrorAction(AuthActionTypes.LoginFailure)()

export const logoutRequest = createAction(AuthActionTypes.LogoutRequest, (global) => ({
	global,
}))<{
	global: boolean
}>()
export const logoutSuccess = createAction(AuthActionTypes.LogoutSuccess)()

export const signupRequest = createAction(AuthActionTypes.SignupRequest)<ISignUpDetails>()
export const signupSuccess = createAction(AuthActionTypes.SignupSuccess)()
export const signupFailure = createErrorAction(AuthActionTypes.SignupFailure)()

export const verifyRequest = createAction(AuthActionTypes.VerifyRequest, (username, code) => ({
	username,
	code,
}))<{
	username: string
	code: string
}>()
export const verifySuccess = createAction(AuthActionTypes.VerifySuccess)()
export const verifyFailure = createErrorAction(AuthActionTypes.VerifyFailure)()

export const reAuthRequest = createAction(AuthActionTypes.ReAuthRequest)()
export const reAuthSuccess = createAction(AuthActionTypes.ReAuthSuccess)<IAuthDetails>()
export const reAuthFailure = createErrorAction(AuthActionTypes.ReAuthFailure)()

export const updateCognitoIdentityRequest = createAction(AuthActionTypes.UpdateCognitoIdentityRequest)()
export const updateCognitoIdentitySuccess = createAction(AuthActionTypes.UpdateCognitoIdentitySuccess)()
export const updateCognitoIdentityFailure = createErrorAction(AuthActionTypes.UpdateCognitoIdentityFailure)()

export const getCognitoIdentityRequest = createAction(AuthActionTypes.GetCognitoIdentityRequest)()
export const getCognitoIdentitySuccess = createAction(
	AuthActionTypes.GetCognitoIdentitySuccess,
	(identity: string) => ({
		identity,
	})
)<{
	identity: string
}>()
export const getCognitoIdentityFailure = createErrorAction(AuthActionTypes.GetCognitoIdentityFailure)()
