import { TDialogNode } from "@components/ButtonWithDialog"
import * as dialogStyles from "@components/ButtonWithDialog/DialogDefaults.module.scss"
import classNames from "classnames"

interface IWarnConfirmDialogProps {
	title: string
	description: string
	cancelText: string
	confirmText: string
	onConfirm: () => void
}

/**
 * Quite useful function that generates simple dialog layout with "Cancel" / "Delete"
 * buttons ("Delete" button is signaling about some potential danger)
 */
export function warnConfirmDialog(props: IWarnConfirmDialogProps): TDialogNode {
	const { title, description, cancelText, confirmText, onConfirm } = props

	return ({ closeDialog }) => (
		<div className={dialogStyles.dialog}>
			<h2 className={dialogStyles.header}>{title}</h2>
			<div className={dialogStyles.description}>{description}</div>
			<div className={dialogStyles.actionsWrapper}>
				<button className={`${dialogStyles.button}`} onClick={() => closeDialog()}>
					{cancelText}
				</button>
				<button
					className={classNames(dialogStyles.button, dialogStyles.danger)}
					onClick={() => {
						closeDialog()
						onConfirm()
					}}
				>
					{confirmText}
				</button>
			</div>
		</div>
	)
}
