import { ICombinedAPI } from "./definitions"
import {
	putInventoryItem,
	deleteInventoryItem,
	fetchInventory,
	fetchOrganisations,
	fetchRemoteActions,
	fetchShareDetails,
	getInventoryItem,
	importFromArtlogic,
	requestAccessCode,
	setCognitoIdentity,
	getCollections,
	manageCollection,
	createCollection,
	getWorkflowsSchema,
} from "./implementations/rest/common"
import {
	forgotPassword,
	forgotPasswordSubmit,
	getAccessToken,
	getAuthenticatedUser,
	getCognitoIdentity,
	reauthenticate,
	signIn,
	signOut,
	signUp,
	uploadFile,
	verifyUser,
} from "./implementations/aws/amplify"
import { predictArtworkEntities } from "./implementations/rest/ml"
import { getArtists } from "./implementations/rest/static"

// eslint-disable-next-line @typescript-eslint/naming-convention
const API: ICombinedAPI = {
	common: {
		fetchShareDetails,
		requestAccessCode,
		putInventoryItem,
		deleteInventoryItem,
		fetchInventory,
		fetchRemoteActions,
		setCognitoIdentity,
		importFromArtlogic,
		fetchOrganisations,
		getInventoryItem,
		getCollections,
		manageCollection,
		createCollection,
		getWorkflowsSchema,
	},
	amplify: {
		signIn,
		verifyUser,
		signOut,
		reauthenticate,
		getAuthenticatedUser,
		getCognitoIdentity,
		getAccessToken,
		forgotPassword,
		forgotPasswordSubmit,
		signUp,
		uploadFile,
	},
	ml: {
		predictArtworkEntities,
	},
	static: {
		getArtists,
	},
}

export default API
