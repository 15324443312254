import API from "@services/api"
import { fetchUserOrgsSuccess, fetchUserOrgsFailure } from "@store/ducks/orgs/actions"
import { call, put, takeLatest } from "redux-saga/effects"
import { OrganisationActionTypes } from "@store/ducks/orgs/index"

function* fetchOrganisationsWorker() {
	try {
		const orgs = yield call(API.common.fetchOrganisations)
		yield put(fetchUserOrgsSuccess(orgs))
	} catch (error) {
		yield put(fetchUserOrgsFailure(error))
	}
}

export default function* orgsWatcher(): Generator {
	yield takeLatest(OrganisationActionTypes.FetchUserOrgsRequest, fetchOrganisationsWorker)
}
