import React, { Fragment, useEffect, useMemo } from "react"
import { Route, Switch } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"
import { Provider } from "react-redux"
import "./index.scss"

import { store, persistor } from "@store/store"
import { initialise } from "@store/ducks/auth"
import { browserHistory, StaticRoutes } from "@routes"
import ScrollToTop from "@enhancers/ScrollToTop"
import PrivateRoute from "@components/PrivateRoute"
import { ConnectedRouter } from "connected-react-router"
import EmbeddedContext, { isEmbeddedMode } from "@enhancers/EmbeddedContext"

import OrganisationsPage from "@pages/Organisations"
import PostRegister from "@pages/PostRegister"
import PasswordResetRequest from "@pages/PasswordResetRequest"
import EditWorkNew from "@pages/EditWorkNew"
import NewWork from "@pages/NewWork"
import AccountPage from "@pages/AccountPage"
import Register from "@pages/Register"
import Preview from "@pages/Preview"
import Login from "@pages/Login"
import VerifyAccount from "@pages/VerifyAccount"
import ImportArtlogic from "@pages/ImportArtlogic"
import Page404 from "@pages/Page404"
import InventoryNew from "@pages/InventoryNew"
import ImportFile from "@pages/ImportFile"

export const AppLayout: React.FC = () => {
	// check if user is already logged in with cognito and the tokens are valid,
	const loginInit = async () => {
		store.dispatch(initialise())
	}

	useEffect(() => {
		loginInit()
	}, [])

	const query = useMemo(() => new URLSearchParams(window.location.search), [])
	const isEmbedded = useMemo(() => isEmbeddedMode(query), [])

	return (
		<>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<ConnectedRouter history={browserHistory}>
						<Fragment>
							<ScrollToTop />

							<EmbeddedContext.Provider value={{ isEmbedded }}>
								<Switch>
									<Route exact path={StaticRoutes.Home} component={Login} />
									<Route exact path={`${StaticRoutes.PreviewSharedWork}/:sharedKey`} component={Preview} />
									<Route exact path={StaticRoutes.Login} component={Login} />
									<Route exact path={StaticRoutes.Registration} component={Register} />
									<Route exact path={`${StaticRoutes.VerifyUser}/:verPhoneNumber`} component={VerifyAccount} />
									<Route exact path={StaticRoutes.PostRegistration} component={PostRegister} />
									<Route exact path={StaticRoutes.PasswordResetRequest} component={PasswordResetRequest} />
									<PrivateRoute exact path={StaticRoutes.CreateNewWork} component={NewWork} />
									<PrivateRoute exact path={StaticRoutes.UserInventory} component={InventoryNew} />
									<PrivateRoute exact path={`${StaticRoutes.EditWork}/:itemClientID`} component={EditWorkNew} />
									<PrivateRoute exact path={StaticRoutes.ImportFromArtLogic} component={ImportArtlogic} />
									<PrivateRoute exact path={StaticRoutes.ImportFromFile} component={ImportFile} />
									<PrivateRoute exact path={StaticRoutes.AccountPage} component={AccountPage} />
									<PrivateRoute exact path={StaticRoutes.Organisations} component={OrganisationsPage} />
									<Route component={Page404} />
								</Switch>
							</EmbeddedContext.Provider>
						</Fragment>
					</ConnectedRouter>
				</PersistGate>
			</Provider>
		</>
	)
}
