import { ISearchService } from "@services/search/defintions"
import { Document, IDocument, TDocumentSearchResults, TSearchOptions } from "flexsearch"
import { TSearchParams } from "@typed/entities/Search"
import getIntersection from "@utilities/arrays/getIntersection"
import getUnion from "@utilities/arrays/getUnion"
import {
	hasEmptyFilters,
	hasEmptyQuery,
	hasEmptyCollections,
	sanitizeWorkDetails,
	compareByMultipleFields,
	TGetKeyFn,
} from "@services/search/implementations/InventorySearchService/functions"
import * as filters from "./filters"
import { ICollection } from "@typed/entities/InventoryCollection"
import { IWorkDetails } from "@typed/api/responses/WorkDetails"
import splitIntoChunks from "@utilities/arrays/splitIntoChunks"

// keep interface as it part of this particular implementation
export interface ISanitizedWorkDetails {
	id: string
	content: string
	lastUpdated: Date
	createdAt: Date
	artist: string
}

export interface ISanitizedWorkDetailsWithDict extends ISanitizedWorkDetails {
	[key: string]: unknown
}

/**
 * In current implementation we join all indexed string to one `content` field
 * In fact this allows search by multiple fields at the same time
 */
export class InventorySearchService implements ISearchService<IWorkDetails, TSearchParams> {
	private documentIndex: IDocument<ISanitizedWorkDetails>
	private readonly documentMap: Map<string, ISanitizedWorkDetails>
	private readonly collectionsMap: Map<string, string[]> // collectionId -> items with this collectionId
	private archivedSet: Set<string> // items that are archived
	private readonly fieldsList: TGetKeyFn<IWorkDetails>[] = [
		"artist",
		"title",
		"year",
		"stock_code",
		"org_uid",
		"medium",
		"series",
		"location",
		(item) => item.location_history[0]?.name,
	]

	constructor() {
		this.documentIndex = InventorySearchService.createDocumentIndex()
		this.documentMap = new Map()
		this.collectionsMap = new Map()
		this.archivedSet = new Set()
	}

	private static createDocumentIndex(): IDocument<ISanitizedWorkDetails> {
		return new Document({
			tokenize: "forward",
			preset: "memory",
			document: {
				id: "id",
				field: ["content"],
			},
		})
	}

	public async putItem(item: IWorkDetails): Promise<void> {
		// independently of library implementation sanitize data removing all non-necessary fields
		const indexItem = sanitizeWorkDetails(item, this.fieldsList)
		this.documentMap.set(item.id, indexItem)
		if (this.documentIndex.contain(item.id)) {
			return this.documentIndex.updateAsync(indexItem)
		} else {
			return this.documentIndex.addAsync(indexItem)
		}
	}

	public putItemSync(item: IWorkDetails): void {
		// independently of library implementation sanitize data removing all non-necessary fields
		const indexItem = sanitizeWorkDetails(item, this.fieldsList)
		this.documentMap.set(item.id, indexItem)
		if (this.documentIndex.contain(item.id)) {
			return this.documentIndex.update(indexItem)
		} else {
			return this.documentIndex.add(indexItem)
		}
	}

	public async putItems(items: IWorkDetails[]): Promise<void> {
		const { chunks, chunksCount } = splitIntoChunks(items, 200)
		const chunkInsertions = [...new Array(chunksCount)].map((_value, index) => {
			return this.putItemsSync(chunks[index])
		})

		// chain of promises, each promise processes one chunk synchronously
		await Promise.all(chunkInsertions)
	}

	public putItemsSync(items: IWorkDetails[]): void {
		for (const item of items) {
			this.putItemSync(item)
		}
	}

	public async removeItem(itemId: string): Promise<void> {
		this.documentMap.delete(itemId)
		return this.documentIndex.removeAsync(itemId)
	}

	public async removeItems(itemsIds: string[]): Promise<void> {
		await Promise.all(itemsIds.map((itemId) => this.removeItem(itemId)))
	}

	public importCollections(collections: ICollection[]): void {
		for (const collection of collections) {
			this.collectionsMap.set(collection.id, collection.item_ids || [])
			if (collection.kind === "archive") {
				this.archivedSet = new Set(collection.item_ids)
			}
		}
	}

	public async search(params: TSearchParams): Promise<string[]> {
		if (!hasEmptyQuery(params) || !hasEmptyCollections(params)) {
			const searchResults = await this.applySearch(params)
			const collectionsResults = this.applyCollections(searchResults, params)
			const filteredSearchResults = this.applyFilters(collectionsResults, params)
			return this.applySort(filteredSearchResults, params)
		}

		const filteredResults = this.applyFilters([...this.documentMap.keys()], params)
		return this.applySort(filteredResults, params)
	}

	public async clear(): Promise<void> {
		this.documentIndex = InventorySearchService.createDocumentIndex()
		this.documentMap.clear()
		this.collectionsMap.clear()
		this.archivedSet.clear()
	}

	private async applySearch(params: TSearchParams): Promise<string[]> {
		if (hasEmptyQuery(params)) {
			return [...this.documentMap.keys()]
		}

		const { query } = params
		const results = await this.genericSearch({
			limit: Infinity,
			query,
		})

		// `results` is an array splitted by fields, so we merge them
		return getUnion(results.map(({ result }) => result))
	}

	private applyCollections(itemsIds: string[], params: TSearchParams): string[] {
		if (hasEmptyCollections(params) || typeof params.collectionsIds === "undefined") {
			return itemsIds
		}

		const collectionSets: string[][] = params.collectionsIds.map(
			(collectionId) => this.collectionsMap.get(collectionId) || []
		)
		return getIntersection([itemsIds, ...collectionSets])
	}

	private applyFilters(itemsIds: string[], params: TSearchParams): string[] {
		if (hasEmptyFilters(params)) {
			return itemsIds
		}

		return itemsIds.filter((itemId) => {
			const item = this.documentMap.get(itemId)
			if (typeof item === "undefined") {
				throw "[InventorySearchService] No item found in documentMap while filtering"
			}

			return filters.archived(item, params.filters, this.archivedSet)
		})
	}

	private applySort(itemsIds: string[], params: TSearchParams): string[] {
		return itemsIds.sort((leftId, rightId) => {
			const leftItem = this.documentMap.get(leftId) as ISanitizedWorkDetailsWithDict
			const rightItem = this.documentMap.get(rightId) as ISanitizedWorkDetailsWithDict

			if (typeof leftItem === "undefined" || typeof rightItem === "undefined") {
				throw "[InventorySearchService] No item found in documentMap while sorting"
			}

			return compareByMultipleFields(leftItem, rightItem, params.sorting)
		})
	}

	private genericSearch(options: TSearchOptions): Promise<TDocumentSearchResults> {
		return new Promise<TDocumentSearchResults>((resolve) => {
			this.documentIndex.searchAsync(options, (results) => {
				resolve(results)
			})
		})
	}
}
