import { hotjar } from "react-hotjar"
import { environment } from "@services/environment"

const hotjarId = environment.HOTJAR_ID
const hotjarSv = environment.HOTJAR_SV

export function initialise(): void {
	if (hotjarId && hotjarSv) {
		hotjar.initialize(hotjarId, hotjarSv)
	} else {
		console.warn("Hotjar analytics are not enabled in current environment")
	}
}

export function identify(cognitoSub: string, userName: string, userEmail: string): void {
	if (hotjarId && hotjarSv) {
		hotjar.identify(cognitoSub, {
			name: userName,
			email: userEmail,
		})
	}
}

export function onRouteChange(newRoute: string): void {
	if (hotjarId && hotjarSv) {
		hotjar.stateChange(newRoute)
	}
}
