import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

type TVerifyFormProps = {
	submit: (username: string, code: string) => void
	username: string
	deliveryMedium: string
	destination: string
}

interface IFormFields {
	code: string
}

const validationSchema = yup.object().shape({
	code: yup.string().required(),
})

export const VerifyForm: React.FC<TVerifyFormProps> = (props) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormFields>({
		resolver: yupResolver(validationSchema),
	})

	const onSubmit = (data: IFormFields) => {
		props.submit(props.username, data.code)
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="pb-4">
				{props.deliveryMedium} with verification code was sent to <strong>{props.destination}</strong>. Please enter the
				code below to confirm your account.
			</div>
			{/* <div className="form-group">
						<label>Phone number</label>
						<input type="text" className="form-control" name="phoneNumber" ref={register} disabled defaultValue={props.phoneNumber} />
						<small className="form-text text-danger">{errors.phoneNumber?.message}</small>
					</div> */}
			<div className="form-group">
				<label>Access code</label>
				<input type="text" className="form-control form-input-auth" {...register("code")} />
				<small className="form-text text-danger">{errors.code?.message}</small>
			</div>
			<button type="submit" className="btn btn-primary btn-block">
				Submit
			</button>
		</form>
	)
}
