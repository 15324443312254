import { Reducer } from "redux"
import { TInventoryAction, InventoryActionTypes, TInventoryActionsSets, TSearchState } from "./types"
import {
	TNormalizedInventorySchema,
	normalizeInventoryItems,
	putToNormalizedInventoryItems,
} from "@store/ducks/inventory/normalized"
import * as actionSets from "./actionSets"
import { defaultRequestState, commonReducerCreator, TStateWithRequestStateGroup } from "@store/shared/requestState"
import deepMerge from "@utilities/objects/deepMerge"

export interface IItemsDictionary {
	[itemId: string]: true
}

export interface IInventoryStoreState extends TStateWithRequestStateGroup<TInventoryActionsSets> {
	readonly items: TNormalizedInventorySchema
	readonly lastItemsFetchTimestamp: string | undefined
	readonly selectedItems: IItemsDictionary
	readonly notFoundItems: IItemsDictionary
	readonly filter: {
		readonly params: TSearchState
		readonly result: string[]
	}
	readonly itemIdInPreview: string | undefined
	readonly search: string
}

export const initInventoryState: IInventoryStoreState = {
	items: normalizeInventoryItems([]),
	lastItemsFetchTimestamp: undefined,
	selectedItems: {},
	notFoundItems: {},
	filter: {
		params: {
			filters: {
				archived: {
					includeUnarchived: true,
					includeArchived: false,
				},
			},
			sorting: {
				property: "createdAt",
				order: "DESC",
			},
		},
		result: [],
	},
	itemIdInPreview: undefined,
	search: "",
	requests: defaultRequestState(actionSets),
}

const commonReducer = commonReducerCreator<IInventoryStoreState, TInventoryAction>(actionSets)

function updatedSelectedItems(
	currentState: IItemsDictionary,
	itemsId: string[],
	selectedStatus: boolean
): IItemsDictionary {
	const result = { ...currentState }
	if (selectedStatus) {
		for (const itemId of itemsId) {
			result[itemId] = true
		}
	} else {
		for (const itemId of itemsId) {
			delete result[itemId]
		}
	}

	return result
}

function updatedNotFoundItems(currentState: IItemsDictionary, notFoundItemId: string): IItemsDictionary {
	const result = { ...currentState }
	result[notFoundItemId] = true

	return result
}

export const InventoryReducer: Reducer<IInventoryStoreState, TInventoryAction> = (
	state = initInventoryState,
	action: TInventoryAction
) => {
	switch (action.type) {
		case InventoryActionTypes.MergeDataIntoInventory:
			return {
				...commonReducer(state, action),
				items:
					state.items.result.length > 0
						? putToNormalizedInventoryItems(
								state.items,
								normalizeInventoryItems(action.payload.itemsToPut),
								action.payload.itemsIdsToDelete
							) // prettier-ignore
						: normalizeInventoryItems(action.payload.itemsToPut),
				lastItemsFetchTimestamp: action.payload.updatedTimestamp || state.lastItemsFetchTimestamp,
			}
		case InventoryActionTypes.FetchInventoryFailure:
			return {
				...commonReducer(state, action),
				items: normalizeInventoryItems([]),
			}
		case InventoryActionTypes.SetSearchQuerySuccess:
			return {
				...commonReducer(state, action),
				search: action.payload.query,
			}
		case InventoryActionTypes.SetItemSelected:
			return {
				...commonReducer(state, action),
				selectedItems: updatedSelectedItems(
					state.selectedItems,
					[action.payload.itemId],
					action.payload.selectedStatus
				),
				itemIdInPreview: undefined,
			}
		case InventoryActionTypes.SetFilterItemsSelected:
			return {
				...commonReducer(state, action),
				selectedItems: updatedSelectedItems(state.selectedItems, state.filter.result, action.payload.selectedStatus),
				itemIdInPreview: undefined,
			}
		case InventoryActionTypes.ClearItemsSelection:
			return {
				...commonReducer(state, action),
				selectedItems: {},
			}
		case InventoryActionTypes.ClearItemPreview:
			return {
				...commonReducer(state, action),
				itemIdInPreview: undefined,
			}
		case InventoryActionTypes.SetItemForPreview:
			return {
				...commonReducer(state, action),
				itemIdInPreview: action.payload.itemId,
			}
		case InventoryActionTypes.ApplyFilterRequest:
			return {
				...commonReducer(state, action),
				filter: {
					...state.filter,
					params: deepMerge(state.filter.params, action.payload || {}),
				},
			}
		case InventoryActionTypes.SetFilterResult:
			return {
				...commonReducer(state, action),
				filter: {
					...state.filter,
					result: action.payload.itemIds.slice(),
				},
			}
		case InventoryActionTypes.GetInventoryItemFailure:
			return {
				...commonReducer(state, action),
				notFoundItems: updatedNotFoundItems(state.notFoundItems, action.payload.itemId),
			}
		default:
			return commonReducer(state, action)
	}
}
