import merge, { Options } from "deepmerge"

// TOptional deeply makes all fields as optional
export type TOptional<Type extends Record<string, unknown>> = {
	[Property in keyof Type]?: Type[Property] extends Record<string, unknown> ? TOptional<Type[Property]> : Type[Property]
}

const overwriteMerge: Options["arrayMerge"] = (_destinationArray, sourceArray) => sourceArray

/**
 * Given `existing` object and partial `merging` object, deeply merges `merging` into `existing`
 * @param existing
 * @param merging
 */
export default function deepMerge<T extends Record<string, unknown>>(existing: T, merging: TOptional<T>): T {
	return merge<T, TOptional<T>>(existing, merging, {
		arrayMerge: overwriteMerge,
	})
}
