import React from "react"
import ButtonWithDialog from "@components/ButtonWithDialog"
import * as animationStyles from "@components/ButtonWithDialog/Animations.module.scss"
import { useTranslation } from "react-i18next"
import IconButton from "@components/IconButton"
import warnConfirmDialog from "@components/WarnConfirmDialog"

interface IRemoveArtworkDialogProps {
	buttonIcon: React.ReactNode
	buttonClassName: string
	disabled?: boolean
	artworkTitle: string
	isArchived: boolean
	onConfirm: () => void
}

export const RemoveArtworkDialog: React.FC<IRemoveArtworkDialogProps> = (props) => {
	const { artworkTitle, onConfirm, buttonIcon, buttonClassName, disabled, isArchived } = props
	const { t } = useTranslation()

	const dialog = warnConfirmDialog({
		title:
			artworkTitle.length > 0 ? t("inventory.deleteItemTitle", { artworkTitle }) : t("inventory.deleteItemTitleNoName"),
		description: isArchived
			? t("inventory.deleteItemDescription")
			: t("inventory.deleteItemDescriptionWithArchiveNote"),
		cancelText: t("common.cancel"),
		confirmText: t("common.delete"),
		onConfirm,
	})

	return (
		<ButtonWithDialog
			button={({ ref }) => (
				<IconButton
					ref={ref}
					className={buttonClassName}
					icon={buttonIcon}
					label={t("common.delete")}
					disabled={disabled}
				/>
			)}
			dialogNode={dialog}
			transitionClasses={animationStyles}
		/>
	)
}
