import * as Sentry from "@sentry/react"
import { environment, getEnvironmentString } from "@services/environment"
import { Integrations } from "@sentry/tracing"

function initialiseSentry(): void {
	Sentry.init({
		dsn: environment.SENTRY_DSN,
		integrations: [
			new Integrations.BrowserTracing({
				tracingOrigins: [environment.API_SERVER],
			}),
		],
		tracesSampleRate: 1.0,
		environment: getEnvironmentString(),
	})
}

initialiseSentry()
