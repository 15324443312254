import React, { useCallback, useState } from "react"
import Skeleton from "react-loading-skeleton"
import * as styles from "./Image.module.scss"
import classNames from "classnames"

/**
 * This image component tends to handle image loading
 * and probably other cases in the future
 *
 * In fact, it is a simple wrapper over <img /> tag which
 * use <Skeleton /> to preview image before actual load complete
 */

interface IImageProps {
	src: string
	alt: string
	width: number | string
	height: number | string
	className: string
}

export const Image: React.FC<IImageProps> = React.memo((props) => {
	const { src, alt, width, height, className } = props
	const [loaded, setLoaded] = useState(false)
	const [failed, setFailed] = useState(false)

	const onImageLoad = useCallback(() => setLoaded(true), [setLoaded])
	const onImageError = useCallback(() => setFailed(true), [setFailed])

	const displayStyle = (shouldDisplay: boolean) => ({
		display: shouldDisplay ? "block" : "none",
	})

	const combinedClassNames = className ? classNames(styles.imageWrapper, className) : styles.imageWrapper

	return (
		<div className={combinedClassNames}>
			<img
				style={displayStyle(loaded && !failed)}
				alt={alt}
				src={src}
				width={width}
				height={height}
				onLoad={onImageLoad}
				onError={onImageError}
			/>
			<Skeleton
				className={styles.skeletonLoader}
				style={displayStyle(!loaded || failed)}
				width={width}
				height={height}
			/>
		</div>
	)
})
