import React, { MutableRefObject, useRef, useState, memo } from "react"
import * as styles from "./SelectionQuickPreview.module.scss"
import { FixedSizeList, areEqual, ListChildComponentProps } from "react-window"
import useResizeObserver from "@react-hook/resize-observer"
import { connect } from "react-redux"
import { TProps, TRenderRowData } from "@containers/PreviewContainer/SelectionQuickPreview/types"
import ItemPreview from "@containers/PreviewContainer/ItemInSelectionPreview"
import areSetsEqual from "@utilities/arrays/areSetsEqual"
import { clearItemsSelection } from "@store/ducks/inventory"
import IconButton from "@components/IconButton"
import { ReactComponent as ArchiveIcon } from "@icons/ArchiveIcon.svg"
import { ReactComponent as TagIcon } from "@icons/TagIcon.svg"
import AddCollectionPopover from "@containers/PreviewContainer/AddCollectionPopover"
import { useTranslation } from "react-i18next"
import { createCollection, manageArchive, manageCollection } from "@store/ducks/collections"
import { isItemArchived } from "@store/ducks/collections/selectors"

// We are using memoization so rows won't rerender on each change of selected list
const RenderRow = memo<ListChildComponentProps<TRenderRowData>>(({ data, index, style }) => {
	const row = data[index]

	return <ItemPreview itemId={row.id} style={style} />
}, areEqual)

export const SelectionQuickPreview: React.FC<TProps["Combined"]> = React.memo(
	(props) => {
		const { selectedItems, areAllItemsArchived } = props
		const { clearItemsSelection, addItemsToCollection, addItemsToArchive, removeItemsFromArchive, createCollection } =
			props
		const { t } = useTranslation()
		const [elementHeight, setElementHeight] = useState(0)
		const containerRef = useRef() as MutableRefObject<HTMLDivElement>
		useResizeObserver(containerRef, (rect) => {
			setElementHeight(rect.target.clientHeight)
		})

		return (
			<>
				<div className={styles.header}>
					{t("bulkActions.itemsSelected", { count: props.itemsIds.length })}
					<button onClick={() => clearItemsSelection()} className={styles.deselectButton}>
						{t("bulkActions.deselect")}
					</button>
					<div className={styles.bulkOperationsWrapper}>
						<IconButton
							icon={<ArchiveIcon />}
							label={areAllItemsArchived ? t("bulkActions.unarchive") : t("bulkActions.archive")}
							className={styles.bulkOperationButton}
							onClick={() => {
								if (areAllItemsArchived) {
									removeItemsFromArchive()
								} else {
									addItemsToArchive()
								}
							}}
						/>
						<AddCollectionPopover
							buttonIcon={<TagIcon />}
							buttonClassName={styles.bulkOperationButton}
							onAddToCollection={(collectionId) => {
								addItemsToCollection(collectionId)
							}}
							onCreateCollection={(collectionName) => createCollection(collectionName)}
							label={t("bulkActions.collection")}
						/>
					</div>
				</div>
				<div ref={containerRef} className={styles.container}>
					<FixedSizeList
						height={elementHeight}
						itemCount={selectedItems.length}
						itemData={selectedItems}
						itemSize={104}
						width={"100%"}
						itemKey={(index, data) => {
							return data[index].id
						}}
					>
						{RenderRow}
					</FixedSizeList>
				</div>
			</>
		)
	},
	(prev, next) =>
		areSetsEqual(new Set(prev.itemsIds), new Set(next.itemsIds)) &&
		prev.areAllItemsArchived === next.areAllItemsArchived
)

const mapStateToProps: TProps["State"] = (state, ownProps) => {
	const areAllItemsArchived = ownProps.itemsIds.every((itemId) => isItemArchived(state)({ itemId: itemId }))

	return {
		selectedItems: ownProps.itemsIds.map((itemId) => ({ id: itemId })),
		areAllItemsArchived,
	}
}

const mapDispatchToProps: TProps["Dispatch"] = (dispatch, ownProps) => {
	return {
		clearItemsSelection: () => dispatch(clearItemsSelection()),
		addItemsToCollection: (collectionId) => dispatch(manageCollection(collectionId, ownProps.itemsIds, [])),
		addItemsToArchive: () => dispatch(manageArchive(ownProps.itemsIds, [])),
		removeItemsFromArchive: () => dispatch(manageArchive([], ownProps.itemsIds)),
		createCollection: (collectionName) => dispatch(createCollection(collectionName, ownProps.itemsIds)),
	}
}

export const SelectionQuickPreviewConnected = connect(mapStateToProps, mapDispatchToProps)(SelectionQuickPreview)
