// Remote Action requests an operation to be perform by the client
// for example sending back cognito identity id, logging out, etc
export interface IRemoteAction {
	name: string
	config: string
}

export enum RemoteActionTypes {
	CognitoIdentityRequest = "cognito-identity-request",
}
