import React from "react"
import ArtworkImages, { IImageItem } from "@components/ArtworkImages"
import { nextFileName, patchFileName } from "@utilities"
import API from "@services/api"
import * as styles from "./tabs/Tabs.module.scss"

interface IArtworkImagesControlledProps {
	images: IImageItem[]
	onImagesUpdate: (newImages: IImageItem[]) => void
	markImageAsUploaded: (imageName: string) => void
	artworkId: string
	shouldHighlight: boolean
	readonly: boolean
}

// iterates through available files in order to derive fileName with appropriate counter
function deriveFileName(images: IImageItem[], fileName: string): string {
	if (images.every((file) => file.name !== fileName)) {
		return fileName
	}

	return deriveFileName(images, nextFileName(fileName))
}

function deriveFilesToAppend(newFiles: File[], existingImages: IImageItem[]): IImageItem[] {
	return newFiles.map((file) => ({
		src: URL.createObjectURL(file),
		name: deriveFileName(existingImages, patchFileName(file.name)),
		isUploaded: false,
		isNew: true,
	}))
}

/**
 * Simply a wrapper over `ArtworkImages`
 * It is designed to be used as simple controlled component
 */
const ArtworkImagesControlled: React.FC<IArtworkImagesControlledProps> = React.memo(
	(props) => {
		const { artworkId, images, onImagesUpdate, markImageAsUploaded, shouldHighlight, readonly } = props

		const onNewImageFn = async (newFiles: File[], uuid: string) => {
			// first, simply add all files to state
			// (optimistic UI approach - user should see added files immediately)
			const filesToAppend = deriveFilesToAppend(newFiles, images)
			const result = [...images, ...filesToAppend]
			onImagesUpdate(result)

			// next, create an array of promises for uploading images
			// note we are using fileNames derived previously
			const filesPromises = newFiles.map(async (file, index) => {
				const derivedFileName = filesToAppend[index].name
				await API.amplify.uploadFile({
					file: file,
					level: "protected",
					uploadPath: `${uuid}/${derivedFileName}`,
					contentType: "image/png",
					updateProgress: () => undefined,
				})

				markImageAsUploaded(derivedFileName)
			})

			await Promise.all(filesPromises)
		}

		return (
			<ArtworkImages
				images={images}
				readonly={readonly}
				onUpdateFn={(items: IImageItem[]) => onImagesUpdate(items)}
				onNewImageFn={onNewImageFn}
				shouldHighlight={shouldHighlight}
				artworkId={artworkId}
				className={styles.artworkImages}
			/>
		)
	},
	(prev, next) => prev.images === next.images && prev.shouldHighlight === next.shouldHighlight
)

export default ArtworkImagesControlled
