import React from "react"
import { connect } from "react-redux"
import { withRouter, RouteComponentProps, Link } from "react-router-dom"
import { loginRequest } from "@store/ducks/auth/actions"
import { fetchUserOrgsRequest } from "@store/ducks/orgs/actions"
import { StaticRoutes } from "@routes"
import PageLayout from "@layouts/PageLayout"
import ErrorMessage from "@components/ErrorMessage"
import Loader from "@components/Loader"
import LoginForm from "@components/LoginForm"
import { TRootState } from "@store/rootReducer"
import * as authSelectors from "@store/ducks/auth/selectors"
import * as authActionSets from "@store/ducks/auth/actionSets"

interface IReduxProps {
	loginRequest: (username: string, password: string) => void
	fetchUserOrgsRequest: () => void
}

interface ILoginProps {
	isLoading: boolean
	errorMsg?: string
}

type TComponentProps = ILoginProps & IReduxProps & RouteComponentProps

const Login: React.FC<TComponentProps> = (props: TComponentProps) => {
	const { isLoading, errorMsg } = props

	const performLogin = (username: string, password: string) => {
		props.loginRequest(username, password)
	}

	return (
		<PageLayout className="h-100 w-100 d-inline-block">
			<div className="container d-flex h-75">
				<div className="row justify-content-center align-self-center mx-auto">
					<div className="authForm fade-in-bottom ">
						<h1 className="display-4 mb-3">Login</h1>
						<LoginForm submit={performLogin} />
						<div className="pt-5 text-center">
							<Link to={StaticRoutes.PasswordResetRequest} className="color-views-link">
								Forgot password?
							</Link>
						</div>
						{errorMsg && <ErrorMessage message={errorMsg} />}
						{isLoading && <Loader />}
					</div>
				</div>
			</div>
		</PageLayout>
	)
}

function mapStateToProps(state: TRootState): ILoginProps {
	return {
		isLoading: authSelectors.getLoadingStatus(authActionSets.login.request)(state),
		errorMsg: authSelectors.getErrorMsg(authActionSets.login.request)(state),
	}
}

export default connect(mapStateToProps, { loginRequest, fetchUserOrgsRequest })(withRouter(Login))
