import React, { useState } from "react"
import API from "@services/api"
import { Link } from "react-router-dom"
import { StaticRoutes } from "@routes"
import PageLayout from "@layouts/PageLayout"
import ErrorMessage from "@components/ErrorMessage"
import Loader from "@components/Loader"

enum ResetState {
	RequestCode,
	ChangePassword,
	Success,
}

const PasswordResetRequest: React.FC = () => {
	const [resetState, setResetState] = useState<ResetState>(ResetState.RequestCode)
	const [phoneNumber, setPhoneNumber] = useState("")
	const [mobileCode, setMobileCode] = useState("")
	const [newPassword, setNewPassword] = useState("")
	const [errorMsg, setErrorMsg] = useState("")
	const [isLoading, setLoading] = useState(false)

	const requestReset = async () => {
		setLoading(true)
		setErrorMsg("")
		const { status, error } = await API.amplify.forgotPassword({ username: phoneNumber })
		setLoading(false)
		if (status) {
			setResetState(ResetState.ChangePassword)
		} else {
			console.log(error)
			setErrorMsg(error?.message || "Unknown error")
		}
	}

	const changePassword = async () => {
		setLoading(true)
		setErrorMsg("")
		console.log(phoneNumber, mobileCode, newPassword)
		const { status, error } = await API.amplify.forgotPasswordSubmit({
			username: phoneNumber,
			code: mobileCode,
			newPassword,
		})
		setLoading(false)
		if (status) {
			setResetState(ResetState.Success)
		} else {
			// for now interpret `UserLambdaValidationException` as success
			// it arrises because CognitoConfirmation lambda is triggered on password change as well as account creation
			if (error?.code === "UserLambdaValidationException") {
				setResetState(ResetState.Success)
			} else {
				console.log(error)
				setErrorMsg(error?.message ?? "Unknown error")
			}
		}
	}

	return (
		<PageLayout>
			<div className="container d-flex justify-content-center">
				<div className="authForm p-4 fade-in-bottom ">
					<h1 className="display-4 mb-3">Reset</h1>
					{resetState === ResetState.RequestCode && (
						<div>
							<div className="pb-4">Enter your phone number you used to create an account.</div>
							<div className="form-group">
								<input
									type="text"
									className="form-control form-input-auth"
									name="code"
									value={phoneNumber}
									onChange={(e) => setPhoneNumber(e.target.value)}
									placeholder="+449876543212"
								/>
							</div>
							<button type="submit" className="btn btn-primary btn-block" onClick={() => requestReset()}>
								Request reset
							</button>
						</div>
					)}
					{resetState === ResetState.ChangePassword && (
						<div>
							<div className="pb-4">Enter the code you should received to your phone number.</div>
							<div className="form-group">
								<label>Code</label>
								<input
									type="text"
									className="form-control form-input-auth"
									name="code"
									value={mobileCode}
									onChange={(e) => setMobileCode(e.target.value)}
									placeholder="12345"
								/>
							</div>
							<div className="form-group">
								<label>New password</label>
								<input
									type="password"
									className="form-control form-input-auth"
									name="code"
									value={newPassword}
									onChange={(e) => setNewPassword(e.target.value)}
									placeholder=""
								/>
							</div>
							<button type="submit" className="btn btn-primary btn-block" onClick={() => changePassword()}>
								Request reset
							</button>
						</div>
					)}
					{resetState === ResetState.Success && (
						<div className="pb-4">
							Password was successfully reset. Click <Link to={StaticRoutes.Login}>here</Link> to login.
						</div>
					)}

					{errorMsg && <ErrorMessage message={errorMsg} />}
					{isLoading && <Loader />}
				</div>
			</div>
		</PageLayout>
	)
}

export default PasswordResetRequest
