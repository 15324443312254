import React from "react"
import * as styles from "./InventoryLayout.module.scss"
import InventoryContainer from "@containers/InventoryContainer"
import PreviewContainer from "@containers/PreviewContainer"

export const InventoryLayout: React.FC = () => {
	return (
		<>
			<div className={styles.inventoryGrid}>
				<InventoryContainer className={styles.inventoryContainer} />
				<PreviewContainer className={styles.previewContainer} />
			</div>
		</>
	)
}
