import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { ISignUpDetails } from "@typed/entities/SignUpDetails"
import PhoneInput from "react-phone-input-2"

type TRegisterFormProps = {
	submit: (details: ISignUpDetails) => void
}

interface IFormFields {
	givenName: string
	familyName: string
	email: string
	password: string
	terms: boolean
}

const validationSchema = yup.object().shape({
	givenName: yup.string().required(),
	familyName: yup.string().required(),
	email: yup.string().required(),
	password: yup.string().required(),
})

export const RegisterForm: React.FC<TRegisterFormProps> = ({ submit }) => {
	const [username, setUsername] = useState<string>("")

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormFields>({
		resolver: yupResolver(validationSchema),
	})

	const onSubmit = (data: IFormFields) => {
		submit({
			phoneNumber: username,
			givenName: data.givenName,
			familyName: data.familyName,
			password: data.password,
			email: data.email,
		})
	}

	const termsAndConditionsSrc = "https://www.views.art/terms-and-conditions"
	const privacyPolicySrc = "https://www.views.art/privacy"

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="form-group">
				<label>Mobile number</label>
				<PhoneInput
					country={"gb"}
					value={username}
					onChange={(phone) => setUsername("+" + phone)}
					inputClass="form-input-auth"
					preferredCountries={["gb", "us"]}
					disableDropdown={false}
					// name=phone for better autocomplete support
					inputProps={{ name: "phone" }}
				/>
			</div>
			<div className="form-group">
				<label>Email</label>
				<input type="email" className="form-control form-input-auth" {...register("email")} />
				<small className="form-text text-danger">{errors.email?.message}</small>
			</div>
			<div className="form-group">
				<label>First name</label>
				<input type="text" className="form-control form-input-auth" {...register("givenName")} />
				<small className="form-text text-danger">{errors.givenName?.message}</small>
			</div>
			<div className="form-group">
				<label>Last name</label>
				<input type="text" className="form-control form-input-auth" {...register("familyName")} />
				<small className="form-text text-danger">{errors.familyName?.message}</small>
			</div>
			<div className="form-group">
				<label>Password</label>
				<input type="password" className="form-control form-input-auth" {...register("password")} />
				<small className="form-text text-danger">{errors.password?.message}</small>
			</div>
			<div className="mb-3">
				By joining Views, you agree to our{" "}
				<a href={termsAndConditionsSrc} target="_blank">
					Terms of Service
				</a>{" "}
				and{" "}
				<a href={privacyPolicySrc} target="_blank">
					Privacy Policy
				</a>
				.
			</div>
			<button type="submit" className="btn btn-primary btn-block">
				Create account
			</button>
		</form>
	)
}
