import React, { useCallback } from "react"
import * as styles from "./ItemQuickPreview.module.scss"
import { TProps } from "./types"
import { getInventoryItem } from "@store/ducks/inventory/selectors"
import { connect } from "react-redux"
import ThumbnailImage from "@components/ThumbnailImage"
import { dimensionsStringCm, dimensionsStringIns } from "@utilities"
import IconButton from "@components/IconButton"
import { clearItemPreview, deleteItemRequest } from "@store/ducks/inventory"
import { ReactComponent as CloseIcon } from "@icons/CloseIcon.svg"
import { ReactComponent as ArchiveIcon } from "@icons/ArchiveIcon.svg"
import { ReactComponent as TagIcon } from "@icons/TagIcon.svg"
import { ReactComponent as RemoveIcon } from "@icons/RemoveIcon.svg"
import { ReactComponent as EditIcon } from "@icons/EditIcon.svg"
import { StaticRoutes } from "@routes"
import { Link } from "react-router-dom"
import AddCollectionPopover from "@containers/PreviewContainer/AddCollectionPopover"
import RemoveArtworkDialog from "@containers/PreviewContainer/RemoveArtworkDialog"
import { ItemPermissions } from "@typed/entities/ItemPermissions"
import { joinStrings } from "@utilities/strings/formatting"
import { useTranslation } from "react-i18next"
import { getItemCollections, isItemArchived } from "@store/ducks/collections/selectors"
import { createCollection, manageArchive, manageCollection } from "@store/ducks/collections"
import { formatPrice } from "@utilities/currency/unifiedPrice"
import getCurrency from "@utilities/currency/getCurrency"

const ItemQuickPreview: React.FC<TProps["Combined"]> = (props) => {
	const {
		title,
		author,
		year,
		material,
		thumbnailSrc,
		dims,
		edition,
		stockCode,
		itemId,
		permissions,
		isArchived,
		collections,
		retailPrice,
	} = props
	const {
		clearItemPreview,
		addItemToCollection,
		deleteItem,
		removeItemFromCollection,
		addItemToArchive,
		removeItemFromArchive,
		createCollection,
	} = props
	const { t, ready } = useTranslation(undefined, { useSuspense: false })
	const hasYear = year && year.length > 0
	const canDelete = permissions.includes(ItemPermissions.canDelete)
	const dimensionsIns = dimensionsStringIns(dims)

	const handleClearItemPreview = useCallback(() => clearItemPreview(), [clearItemPreview])
	const handleAddItemToCollection = useCallback(
		(collectionId) => addItemToCollection(collectionId),
		[addItemToCollection]
	)
	const handleRemoveFromCollection = useCallback(
		(collectionId) => removeItemFromCollection(collectionId),
		[removeItemFromCollection]
	)
	const handleCreateCollection = useCallback((collectionName) => createCollection(collectionName), [createCollection])
	const handleArchive = useCallback(() => {
		if (isArchived) {
			removeItemFromArchive()
		} else {
			addItemToArchive()
		}
	}, [removeItemFromArchive, addItemToArchive, isArchived])
	const handleDeleteItem = useCallback(() => deleteItem(), [deleteItem])

	return (
		<div className={styles.container}>
			{ready && (
				<>
					<div className={styles.topActions}>
						<IconButton
							onClick={handleClearItemPreview}
							icon={<CloseIcon />}
							label={t("common.close")}
							className={styles.closeIcon}
						/>
						<div className={styles.quickActionsWrapper}>
							<Link to={`${StaticRoutes.EditWork}/${itemId}`}>
								<IconButton
									icon={<EditIcon />}
									label={t("inventory.editArtworkDetails")}
									className={styles.quickActionButton}
								/>
							</Link>
							<IconButton
								icon={<ArchiveIcon />}
								label={isArchived ? t("itemActions.unarchive") : t("itemActions.archive")}
								className={styles.quickActionButton}
								onClick={handleArchive}
							/>
							<AddCollectionPopover
								buttonIcon={<TagIcon />}
								buttonClassName={styles.quickActionButton}
								onAddToCollection={handleAddItemToCollection}
								onRemoveFromCollection={handleRemoveFromCollection}
								onCreateCollection={handleCreateCollection}
								appliedCollectionsIds={collections.map(({ id }) => id)}
								label={t("itemActions.collection")}
							/>
							<RemoveArtworkDialog
								artworkTitle={title}
								buttonIcon={<RemoveIcon />}
								buttonClassName={styles.quickActionButton}
								isArchived={isArchived}
								disabled={!canDelete}
								onConfirm={handleDeleteItem}
							/>
						</div>
					</div>
					<div className={styles.artworkInfo}>
						<div className={styles.thumbnail} key={thumbnailSrc}>
							<ThumbnailImage
								src={thumbnailSrc}
								width={"100%"}
								height={"100%"}
								emptyImageWidth={92}
								alt={joinStrings([author, title], ", ")}
								className={styles.artworkImage}
							/>
						</div>
						<div className={styles.description}>
							<div className={styles.heading}>
								<span className={styles.author}>{author}</span>
								<span className={styles.stockCode}>{stockCode}</span>
							</div>
							<section>
								<i>{title}</i>
								{hasYear && `, ${year}`}
							</section>
							<section>{material}</section>
							{dimensionsIns && (
								<section>
									{dimensionsStringIns(dims)} ({dimensionsStringCm(dims)})
								</section>
							)}
							{edition && <section>{edition}</section>}
							{retailPrice && (
								<section className={styles.retailPrice}>
									<div className={styles.title}>{t("artwork.askingPrice")}</div>
									<div className={styles.content}>{formatPrice(retailPrice)}</div>
								</section>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	)
}

const mapStateToProps: TProps["State"] = (state, ownProps) => {
	const row = getInventoryItem(ownProps.itemId)(state)
	const retailPrice =
		row?.retail_price && row?.retail_price_currency
			? {
					value: +row.retail_price,
					currency: getCurrency(row.retail_price_currency),
				} // prettier-ignore
			: undefined

	return {
		thumbnailSrc: row?.images ? row?.images[0]?.detail : undefined,
		inventoryId: row?.org_uid,
		author: row?.artist,
		title: row?.title,
		year: row?.year,
		material: row?.medium,
		stockCode: row?.stock_code,
		edition: row?.edition,
		permissions: row?.permissions || [],
		collections: getItemCollections(state)({ itemId: ownProps.itemId }),
		dims: {
			width: row?.dim_width,
			height: row?.dim_height,
			depth: row?.dim_depth,
		},
		isArchived: isItemArchived(state)({ itemId: ownProps.itemId }),
		retailPrice,
	}
}

const mapDispatchToProps: TProps["Dispatch"] = (dispatch, ownProps) => {
	return {
		clearItemPreview: () => dispatch(clearItemPreview()),
		deleteItem: () => dispatch(deleteItemRequest(ownProps.itemId)),
		addItemToCollection: (collectionId) => dispatch(manageCollection(collectionId, [ownProps.itemId], [])),
		removeItemFromCollection: (collectionId) => dispatch(manageCollection(collectionId, [], [ownProps.itemId])),
		addItemToArchive: () => dispatch(manageArchive([ownProps.itemId], [])),
		removeItemFromArchive: () => dispatch(manageArchive([], [ownProps.itemId])),
		createCollection: (collectionName) => dispatch(createCollection(collectionName, [ownProps.itemId])),
	}
}

export const ItemQuickPreviewConnected = connect(mapStateToProps, mapDispatchToProps)(ItemQuickPreview)
