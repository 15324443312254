/**
 * Compares two arrays for equality
 * Function is order sensitive, it also doesn't perform deep object comparison
 * @param leftArray
 * @param rightArray
 */
export default function getArraysEqual<T>(leftArray: T[], rightArray: T[]): boolean {
	if (leftArray === rightArray) {
		return true
	}

	if (leftArray == null || rightArray == null) {
		return false
	}

	if (leftArray.length !== rightArray.length) {
		return false
	}

	for (let i = 0; i < leftArray.length; ++i) {
		if (leftArray[i] !== rightArray[i]) {
			return false
		}
	}

	return true
}
