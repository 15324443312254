import React, { useEffect } from "react"
import { withRouter, RouteComponentProps, useLocation } from "react-router-dom"
import { connect } from "react-redux"
import { verifyRequest } from "@store/ducks/auth/actions"
import PageLayout from "@layouts/PageLayout"
import VerifyForm from "@components/VerifyForm"
import Loader from "@components/Loader"
import ErrorMessage from "@components/ErrorMessage"
import { TRootState } from "@store/rootReducer"
import * as authSelectors from "@store/ducks/auth/selectors"
import * as authActionSets from "@store/ducks/auth/actionSets"

interface IReduxProps {
	verifyRequest: (username: string, code: string) => void
}

interface ILocationProps {
	deliveryMedium: string
	destination: string
	username: string
}

interface IVerifyAccountProps {
	errorMsg: string | undefined
	isLoading: boolean
}

type TComponentProps = IVerifyAccountProps & IReduxProps & RouteComponentProps

const VerifyAccount: React.FC<TComponentProps> = (props) => {
	// let { cognitoSub } = useParams<URLProps>();
	const { errorMsg, isLoading } = props

	const location = useLocation<ILocationProps>()

	const performVerify = async (username: string, code: string) => {
		props.verifyRequest(username, code)
	}

	useEffect(() => {
		console.log(props)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<PageLayout>
			<div className="container d-flex justify-content-center">
				<div className="authForm p-4 fade-in-bottom ">
					<h1 className="display-4 mb-3">Verify</h1>
					<VerifyForm
						submit={performVerify}
						username={location.state.username}
						deliveryMedium={location.state.deliveryMedium}
						destination={location.state.destination}
					/>
					{errorMsg && <ErrorMessage message={errorMsg} />}
					{isLoading && <Loader />}
				</div>
			</div>
		</PageLayout>
	)
}

function mapStateToProps(state: TRootState): IVerifyAccountProps {
	return {
		isLoading: authSelectors.getLoadingStatus(authActionSets.verify.request)(state),
		errorMsg: authSelectors.getErrorMsg(authActionSets.verify.request)(state),
	}
}

export default connect(mapStateToProps, { verifyRequest })(withRouter(VerifyAccount))
