export enum Currency {
	USD = "$ USD",
	EUR = "€ EUR",
	JPY = "¥ JPY",
	GBP = "£ GBP",
	AUD = "A$ AUD",
	CAD = "C$ CAD",
	CHF = "CHF CHF",
	CNY = "元 CNY",
	HKD = "HK$ HKD",
	NZD = "NZ$ NZD",
	SEK = "kr SEK",
	KRW = "₩ KRW",
	SGD = "S$ SGD",
	NOK = "kr NOK",
	MXN = "$ MXN",
	INR = "₹ INR",
	RUB = "₽ RUB",
	ZAR = "R ZAR",
	TRY = "₺ TRY",
	BRL = "R$ BRL",
	TWD = "NT$ TWD",
	DKK = "kr DKK",
	PLN = "zł PLN",
	THB = "฿ THB",
	IDR = "Rp IDR",
	HUF = "Ft HUF",
	CZK = "Kč CZK",
	ILS = "₪ ILS",
	CLP = "CLP$ CLP",
	PHP = "₱ PHP",
	COP = "COL$ COP",
	MYR = "RM MYR",
	RON = "L RON",
}

export type TCurrencyStrings = keyof typeof Currency

export const currencies: TCurrencyStrings[] = [
	"USD",
	"EUR",
	"JPY",
	"GBP",
	"AUD",
	"CAD",
	"CHF",
	"CNY",
	"HKD",
	"NZD",
	"SEK",
	"KRW",
	"SGD",
	"NOK",
	"MXN",
	"INR",
	"RUB",
	"ZAR",
	"TRY",
	"BRL",
	"TWD",
	"DKK",
	"PLN",
	"THB",
	"IDR",
	"HUF",
	"CZK",
	"ILS",
	"CLP",
	"PHP",
	"COP",
	"MYR",
	"RON",
] as TCurrencyStrings[]
