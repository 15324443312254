import React from "react"
import ButtonWithPopover, { TPopoverNode } from "@components/ButtonWithPopover"
import * as animationStyles from "@components/ButtonWithPopover/Animations.module.scss"
import * as styles from "./FilterPopover.module.scss"
import * as checkboxStyles from "@styles/modules/Checkbox.module.scss"
import { TProps } from "./types"
import { getSearchState } from "@store/ducks/inventory/selectors"
import { applyFilterRequest } from "@store/ducks/inventory"
import { connect } from "react-redux"
import CollectionsList from "@components/CollectionsList"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { getAllCollections } from "@store/ducks/collections/selectors"

export const FilterPopover: React.FC<TProps["Combined"]> = (props) => {
	const { collections, searchState, className } = props
	const { archived } = searchState.filters
	const { applySearchQueryRequest } = props
	const { t } = useTranslation()
	const filterCollectionsIdsAsSet = new Set(searchState.collectionsIds)

	const innerElement: TPopoverNode = () => (
		<div className={styles.filterPopover}>
			<section>
				<span className={styles.header}>{t("inventory.collections")}</span>
				<CollectionsList
					collections={collections
						.filter((collection) => collection.kind != "archive")
						.sort((left, right) => left.pos - right.pos)}
					className={styles.collectionsWrapper}
					collectionClassName={(collectionKey) =>
						classNames(styles.collection, {
							[styles.collectionInFilter]: filterCollectionsIdsAsSet.has(collectionKey),
						})
					}
					onCollectionClick={(collectionKey) => {
						const selectedCollectionsIds = [...filterCollectionsIdsAsSet]
						const collectionIndex = selectedCollectionsIds.indexOf(collectionKey)
						if (collectionIndex >= 0) {
							selectedCollectionsIds.splice(collectionIndex, 1)
						} else {
							selectedCollectionsIds.push(collectionKey)
						}

						applySearchQueryRequest({ collectionsIds: selectedCollectionsIds })
					}}
				/>
			</section>
			<section>
				<div className={styles.checkboxWrapper}>
					<input
						type="checkbox"
						id="non-archived-checkbox"
						className={checkboxStyles.checkbox}
						checked={archived.includeUnarchived}
						onChange={(e) => {
							applySearchQueryRequest({
								filters: {
									archived: {
										includeUnarchived: e.target.checked,
									},
								},
							})
						}}
					/>
					<label className={styles.label} htmlFor="non-archived-checkbox">
						{t("inventory.unarchived")}
					</label>
				</div>
				<div className={styles.checkboxWrapper}>
					<input
						type="checkbox"
						id="archived-checkbox"
						className={checkboxStyles.checkbox}
						checked={archived.includeArchived}
						onChange={(e) => {
							applySearchQueryRequest({
								filters: {
									archived: {
										includeArchived: e.target.checked,
									},
								},
							})
						}}
					/>
					<label className={styles.label} htmlFor="archived-checkbox">
						{t("inventory.archived")}
					</label>
				</div>
			</section>
		</div>
	)

	return (
		<ButtonWithPopover
			button={(props) => (
				<button className={className} {...props}>
					{t("inventory.filter")}
				</button>
			)}
			popoverNode={innerElement}
			popoverClassName=""
			transitionClasses={animationStyles}
			placement="bottom-start"
		/>
	)
}

const mapStateToProps: TProps["State"] = (state) => {
	return {
		searchState: getSearchState(state),
		collections: getAllCollections(state),
	}
}

const mapDispatchToProps: TProps["Dispatch"] = (dispatch) => {
	return {
		applySearchQueryRequest: (params) => {
			dispatch(applyFilterRequest(params))
		},
	}
}

export const FilterPopoverConnected = connect(mapStateToProps, mapDispatchToProps)(FilterPopover)
