import React, { useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, withRouter, RouteComponentProps } from "react-router-dom"
import { getLoggedInStatus } from "@store/ducks/auth"
import { logoutRequest } from "@store/ducks/auth"
import { StaticRoutes } from "@routes"
import { hasOrganisations } from "@store/ducks/orgs"
import EmbeddedContext from "@enhancers/EmbeddedContext"
import classNames from "classnames"

const Header: React.FC<RouteComponentProps> = () => {
	const dispatch = useDispatch()
	const isAuthenticated = useSelector(getLoggedInStatus)
	const hasOrgs = useSelector(hasOrganisations)
	const { isEmbedded } = useContext(EmbeddedContext)

	const logout = () => {
		// TODO: should be one side-effect
		dispatch(logoutRequest(false))
	}

	return (
		<nav className={classNames("navbar navbar-expand-sm navbar-light", { invisible: isEmbedded })}>
			<Link to={StaticRoutes.UserInventory} className="navbar-brand header--logo" href="home">
				<img src="/graphics/logo.png" alt="Views logo" className="logo" />
			</Link>
			<ul className="navbar-nav ml-auto">
				{!isAuthenticated && (
					<>
						<li className="nav-item">
							<Link to={StaticRoutes.Login} className="nav-link" href="login">
								Login
							</Link>
						</li>
					</>
				)}
				{isAuthenticated && (
					<>
						<li className="nav-item">
							<Link to={StaticRoutes.UserInventory} className="nav-link" href="inventory">
								Inventory
							</Link>
						</li>
						<li className="nav-item">
							<Link to={StaticRoutes.AccountPage} className="nav-link" href="inventory">
								Account
							</Link>
						</li>
						{hasOrgs && (
							<li className="nav-item">
								<Link to={StaticRoutes.Organisations} className="nav-link" href="inventory">
									Organisations
								</Link>
							</li>
						)}
						<li className="nav-item">
							<a className="nav-link" href="#logout" onClick={() => logout()}>
								Logout
							</a>
						</li>
					</>
				)}
			</ul>
		</nav>
	)
}

// TODO: component shouldn't be router-dependent
export const headerWithRouter = withRouter(Header)
