import React, { CSSProperties, MutableRefObject, useMemo, useRef, useState } from "react"
import { VariableSizeList } from "react-window"
import { connect } from "react-redux"
import splitIntoChunks from "@utilities/arrays/splitIntoChunks"
import * as styles from "./ItemsGridContainer.module.scss"
import ItemsGridCell from "@containers/ItemsGridContainer/ItemsGridCell"
import useResizeObserver from "@react-hook/resize-observer"
import { getItemsPerRowCount } from "@containers/ItemsGridContainer/ItemsGridCell/functions"
import { TProps } from "./types"
import { getFilteredItems, getLastFetchTimestamp } from "@store/ducks/inventory/selectors"
import areArraysEqual from "@utilities/arrays/areArraysEqual"
import classNames from "classnames"

const itemHeight = 350
const itemPadding = 20

const ItemsGridContainer: React.FC<TProps["Combined"]> = React.memo(
	(props) => {
		const { itemsIds, className } = props
		const combinedClassNames = className ? classNames(styles.grid, className) : styles.grid

		// the total number of cells in each row of grid depends on actual grid width
		const minCellWidth = 280
		const [elementWidth, setElementWidth] = useState(minCellWidth)
		const [elementHeight, setElementHeight] = useState(0)
		const itemsPerRowCount = useMemo(
			() => getItemsPerRowCount(elementWidth, minCellWidth),
			[elementWidth, minCellWidth]
		)
		const { chunks, chunksCount } = splitIntoChunks(itemsIds, itemsPerRowCount)

		// using ResizeObserver API to subscribe on div width change
		const containerRef = useRef() as MutableRefObject<HTMLDivElement>
		useResizeObserver(containerRef, (rect) => {
			setElementWidth(rect.target.clientWidth)
			setElementHeight(rect.target.clientHeight)
		})

		const isFirstIndex = (chunkIndex: number) => chunkIndex == 0
		const isLastIndex = (chunkIndex: number) => chunkIndex == chunksCount - 1
		const isBoundIndex = (chunkIndex: number) => isFirstIndex(chunkIndex) || isLastIndex(chunkIndex)

		const getItemHeight = (chunkIndex: number) => (isBoundIndex(chunkIndex) ? itemHeight + itemPadding : itemHeight)

		const RenderCell = React.useCallback(
			({ index, style }) => {
				const items = chunks[index]
				const fillers = [...new Array(itemsPerRowCount - items.length)]

				const additionalStyles: CSSProperties = isBoundIndex(index)
					? {
							display: "flex",
							flexDirection: "column",
							justifyContent: isFirstIndex(index) ? "flex-end" : "flex-start",
						} // prettier-ignore
					: {}

				/**
				 * We fill each row with real elements and `filler` elements
				 * The purpose is to have same amount of elements in each row
				 */
				return (
					<div style={{ ...style, ...additionalStyles }}>
						<div className={styles.gridRow}>
							{items.map((itemId) => (
								<ItemsGridCell itemId={itemId} style={{}} key={itemId} />
							))}
							{fillers.map((_value, idx) => (
								<div className={styles.gridFiller} key={`filler-${idx}`} />
							))}
						</div>
					</div>
				)
			},
			[itemsIds, itemsPerRowCount]
		)

		/**
		 * We are using FixedSizeList (not Grid) mainly because we want to control most of the styles
		 * via CSS and not JS (while FixedSizeGrid forces us to specify width in px, not in %)
		 */
		return (
			<div ref={containerRef} className={combinedClassNames}>
				<VariableSizeList height={elementHeight} itemCount={chunksCount} itemSize={getItemHeight} width={"100%"}>
					{RenderCell}
				</VariableSizeList>
			</div>
		)
	},
	(prev, next) => areArraysEqual(prev.itemsIds, next.itemsIds)
)

const mapStateToProps: TProps["State"] = (state) => {
	const lastFetch = getLastFetchTimestamp(state)
	return {
		itemsIds: lastFetch ? getFilteredItems(state) : [],
	}
}

export const ItemsGridContainerConnected = connect(mapStateToProps)(ItemsGridContainer)
