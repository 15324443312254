import React from "react"
import { Controller } from "react-hook-form"
import * as styles from "./FormInputs.module.scss"
import { IHookFormInput } from "@components/FormInputs/types"
import { FieldValues } from "react-hook-form/dist/types/fields"
import NumberFormat from "react-number-format"
import { FormInputWrapper } from "@components/FormInputs/FormInputWrapper"
import classNames from "classnames"

interface IInputFieldProps<TFieldValues extends FieldValues> extends IHookFormInput<TFieldValues> {
	prefix: string
	thousandSeparator: boolean
}

interface IInputFieldControllerProps<TFieldValues extends FieldValues> extends IInputFieldProps<TFieldValues> {
	className?: string
}

interface IInputFieldLabeledProps<TFieldValues extends FieldValues> extends IInputFieldProps<TFieldValues> {
	label: string
}

export function NumericInputFieldController<TFieldValues extends FieldValues>(
	props: IInputFieldControllerProps<TFieldValues>
): JSX.Element {
	const { control, name, prefix, thousandSeparator, className } = props

	return (
		<Controller
			render={({ field }) => (
				<NumberFormat
					className={classNames(styles.input, {
						[`${className}`]: !!className,
					})}
					thousandSeparator={thousandSeparator}
					prefix={prefix}
					allowLeadingZeros={false}
					onValueChange={(v) => field.onChange(v.value)}
					allowNegative={false}
					allowEmptyFormatting={true}
					value={field.value && +field.value}
				/>
			)}
			name={name}
			control={control}
		/>
	)
}

function NumericInputField<TFieldValues extends FieldValues>(
	props: IInputFieldLabeledProps<TFieldValues>
): JSX.Element {
	const { label, control, name, className, prefix, thousandSeparator } = props

	return (
		<FormInputWrapper className={className} label={label}>
			<NumericInputFieldController
				control={control}
				name={name}
				prefix={prefix}
				thousandSeparator={thousandSeparator}
			/>
		</FormInputWrapper>
	)
}

export default NumericInputField
