import React from "react"

export enum CheckboxState {
	Unchecked = "unchecked",
	Checked = "checked",
	Indeterminate = "indeterminate",
}

interface ICheckboxProps {
	className?: string
	title?: string | ((state: CheckboxState) => string)
	disabled?: boolean
	checked?: CheckboxState
	onChange?: (checked: boolean) => void
}

function deriveNextCheckboxState(prevState?: CheckboxState): CheckboxState.Checked | CheckboxState.Unchecked {
	switch (prevState) {
		case CheckboxState.Checked:
		case CheckboxState.Indeterminate:
			return CheckboxState.Unchecked
		case CheckboxState.Unchecked:
			return CheckboxState.Checked
		default:
			return CheckboxState.Unchecked
	}
}

function calculateTitle(
	title?: string | ((state: CheckboxState) => string),
	checked?: CheckboxState
): string | undefined {
	if (typeof title === "function" && typeof checked === "undefined") {
		throw "[Checkbox] `title` is passed as function, but no `checked` prop is provided!"
	} else if (typeof title === "function" && typeof checked !== "undefined") {
		return title(checked)
	} else if (typeof title !== "function") {
		return title
	}
}

/**
 * Checkbox with three possible states: `checked`, `unchecked` and `indeterminate`
 * Clicking on checkbox can set wither `checked` or `unchecked`,
 * although we can pass `indeterminate` state when needed
 * @param props
 * @constructor
 */
export const Checkbox: React.FC<ICheckboxProps> = (props) => {
	const { className, disabled, checked, title } = props
	const { onChange } = props

	return (
		<input
			type="checkbox"
			className={className}
			title={calculateTitle(title, checked)}
			disabled={disabled}
			checked={checked === CheckboxState.Checked}
			data-checked={checked}
			onChange={() => {
				if (typeof onChange !== "undefined") {
					onChange(deriveNextCheckboxState(checked) === CheckboxState.Checked)
				}
			}}
		/>
	)
}
