import { environment } from "@services/environment"

export function initialise(): void {
	const hubspotScript = document.createElement("script")
	hubspotScript.setAttribute("src", `//js.hs-scripts.com/${environment.HUBSPOT_HUB_ID}.js`)
	hubspotScript.type = "text/javascript"
	hubspotScript.id = "hs-script-loader"
	hubspotScript.async = true
	hubspotScript.defer = true

	document.body.appendChild(hubspotScript)
}
