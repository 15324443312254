import { call, put, select, takeLatest, debounce } from "redux-saga/effects"
import API from "@services/api"
import { CollectionsActionTypes } from "@store/ducks/collections/types"
import {
	applyNewCollection,
	createCollection,
	getCollectionsFailure,
	getCollectionsRequest,
	getCollectionsSuccess,
	manageArchive,
	manageCollection,
	setCollectionsData,
	updateCollectionsRequest,
} from "@store/ducks/collections/actions"
import { ICollection } from "@typed/entities/InventoryCollection"
import { getAllCollections, getArchiveCollectionId } from "@store/ducks/collections/selectors"
import * as inventorySearchService from "@services/search"
import * as autocompleteService from "@services/autocomplete"
import { applyFilterRequest, InventoryActionTypes } from "@store/ducks/inventory"
import { take } from "redux-saga-test-plan/matchers"

function* applyCollectionsChanges() {
	const collections: ICollection[] = yield select(getAllCollections)
	yield call(inventorySearchService.importCollections, collections)
	yield put(applyFilterRequest())

	const state = yield select()
	yield call(autocompleteService.update, state)
}

function* getCollectionsWorker() {
	try {
		const items: ICollection[] = yield call(API.common.getCollections)
		yield put(setCollectionsData(items))
	} catch (error) {
		yield put(getCollectionsFailure())
	}
}

function* manageCollectionWorker(action: ReturnType<typeof manageCollection>) {
	yield call(API.common.manageCollection, {
		collectionId: action.payload.collectionId,
		itemsIdsToAdd: action.payload.itemsIdsToAdd,
		itemsIdsToDelete: action.payload.itemsIdsToDelete,
	})

	yield call(applyCollectionsChanges)

	// after Optimistic UI applied, retrieve real data from backend
	yield put(updateCollectionsRequest())
}

function* updateCollectionsWorker() {
	yield put(getCollectionsRequest())
}

function* manageArchiveWorker(action: ReturnType<typeof manageArchive>) {
	const archiveCollectionId = yield select(getArchiveCollectionId)

	yield put(manageCollection(archiveCollectionId, action.payload.itemsIdsToAdd, action.payload.itemsIdsToDelete, true))
}

function* setCollectionsDataWorker() {
	yield call(applyCollectionsChanges)
	// we explicitly wait until we apply new filtering results
	yield take(InventoryActionTypes.SetFilterResult)
	yield put(getCollectionsSuccess())
}

function* createCollectionWorker(action: ReturnType<typeof createCollection>) {
	const { id } = yield call(API.common.createCollection, {
		name: action.payload.collectionName,
	})

	yield put(applyNewCollection(id, action.payload.collectionName))
	yield put(manageCollection(id, action.payload.itemsIdsToAdd, []))
}

export default function* collectionsWatchers(): Generator {
	yield takeLatest(CollectionsActionTypes.GetCollectionsRequest, getCollectionsWorker)
	yield takeLatest(CollectionsActionTypes.CreateCollection, createCollectionWorker)
	yield takeLatest(CollectionsActionTypes.ManageCollection, manageCollectionWorker)
	yield takeLatest(CollectionsActionTypes.ManageArchive, manageArchiveWorker)
	yield takeLatest(CollectionsActionTypes.SetCollectionsData, setCollectionsDataWorker)
	yield debounce(5000, CollectionsActionTypes.UpdateCollectionsRequest, updateCollectionsWorker)
}
