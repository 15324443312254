import * as actions from "./actions"
import * as actionSets from "./actionSets"
import { ActionType } from "typesafe-actions"

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-namespace */

export namespace OrganisationActionTypes {
	export const FetchUserOrgsRequest = actionSets.fetchUserOrgs.request
	export const FetchUserOrgsSuccess = actionSets.fetchUserOrgs.success
	export const FetchUserOrgsFailure = actionSets.fetchUserOrgs.failure
}

export type TOrganisationAction = ActionType<typeof actions>
export type TOrganisationActionsSets = typeof actionSets
