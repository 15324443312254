import React from "react"
import { Controller } from "react-hook-form"
import * as styles from "./FormInputs.module.scss"
import { IHookFormInput } from "@components/FormInputs/types"
import { FieldValues } from "react-hook-form/dist/types/fields"
import * as checkboxStyles from "@styles/modules/Checkbox.module.scss"
import classNames from "classnames"

interface IInputFieldProps<TFieldValues extends FieldValues> extends IHookFormInput<TFieldValues> {
	label: string
	id: string
}

function CheckboxInputField<TFieldValues extends FieldValues>(props: IInputFieldProps<TFieldValues>): JSX.Element {
	const { label, control, name, className = "", id } = props

	return (
		<Controller
			render={({ field }) => (
				<div className={styles.checkboxWrapper}>
					<input
						id={id}
						type="checkbox"
						className={classNames(checkboxStyles.checkbox, { [className]: className })}
						checked={field.value}
						onChange={(e) => field.onChange(e.target.checked)}
					/>
					<label className={styles.label} htmlFor={id}>
						{label}
					</label>
				</div>
			)}
			name={name}
			control={control}
		/>
	)
}

export default CheckboxInputField
