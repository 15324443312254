import { AnyAction } from "redux"
import { getRequestStateInfo } from "./tools"
import { TRequestActionsSetGroup, TRequestState, TStateWithRequestStateGroup } from "./types"

/**
 * `commonReducer` should be user within each duck state in order to support `requestState` feature
 * Basic concept here is to move maximum boilerplate logic to current reducer
 */
export function commonReducerCreator<
	T extends TStateWithRequestStateGroup<TRequestActionsSetGroup>,
	K extends AnyAction
>(actionsSetGroup: TRequestActionsSetGroup) {
	return function (state: T, action: K): T {
		const requests = { ...state.requests } as Record<string, TRequestState>
		const requestStateInfo = getRequestStateInfo(action.type, actionsSetGroup)
		if (requestStateInfo) {
			requests[requestStateInfo.name] = {
				isLoading: requestStateInfo.phase === "REQUEST",
				error: requestStateInfo.phase === "FAILURE" && action.error ? { ...action.payload } : null,
			}
		}

		return {
			...state,
			requests,
		}
	}
}
