type TPutForExecution = (key: string, callback: () => void, customDelay?: number) => void

/**
 * This function returns debounce function that identifies each callback
 * by the provided `key`
 * On practice, this could be helpful when you want to debounce multiple callbacks
 * at once
 * @param delay - delay (in ms) that will we used by default
 */
export default function createMappedDebounce(delay: number): TPutForExecution {
	// create a Map where each value is `timeoutId`
	const timeoutMap = new Map<string, NodeJS.Timeout>()

	// this is out target debounce function
	return function mappedDebounce(key: string, callback: () => void, customDelay?: number) {
		if (typeof customDelay === "undefined") {
			customDelay = delay
		}

		const existingTimeout = timeoutMap.get(key)
		if (existingTimeout) {
			clearTimeout(existingTimeout)
		}

		timeoutMap.set(
			key,
			setTimeout(() => {
				callback()
				timeoutMap.delete(key)
			}, customDelay)
		)
	}
}
