import { ActionType } from "typesafe-actions"
import * as actions from "./actions"
import * as actionSets from "./actionSets"

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-namespace */

export namespace AuthActionTypes {
	export const Initialise = "auth/INITIALISE"
	export const LoginRequest = actionSets.login.request
	export const LoginSuccess = actionSets.login.success
	export const LoginFailure = actionSets.login.failure
	export const LogoutRequest = actionSets.logout.request
	export const LogoutSuccess = actionSets.logout.success
	export const SignupRequest = actionSets.signup.request
	export const SignupSuccess = actionSets.signup.success
	export const SignupFailure = actionSets.signup.failure
	export const VerifyRequest = actionSets.verify.request
	export const VerifySuccess = actionSets.verify.success
	export const VerifyFailure = actionSets.verify.failure
	export const ReAuthRequest = actionSets.reAuth.request
	export const ReAuthSuccess = actionSets.reAuth.success
	export const ReAuthFailure = actionSets.reAuth.failure
	export const UpdateCognitoIdentityRequest = actionSets.updateCognitoIdentity.request
	export const UpdateCognitoIdentitySuccess = actionSets.updateCognitoIdentity.success
	export const UpdateCognitoIdentityFailure = actionSets.updateCognitoIdentity.failure
	export const GetCognitoIdentityRequest = actionSets.getCognitoIdentity.request
	export const GetCognitoIdentitySuccess = actionSets.getCognitoIdentity.success
	export const GetCognitoIdentityFailure = actionSets.getCognitoIdentity.failure
}

export type TAuthAction = ActionType<typeof actions>
export type TAuthActionsSets = typeof actionSets
