import { ILocationEntity } from "@typed/api/responses/WorkDetails"

export const sortLocationEntities = (leftEntity: ILocationEntity, rightEntity: ILocationEntity): number => {
	if (leftEntity.date && rightEntity.date) {
		return +new Date(rightEntity.date) - +new Date(leftEntity.date)
	} else if (leftEntity.date && !rightEntity.date) {
		return -1
	} else if (!leftEntity.date && rightEntity.date) {
		return 1
	} else {
		return 0
	}
}
