import { InventoryActionTypes } from "@store/ducks/inventory/types"
import { TAnalyticsEventCreator } from "@services/analytics/definitions"
import { AnalyticsActionTypes } from "@store/ducks/analytics/types"
import { TAnyAction, TRootState } from "@store/rootReducer"
import { getFilteredItems } from "@store/ducks/inventory/selectors"
import { CollectionsActionTypes } from "@store/ducks/collections"

export const createEvent: TAnalyticsEventCreator<TRootState, TAnyAction> = (state, action) => {
	switch (action.type) {
		case InventoryActionTypes.PutItemSuccess:
			return action.payload.isNewItem
				? { name: "Upload Work", payload: { Privacy: "Private" } }
				: { name: "Edit Work", payload: { Privacy: action.payload.isOrganisationItem ? "Organization" : "Private" } }
		case InventoryActionTypes.SetItemForPreview:
			return {
				name: "View Work",
			}
		case InventoryActionTypes.SetFilterResult:
			return (
				(action.payload.isSearch && {
					name: "Search Works",
					payload: {
						"Number of works": getFilteredItems(state).length,
					},
					delay: 2000,
				}) ||
				undefined
			)
		case CollectionsActionTypes.ManageCollection:
			return action.payload.isArchive
				? action.payload.itemsIdsToAdd.length > 0
					? {
							name: "Archive Works",
							payload: {
								"Number of works": action.payload.itemsIdsToAdd.length,
							},
						} // prettier-ignore
					: {
							name: "Unarchive Works",
							payload: {
								"Number of works": action.payload.itemsIdsToDelete.length,
							},
						} // prettier-ignore
				: { name: "Tag Works" }
		case AnalyticsActionTypes.EmitAnalyticEvent:
			return action.payload
		default:
			return undefined
	}
}
