/**
 * Return string of joined non-empty elements
 * @param array
 * @param separator
 */
export function joinStrings(array: (string | undefined)[], separator?: string): string {
	return array.filter((str) => typeof str !== "undefined" && str.length > 0).join(separator || " ")
}

export function reverseString(str: string): string {
	return str.split("").reverse().join("")
}

export function formatCount(num: number): string {
	const numIntPart = Math.floor(num).toString()
	const isInteger = Number.isInteger(num)
	const numFloatPart = (num % 1).toFixed(2).substr(-2)

	const split = reverseString(numIntPart.toString()).match(/.{1,3}/g) || []
	return joinStrings([reverseString(split.join(",")), isInteger ? undefined : numFloatPart], ".")
}
