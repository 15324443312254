import React, { useState } from "react"
import { IRequestAccessCode } from "@typed/api/responses/AccessCode"

type TSubmitAccessCodeProps = {
	submit: (accessCode: string) => void
	details: IRequestAccessCode
}

export const SubmitAccessCode: React.FC<TSubmitAccessCodeProps> = ({ submit, details }) => {
	const [code, setCode] = useState("")
	return (
		<div>
			<div className="row">
				<h4>Verification required for access</h4>
			</div>
			<div className="row">
				<p>
					Text message with an access code was sent to <strong>{details.phone_number}</strong>. Enter access code below
					to see work details.
				</p>
			</div>

			<div className="row">
				<div className="col-3">
					<input
						type="text"
						className="form-control form-control-lg"
						id="access_code"
						placeholder="Access code"
						onChange={(e) => setCode(e.target.value)}
						value={code}
					/>
				</div>
				<div className="col-9">
					<button type="button" className="btn btn-dark btn-lg" onClick={() => submit(code)}>
						Submit
					</button>
				</div>
			</div>
		</div>
	)
}
