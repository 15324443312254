import {
	failureAction,
	requestAction,
	successAction,
	TFailureAction,
	TRequestAction,
	TRequestActionsSet,
	TRequestActionsSetGroup,
	TRequestState,
	TRequestStateGroup,
	TRequestStateInfo,
	TSuccessAction,
} from "./types"

/**
 * Creates a simple requestActionSet in a format `duck/requestName/TYPE`
 * @param duck - store duck (e.g. `authentication`)
 * @param requestName - actual name of current request (e.g. `login` or `logout`)
 */
export function createRequestActionsSet<TD extends string, TRN extends string>(
	duck: TD,
	requestName: TRN
): TRequestActionsSet<TD, TRN> {
	const request = `${duck}/${requestName}/${requestAction}` as TRequestAction<TD, TRN>
	const success = `${duck}/${requestName}/${successAction}` as TSuccessAction<TD, TRN>
	const failure = `${duck}/${requestName}/${failureAction}` as TFailureAction<TD, TRN>

	return { request, success, failure }
}

/**
 * For a given actionsSets creates default requestState
 * @param actionsSets
 */
export function defaultRequestState<TActions extends TRequestActionsSetGroup>(
	actionsSets: TActions
): TRequestStateGroup<TActions> {
	const defaultValue = {
		isLoading: false,
		error: null,
	}

	const requests: Record<string, TRequestState> = {}
	for (const key in actionsSets) {
		if (Object.prototype.hasOwnProperty.call(actionsSets, key)) {
			const actionSet = actionsSets[key]
			requests[actionSet.request] = { ...defaultValue }
		}
	}

	return requests as unknown as TRequestStateGroup<TActions>
}

/**
 * Given some `actionType`, return info about it's actionSet or undefined,
 * if it doesn't match any requestState
 * @param actionType
 * @param actionsSetGroup
 */
export function getRequestStateInfo<TActions extends TRequestActionsSetGroup>(
	actionType: string,
	actionsSetGroup: TActions
): TRequestStateInfo | undefined {
	for (const key in actionsSetGroup) {
		if (Object.prototype.hasOwnProperty.call(actionsSetGroup, key)) {
			const name = actionsSetGroup[key].request
			if (actionType === actionsSetGroup[key].request) {
				return { name, phase: requestAction }
			} else if (actionType === actionsSetGroup[key].success) {
				return { name, phase: successAction }
			} else if (actionType === actionsSetGroup[key].failure) {
				return { name, phase: failureAction }
			}
		}
	}
}
