import { IPredictedArtwork } from "@typed/api/responses/MLPredictArtwork"

/**
 * Returns an array of all fields that has non-empty string
 * @param details
 */
export function getIdentifiedFields(details: IPredictedArtwork): string[] {
	return Object.entries(details)
		.filter(([, value]) => value.length > 0)
		.map(([key]) => key)
}
