import React, { MutableRefObject, useRef } from "react"
import * as styles from "./PreviewContainer.module.scss"
import ItemQuickPreview from "@containers/PreviewContainer/ItemQuickPreview"
import { TProps } from "./types"
import { connect } from "react-redux"
import { getSelectedItemsAsArray, itemIdInPreview } from "@store/ducks/inventory/selectors"
import SelectionQuickPreview from "@containers/PreviewContainer/SelectionQuickPreview"
import classNames from "classnames"
import { CSSTransition } from "react-transition-group"
import * as floatingAnimationStyles from "./FloatingAnimations.module.scss"

const PreviewContainer: React.FC<TProps["Combined"]> = (props) => {
	const { className, selectedItemsIds, itemIdInPreview } = props
	const isMultiMode = typeof itemIdInPreview !== "undefined" && selectedItemsIds.length > 0
	const floatingContainerRef = useRef() as MutableRefObject<HTMLDivElement>

	let content: React.ReactNode = ""
	if (selectedItemsIds.length > 0) {
		content = <SelectionQuickPreview itemsIds={selectedItemsIds} />
	} else if (typeof itemIdInPreview !== "undefined") {
		content = <ItemQuickPreview itemId={itemIdInPreview} />
	}

	return (
		<>
			<div
				className={classNames(styles.outerContainer, className, {
					[styles.asBackground]: isMultiMode,
					[styles.isVisible]: content != "",
				})}
			>
				<div className={styles.innerContainer}>{content}</div>
			</div>
			<CSSTransition
				classNames={floatingAnimationStyles}
				in={isMultiMode}
				timeout={{
					appear: 0,
					enter: 200,
					exit: 200,
				}}
				nodeRef={floatingContainerRef}
				appear={isMultiMode}
			>
				<div ref={floatingContainerRef} className={styles.floatingContainer}>
					{isMultiMode && typeof itemIdInPreview !== "undefined" && <ItemQuickPreview itemId={itemIdInPreview} />}
				</div>
			</CSSTransition>
		</>
	)
}

const mapStateToProps: TProps["State"] = (state) => {
	return {
		selectedItemsIds: getSelectedItemsAsArray(state),
		itemIdInPreview: itemIdInPreview(state),
	}
}

export const PreviewContainerConnected = connect(mapStateToProps)(PreviewContainer)
