import React from "react"
import { IOrganisation } from "@typed/api/responses/Organisation"

interface IComponentProps {
	title: string
	org: IOrganisation
}

export const OrganisationMembersList: React.FC<IComponentProps> = (props) => {
	return (
		<div>
			<h3>{props.title}</h3>
			<ul className="list-group list-group-flush">
				{props.org.members.map((member) => (
					<li key={member.cognito_uuid} className="list-group-item">
						{member.given_name} {member.family_name}
					</li>
				))}
			</ul>
		</div>
	)
}
