import React from "react"

export const CouldNotFind: React.FC = () => {
	return (
		<div className="container pt-5">
			<div className="d-flex justify-content-center align-items-center mt-4">
				<img src="/graphics/notfound.svg" width="300px" alt="not found" />
			</div>
			<div className="d-flex justify-content-center align-items-center mt-4">
				<div className="row">
					<p>We couldn't find the thing you're looking for...</p>
				</div>
			</div>
		</div>
	)
}
