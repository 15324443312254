/**
 * Merges keys from two objects, prioritising properties of `newObject`
 * @param oldObject - initial object that needs to be updated
 * @param newObject - new object, that should be merged into existing
 */
export function mergeObjectProperties<T>(oldObject: T, newObject: T): T {
	const result = { ...newObject }
	for (const key in oldObject) {
		if (Object.prototype.hasOwnProperty.call(oldObject, key) && typeof result[key] === "undefined") {
			Object.assign(result, { [key]: oldObject[key] })
		}
	}

	return result
}
