import React from "react"

export const Loader: React.FC = () => {
	return (
		<div className="d-flex justify-content-center align-items-center mt-4">
			<div className="spinner-border text-dark" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	)
}
