import * as actions from "./actions"
import * as actionSets from "./actionSets"
import { ActionType } from "typesafe-actions"

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-namespace */

export namespace StatusesActionTypes {
	export const FetchStatusesRequest = actionSets.fetchStatuses.request
	export const FetchStatusesSuccess = actionSets.fetchStatuses.success
	export const FetchStatusesFailure = actionSets.fetchStatuses.failure
}

export type TStatusesAction = ActionType<typeof actions>
export type TStatusesActionsSets = typeof actionSets
