import { AuthActionTypes } from "@store/ducks/auth/types"
import { ICognitoAuthenticatedUser, ILoginResponse } from "@typed/api/responses/Cognito"
import API from "@services/api"
import { IAuthDetails } from "@typed/entities"
import { IRemoteAction, RemoteActionTypes } from "@typed/api/responses/RemoteActions"
import {
	getCognitoIdentityRequest,
	getCognitoIdentityFailure,
	getCognitoIdentitySuccess,
	loginRequest,
	loginFailure,
	loginSuccess,
	logoutRequest,
	logoutSuccess,
	reAuthFailure,
	reAuthSuccess,
	signupRequest,
	signupFailure,
	signupSuccess,
	updateCognitoIdentityRequest,
	updateCognitoIdentityFailure,
	updateCognitoIdentitySuccess,
	verifyRequest,
	verifyFailure,
	verifySuccess,
	reAuthRequest,
} from "@store/ducks/auth/actions"
import { call, put, select, takeEvery } from "redux-saga/effects"
import { fetchUserOrgsRequest } from "@store/ducks/orgs"
import { push } from "connected-react-router"
import { StaticRoutes } from "@routes"
import { ICognitoIdentity } from "@typed/entities/Cognito"
import * as MixpanelService from "@services/analytics/implementations/mixpanel"
import * as HotjarService from "@services/analytics/implementations/hotjar"
import * as HubspotService from "@services/analytics/implementations/hubspot"
import { getCognitoSub } from "@store/ducks/auth/selectors"
import { joinStrings } from "@utilities/strings/formatting"
import { resetServices, setupAutocomplete } from "@store/ducks/inventory"
import * as Sentry from "@sentry/react"

function* initialiseWorker() {
	yield put(reAuthRequest())
	yield call(MixpanelService.initialise)
	yield call(HotjarService.initialise)
}

/**
 * This worker is called after successful authorization
 */
function* onAuthorizedWorker() {
	yield put(fetchUserOrgsRequest())
	const cognitoSub = yield select(getCognitoSub)
	const authenticatedUser: ICognitoAuthenticatedUser = yield call(API.amplify.getAuthenticatedUser)

	const userName = joinStrings([authenticatedUser.attributes.name, authenticatedUser.attributes.family_name], " ")
	const userEmail = authenticatedUser.attributes.email
	yield call(MixpanelService.identify, cognitoSub, userName, userEmail)
	yield call(HotjarService.identify, cognitoSub, userName, userEmail)
	yield call(Sentry.setUser, { id: cognitoSub })

	// TODO: probably this should be moved to inventory
	yield put(setupAutocomplete())
}

function* loginUserWorker(action: ReturnType<typeof loginRequest>) {
	const { username, password } = action.payload

	try {
		const user: ILoginResponse = yield call(API.amplify.signIn, { username, password })
		const details: IAuthDetails = {
			given_name: user.attributes.name,
			access_token: user.signInUserSession.accessToken.jwtToken,
			cognito_sub: user.attributes.sub,
		}
		yield put(loginSuccess(details))
		yield put(getCognitoIdentityRequest())

		// Applying remote actions
		// this will fail if user does not exist in the backend before first login
		const remoteActions: IRemoteAction[] = yield call(API.common.fetchRemoteActions)
		if (remoteActions.map((el) => el.name).includes(RemoteActionTypes.CognitoIdentityRequest)) {
			yield put(updateCognitoIdentityRequest())
		}
		yield call(onAuthorizedWorker)
		yield put(push(StaticRoutes.UserInventory))
	} catch (error) {
		if (error?.code === "UserNotConfirmedException") {
			yield put(push(`${StaticRoutes.VerifyUser}/${username}`))
		} else {
			yield put(loginFailure(error))
		}
	}
}

function* logoutUserWorker(action: ReturnType<typeof logoutRequest>) {
	yield call(MixpanelService.reset)
	yield call(API.amplify.signOut, { global: action.payload.global })
	yield put(resetServices())
	yield put(logoutSuccess())
	yield put(push(StaticRoutes.Login))
}

function* signupUserWorker(action: ReturnType<typeof signupRequest>) {
	try {
		const res = yield call(API.amplify.signUp, action.payload)
		yield put(signupSuccess())
		yield put(
			push({
				pathname: `${StaticRoutes.VerifyUser}/${res.userSub}`,
				state: {
					deliveryMedium: res.codeDeliveryDetails.DeliveryMedium,
					destination: res.codeDeliveryDetails.Destination,
					username: res.user.getUsername(),
				},
			})
		)
	} catch (error) {
		yield put(signupFailure(error))
	}
}

export function* verifyUserWorker(action: ReturnType<typeof verifyRequest>): Generator {
	const { username, code } = action.payload
	try {
		yield call(API.amplify.verifyUser, { username, code })
		yield put(verifySuccess())
		yield put(push(StaticRoutes.PostRegistration))
	} catch (error) {
		yield put(verifyFailure(error))
	}
}

function* getCognitoIdentityWorker() {
	try {
		const identity: ICognitoIdentity = yield call(API.amplify.getCognitoIdentity)
		if (identity) {
			yield put(getCognitoIdentitySuccess(identity.identityId))
		} else {
			yield put(getCognitoIdentityFailure({}))
		}
	} catch (error) {
		yield put(getCognitoIdentityFailure(error))
	}
}

function* reauthenticateWorker() {
	try {
		const res = yield call(API.amplify.reauthenticate)
		const details: IAuthDetails = {
			given_name: res.idToken.payload.name,
			access_token: res.accessToken.jwtToken,
			cognito_sub: res.idToken.payload.sub,
		}
		yield put(reAuthSuccess(details))
		yield put(getCognitoIdentityRequest())
		yield call(onAuthorizedWorker)
	} catch (error) {
		yield put(reAuthFailure(error))
	}
}

function* reauthenticateFailureWorker() {
	// only initialise HubSpot for unregistered users
	yield call(HubspotService.initialise)
}

function* updateCognitoIdentityWorker() {
	try {
		const identity: ICognitoIdentity = yield call(API.amplify.getCognitoIdentity)
		yield call(API.common.setCognitoIdentity, identity)
		yield put(updateCognitoIdentitySuccess())
	} catch (error) {
		yield put(updateCognitoIdentityFailure(error))
	}
}

export default function* authWatchers(): Generator {
	yield takeEvery(AuthActionTypes.Initialise, initialiseWorker)
	yield takeEvery(AuthActionTypes.LoginRequest, loginUserWorker)
	yield takeEvery(AuthActionTypes.LogoutRequest, logoutUserWorker)
	yield takeEvery(AuthActionTypes.SignupRequest, signupUserWorker)
	yield takeEvery(AuthActionTypes.VerifyRequest, verifyUserWorker)
	yield takeEvery(AuthActionTypes.GetCognitoIdentityRequest, getCognitoIdentityWorker)
	yield takeEvery(AuthActionTypes.ReAuthRequest, reauthenticateWorker)
	yield takeEvery(AuthActionTypes.ReAuthFailure, reauthenticateFailureWorker)
	yield takeEvery(AuthActionTypes.UpdateCognitoIdentityRequest, updateCognitoIdentityWorker)
}
