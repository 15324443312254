import { useSelector } from "react-redux"
import { getCognitoIdentity, getCognitoSub } from "@store/ducks/auth"

type TCognitoHookResult = {
	cognitoSub: string
	cognitoIdentity: string
}

/**
 * Simple hook to make use of main cognito properties
 */
export function useCognito(): TCognitoHookResult {
	const cognitoSub = useSelector(getCognitoSub)
	const cognitoIdentity = useSelector(getCognitoIdentity)

	return { cognitoSub, cognitoIdentity }
}
