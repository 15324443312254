import React, { useCallback } from "react"
import FieldArrayTable, { TFieldArrayDialogFunction } from "@components/FieldArrayTable"
import { ILocationEntity } from "@typed/api/responses/WorkDetails"
import format from "date-fns/format"
import * as styles from "./LocationHistoryTable.module.scss"
import { ReactComponent as LocationIcon } from "@icons/LocationIcon.svg"
import { joinStrings } from "@utilities/strings/formatting"
import { ILocationEntityForm } from "./types"
import { locationHistoryMappings, sortLocationEntities, getDefaultLocationEntity } from "./utils"
import { LocationDialog } from "./LocationDialog"
import { Control } from "react-hook-form/dist/types/form"
import { IDetailsForm } from "@containers/WorkDetailsContainer/types"

interface ILocationHistoryTableProps {
	control: Control<IDetailsForm>
	insertLocationIntoAutocomplete: (value: string) => void
}

export const LocationHistoryTable: React.FC<ILocationHistoryTableProps> = (props) => {
	const { control, insertLocationIntoAutocomplete } = props

	const getLocationEntityDialog = useCallback<TFieldArrayDialogFunction<ILocationEntityForm>>(
		(entity, onSubmit, onRemove, entityId) =>
			({ closeDialog }) =>
				(
					<LocationDialog
						entity={entity}
						entityId={entityId}
						onSubmit={onSubmit}
						onDelete={onRemove}
						onClose={closeDialog}
					/>
				),
		[]
	)

	const locationEntityAppearance = useCallback((locationEntity: ILocationEntity, props) => {
		const dateFormatted = locationEntity.date && format(new Date(locationEntity.date), "MMM d, yyyy")

		return (
			<button className={styles.record} {...props}>
				<div className={styles.main}>
					<span className={styles.icon}>
						<LocationIcon />
					</span>
					<span className={styles.name}>{locationEntity.name}</span>
					<span className={styles.notes}>{joinStrings([locationEntity.details, locationEntity.notes], " · ")}</span>
				</div>
				{dateFormatted && <div className={styles.date}>{dateFormatted}</div>}
			</button>
		)
	}, [])

	return (
		<FieldArrayTable
			formControl={control}
			formName="fields.locationHistory"
			captions={{
				title: "Location history",
				empty: "No location history",
			}}
			mappers={locationHistoryMappings}
			sort={sortLocationEntities}
			entityAppearance={locationEntityAppearance}
			defaultEntity={getDefaultLocationEntity()}
			dialog={getLocationEntityDialog}
			onSubmit={(entity) => insertLocationIntoAutocomplete(entity.name)}
		/>
	)
}
