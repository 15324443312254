import { useState } from "react"

interface IUseDoubleClickHookProps {
	onClick: () => void
	onDoubleClick: () => void
	timeout: number
}

interface IUseDoubleClickHookResult {
	onClick: () => void
}

/**
 * This hook returns attributes that allows to handle
 * both single and double clicks.
 *
 * Single-click has immediate reaction (invoking `onClick`).
 * Next click (if done within `timeout` ms from the first one)
 * will be recognized as double-click (invoking `onDoubleClick`)
 * @param props
 */
export default function useDoubleClick(props: IUseDoubleClickHookProps): IUseDoubleClickHookResult {
	const { onClick, onDoubleClick, timeout } = props
	const [lastClickStamp, setLastClickStamp] = useState<number | undefined>(undefined)

	const onClickHandler = () => {
		const currentTime = +new Date()
		if (typeof lastClickStamp !== "undefined" && currentTime - lastClickStamp <= timeout) {
			onDoubleClick()
			setLastClickStamp(undefined)
		} else {
			onClick()
			setLastClickStamp(currentTime)
		}
	}

	return {
		onClick: onClickHandler,
	}
}
