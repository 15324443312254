import { TOption } from "@components/FormInputs/types"
import { ITranslatedFunction } from "@services/i18n"

export const getOwnershipDistanceOptions: ITranslatedFunction<TOption[]> = (t) => {
	return [
		{
			value: "unknown",
			label: t("connectionToWork.unknown"),
		},
		{
			value: "own",
			label: t("connectionToWork.own"),
		},
		{
			value: "direct",
			label: t("connectionToWork.direct"),
		},
		{
			value: "one",
			label: "+1",
		},
		{
			value: "two",
			label: "+2",
		},
	]
}
