import { createAxiosRequest } from "@services/api/implementations/rest/requestWrapper"
import { TGetArtistsRequest } from "@services/api/definitions/static/requests"

export const getArtists: TGetArtistsRequest = async () => {
	const { data } = await createAxiosRequest({
		method: "GET",
		url: `https://views-public-resources-production-default.s3.eu-west-1.amazonaws.com/data_assets/artists-array.json`,
	})
	const artists: string[] = data

	return artists
}
