import React from "react"
import { ILinkShareItem } from "@typed/entities"
import { ShareDetails } from "@components/ShareDetails/ShareDetails"

type TLinkShareItemsProps = {
	items: ILinkShareItem[]
}

export const LinkShareItems: React.FC<TLinkShareItemsProps> = (props) => {
	return (
		<div>
			{props.items.map((item) => (
				<ShareDetails item={item} />
			))}
		</div>
	)
}
