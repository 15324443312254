import { OrganisationActionTypes } from "./types"
import { IOrganisation } from "@typed/api/responses/Organisation"
import { createAction } from "typesafe-actions"
import { createErrorAction } from "@store/shared/errorsHandling"

export const fetchUserOrgsRequest = createAction(OrganisationActionTypes.FetchUserOrgsRequest)()
export const fetchUserOrgsSuccess = createAction(OrganisationActionTypes.FetchUserOrgsSuccess, (orgs) => ({ orgs }))<{
	orgs: IOrganisation[]
}>()
export const fetchUserOrgsFailure = createErrorAction(OrganisationActionTypes.FetchUserOrgsFailure)()
