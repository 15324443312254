import React, { useCallback, useMemo } from "react"
import { RouteComponentProps, useParams, withRouter } from "react-router-dom"
import WorkDetailsContainer from "@containers/WorkDetailsContainer"
import useInventoryItem from "@enhancers/useInventoryItem"
import getCurrency from "@utilities/currency/getCurrency"
import { ICreateItem } from "@typed/api/requests/CreateItem"
import { connect } from "react-redux"
import { putItemRequest } from "@store/ducks/inventory"
import { ItemPermissions } from "@typed/entities/ItemPermissions"
import { useDropzone } from "react-dropzone"
import { StaticRoutes } from "@routes"
import { TAnalyticsEventEmitter } from "@services/analytics/definitions"
import { emitAnalyticsEvent } from "@store/ducks/analytics/actions"
import { createCollection, manageCollection } from "@store/ducks/collections"
import * as standaloneEvents from "@services/analytics/implementations/standaloneEvents"
import useStatuses from "@enhancers/useStatuses"
import getDefaultStatus from "@utilities/statuses/getDefaultStatus"
import { TRootState } from "@store/rootReducer"
import * as inventorySelectors from "@store/ducks/inventory/selectors"
import * as inventoryActionSets from "@store/ducks/inventory/actionSets"
import { useTranslation } from "react-i18next"
import { useCognito } from "@enhancers/useCongnito"

interface IURLParams {
	itemClientID: string
}

interface IEditWorkProps {
	putItemRequest: (item: ICreateItem) => void
	emitAnalyticsEvent: TAnalyticsEventEmitter
	manageCollection: (collectionId: string, itemsIdsToAdd: string[], itemsIdsToDelete: string[]) => void
	createCollection: (collectionName: string, itemsIdsToAdd: string[]) => void
}

interface IReduxStateProps {
	isSaving: boolean
}

const EditWorkNew: React.FC<IReduxStateProps & IEditWorkProps & RouteComponentProps> = (props) => {
	const { itemClientID } = useParams<IURLParams>()
	const { item, collections, notFound } = useInventoryItem(itemClientID)
	const { getRootProps, isDragActive } = useDropzone()
	const { statuses } = useStatuses()
	const { history, emitAnalyticsEvent, isSaving } = props
	const { ready } = useTranslation(undefined, { useSuspense: false })

	const handleMagicFill = useCallback((details) => standaloneEvents.magicFill(emitAnalyticsEvent, details), [])
	const handleAvailabilityChange = useCallback(
		(status, availability) => standaloneEvents.availabilityChange(emitAnalyticsEvent, { status, availability }),
		[]
	)
	const handleAddToCollection = useCallback(
		(collectionId) => props.manageCollection(collectionId, [itemClientID], []),
		[]
	)
	const handleRemoveFromCollection = useCallback(
		(collectionId) => props.manageCollection(collectionId, [], [itemClientID]),
		[]
	)
	const handleCreateCollection = useCallback((collectionId) => props.createCollection(collectionId, [itemClientID]), [])

	const { cognitoSub } = useCognito()
	const isOwnedWork = useMemo(() => item?.owner_details.cognito_uuid === cognitoSub, [item, cognitoSub])

	if (!item || notFound) {
		return <></>
	}

	if (typeof statuses === "undefined") {
		return <></>
	}

	if (!ready) {
		return <></>
	}

	let statusId, availabilityId
	if (item.status && item.availability) {
		statusId = item.status
		availabilityId = item.availability
	} else {
		const { defaultStatusId, defaultAvailabilityId } = getDefaultStatus(statuses)
		statusId = defaultStatusId
		availabilityId = defaultAvailabilityId
	}

	const imagePaths = (item.images || []).map((imageURLs, index) => {
		return {
			src: imageURLs.thumbnail,
			fileName: item.image_names[index],
		}
	})

	const performEdit = async (details: ICreateItem) => {
		props.putItemRequest(details)
	}

	const canEdit = item.permissions.includes(ItemPermissions.canEdit)

	return (
		<div {...getRootProps()}>
			<WorkDetailsContainer
				properties={{
					itemClientID: item.id,
					artist: item.artist,
					title: item.title,
					medium: item.medium || "",
					year: item.year || "",
					dimWidth: item.dim_width,
					dimHeight: item.dim_height,
					dimDepth: item.dim_depth,
					dimsOther: item.dims_other,
					localDimUnit: "cm",
					notes: item.description,
					accessDistance: item.ownership_distance,
					source: item.owner,
					costPrice: item.cost_price,
					retailPrice: item.retail_price,
					provenance: item.provenance,
					exhibitionHistory: item.exhibition_history,
					literature: item.literature,
					stockCode: item.stock_code,
					insurancePrice: item.insurance_price,
					confidentialNote: item.confidential_note,
					location: item.location,
					workType: item.work_type,
					globalCurrency: getCurrency(item.retail_price_currency),
					series: item.series || "",
					statusId,
					availabilityId,
					editionDetails: item.edition,
					locationHistory: item.location_history,
					temporaryAdmission: item.temporary_admission_history,
					temporaryAdmissionNotes: item.temporary_admission_notes,
					sharedWithOrganisation: !!item.org_uid,
				}}
				imagePaths={imagePaths}
				collections={collections}
				canEditImages={canEdit}
				isDraggingFile={isDragActive}
				onSave={performEdit}
				onClose={() => {
					history.push(StaticRoutes.UserInventory)
				}}
				onMagicFill={handleMagicFill}
				onAvailabilityChange={handleAvailabilityChange}
				addToCollection={handleAddToCollection}
				removeFromCollection={handleRemoveFromCollection}
				createCollection={handleCreateCollection}
				isSavingInProgress={isSaving}
				isOwnedWork={isOwnedWork}
			/>
		</div>
	)
}

function mapStateToProps(state: TRootState): IReduxStateProps {
	return {
		isSaving: inventorySelectors.getLoadingStatus(inventoryActionSets.putItem.request)(state),
	}
}

export default connect(mapStateToProps, { putItemRequest, emitAnalyticsEvent, manageCollection, createCollection })(
	withRouter(EditWorkNew)
)
