import { UseFormSetValue } from "react-hook-form/dist/types/form"
import API from "@services/api"
import { IDetailsForm } from "@containers/WorkDetailsContainer/types"
import { inchToCm } from "@utilities"
import { IPredictedArtwork } from "@typed/api/responses/MLPredictArtwork"

export default async function processMagicFill(
	query: string,
	setValue: UseFormSetValue<IDetailsForm>,
	dimUnit: "cm" | "inch"
): Promise<IPredictedArtwork | null> {
	const { details } = await API.ml.predictArtworkEntities({ query })
	if (!details) {
		return null
	}

	const [width, height, depth] = [details.width_cm, details.height_cm, details.depth_cm].map((value) => {
		const valueAsNumber = +value
		if (isNaN(valueAsNumber) || valueAsNumber == 0) {
			return undefined
		}

		return dimUnit == "inch" ? inchToCm(valueAsNumber).toString() : value
	})

	if (details.artist) {
		setValue("fields.artist", details.artist)
	}
	if (details.title) {
		setValue("fields.title", details.title)
	}
	if (details.year) {
		setValue("fields.year", details.year)
	}
	if (details.medium) {
		setValue("fields.medium", details.medium)
	}
	if (height) {
		setValue("fields.dimHeight", height)
	}
	if (width) {
		setValue("fields.dimWidth", width)
	}
	if (depth) {
		setValue("fields.dimDepth", depth)
	}

	return details
}
