import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import API from "@services/api"
import { logoutRequest } from "@store/ducks/auth/actions"
import { ICognitoAuthenticatedUser } from "@typed/api/responses/Cognito"
import PageLayout from "@layouts/PageLayout"
import VerifiedBadge from "@components/VerifiedBadge"

const AccountPage: React.FC = () => {
	const dispatch = useDispatch()
	const [userAccount, setUserAccount] = useState<ICognitoAuthenticatedUser>({
		attributes: {
			name: "",
			email: "",
			email_verified: false,
			family_name: "",
			phone_number: "",
			phone_number_verified: false,
		},
	})

	const getUserDetails = async () => {
		const user = await API.amplify.getAuthenticatedUser()
		setUserAccount(user)
	}

	useEffect(() => {
		getUserDetails()
	}, [])

	return (
		<PageLayout>
			<div className="container pt-5">
				<h1 className="display-4 mb-3">Account</h1>

				<div className="shadow-sm p-3" role="alert">
					<h3>Contact</h3>
					<table className="table">
						<tbody>
							<tr>
								<td width="200px">First name</td>
								<td>
									<strong>{userAccount.attributes.name}</strong>
								</td>
							</tr>
							<tr>
								<td>Family name</td>
								<td>
									<strong>{userAccount.attributes.family_name}</strong>
								</td>
							</tr>
							<tr>
								<td>Phone number</td>
								<td>
									<strong>{userAccount.attributes.phone_number}</strong>{" "}
									<VerifiedBadge verified={userAccount.attributes.phone_number_verified} />
								</td>
							</tr>
							<tr>
								<td width="200px">Email</td>
								<td>
									<strong>{userAccount.attributes.email}</strong>{" "}
									<VerifiedBadge verified={userAccount.attributes.email_verified} />
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				{/* <div className="shadow-sm p-3 mt-5" role="alert">
							<h3>Billing</h3>
								<table className="table">
									<tbody>
										<tr>
											<td width="200px">Membership</td>
											<td><strong>Premium</strong></td>
										</tr>
										<tr>
												<td>Auto renew</td>
												<td><strong>Enabled</strong></td>
										</tr>
										<tr>
												<td>Expiry date</td>
												<td><strong>2020 11 11</strong></td>
										</tr>
									</tbody>
								</table>
							</div> */}

				<div>
					<a className="nav-link" href="#logout" onClick={() => dispatch(logoutRequest(true))}>
						Logout from all devices
					</a>
				</div>
			</div>
		</PageLayout>
	)
}

export default AccountPage
