import { CollectionsActionTypes } from "./types"
import { createAction } from "typesafe-actions"
import { ICollection } from "@typed/entities/InventoryCollection"

export const getCollectionsRequest = createAction(CollectionsActionTypes.GetCollectionsRequest)()
export const getCollectionsSuccess = createAction(CollectionsActionTypes.GetCollectionsSuccess)()
export const getCollectionsFailure = createAction(CollectionsActionTypes.GetCollectionsFailure)()

export const updateCollectionsRequest = createAction(CollectionsActionTypes.UpdateCollectionsRequest)()

export const setCollectionsData = createAction(
	CollectionsActionTypes.SetCollectionsData,
	(collectionsToPut: ICollection[]) => ({ collectionsToPut })
)<{
	collectionsToPut: ICollection[]
}>()

export const manageCollection = createAction(
	CollectionsActionTypes.ManageCollection,
	(collectionId, itemsIdsToAdd, itemsIdsToDelete, isArchive?) => ({
		collectionId,
		itemsIdsToAdd,
		itemsIdsToDelete,
		isArchive,
	})
)<{
	collectionId: string
	itemsIdsToAdd: string[]
	itemsIdsToDelete: string[]
	isArchive: boolean | undefined
}>()

export const createCollection = createAction(
	CollectionsActionTypes.CreateCollection,
	(collectionName, itemsIdsToAdd) => ({
		collectionName,
		itemsIdsToAdd,
	})
)<{
	collectionName: string
	itemsIdsToAdd: string[]
}>()

export const applyNewCollection = createAction(
	CollectionsActionTypes.ApplyNewCollection,
	(collectionId, collectionName) => ({
		collectionId,
		collectionName,
	})
)<{
	collectionId: string
	collectionName: string
}>()

export const manageArchive = createAction(CollectionsActionTypes.ManageArchive, (itemsIdsToAdd, itemsIdsToDelete) => ({
	itemsIdsToAdd,
	itemsIdsToDelete,
}))<{
	itemsIdsToAdd: string[]
	itemsIdsToDelete: string[]
}>()
