import { AutocompleteService } from "@services/autocomplete/AutocompleteService"
import { TRootState } from "@store/rootReducer"
import { ICollection } from "@typed/entities/InventoryCollection"
import { IAutocompleteEntity, ISearchResults, TResultFilterFn } from "@services/autocomplete/definitions"
import { IItemToInsert } from "@services/search/defintions"
import { getAllLocations } from "@store/ducks/inventory/selectors"

const autocompleteCollection = new AutocompleteService<TRootState>({
	collections: {
		selector: (state) => state.collections.collections.entities.collections,
		baseFilter: (id, state) => state.collections.collections.entities.collections[id].kind != "archive",
		deriveValue: (_key, value) => (value as ICollection).name,
	},
	artists: {
		selector: (state) => state.inventory.items.entities.artists,
		baseFilter: (key) => key.length > 0,
		deriveValue: (key) => key,
	},
	series: {
		selector: (state) => state.inventory.items.entities.series,
		baseFilter: (key) => key.length > 0,
		deriveValue: (key) => key,
	},
	locationNames: {
		selector: (state) => getAllLocations(state),
		baseFilter: (key) => key.length > 0,
		deriveValue: (key) => key,
	},
	workType: {
		selector: (state) => state.inventory.items.entities.work_type,
		baseFilter: (key) => key.length > 0 && key != "",
		deriveValue: (key) => key,
		// "Other" option should always be in the bottom
		sort: (left, right) => {
			if (left.id === "Other") {
				return 1
			} else if (right.id === "Other") {
				return -1
			} else {
				return 0
			}
		},
	},
})

export function update(state: TRootState): Promise<void> {
	return autocompleteCollection.update(state)
}

export function insert(unitKey: string, items: IItemToInsert[]): Promise<void> {
	return autocompleteCollection.insert(unitKey, items)
}

export function search(autocompleteUnitKey: string, query: string, filter?: TResultFilterFn): Promise<ISearchResults> {
	return autocompleteCollection.search(autocompleteUnitKey, query, filter)
}

export function getAll(autocompleteUnitKey: string, filter?: TResultFilterFn): Promise<IAutocompleteEntity[]> {
	return autocompleteCollection.getAll(autocompleteUnitKey, filter)
}

export function clear(): Promise<void> {
	return autocompleteCollection.clear()
}
