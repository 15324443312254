import { InventorySearchService } from "./InventorySearchService"
import { TSearchParams } from "@typed/entities/Search"
import { ICollection } from "@typed/entities/InventoryCollection"
import { IWorkDetails } from "@typed/api/responses/WorkDetails"

const inventorySearchService = new InventorySearchService()

export function putItems(items: IWorkDetails[]): Promise<void> {
	return inventorySearchService.putItems(items)
}

export function removeItems(itemsIds: string[]): Promise<void> {
	return inventorySearchService.removeItems(itemsIds)
}

export function importCollections(collections: ICollection[]): void {
	return inventorySearchService.importCollections(collections)
}

export function search(query: TSearchParams): Promise<string[]> {
	return inventorySearchService.search(query)
}

export function clear(): Promise<void> {
	return inventorySearchService.clear()
}

export { hasEmptyParams } from "./functions"
