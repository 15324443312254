import { FieldValues } from "react-hook-form/dist/types/fields"
import { IHookFormInput } from "@components/FormInputs/types"
import { FormInputWrapper } from "@components/FormInputs/FormInputWrapper"
import React from "react"
import { NumericInputFieldController } from "@components/FormInputs/NumericInputField"
import { SelectInputFieldController } from "@components/FormInputs/SelectInputField"
import { currencies, Currency, TCurrencyStrings } from "@typed/entities"
import { FieldPath } from "react-hook-form/dist/types"
import * as styles from "./PriceInputField.module.scss"
import { UseFormWatch } from "react-hook-form/dist/types/form"
import { getCurrencySymbol } from "@utilities/currency/getCurrency"

interface IInputFieldProps<TFieldValues extends FieldValues> extends IHookFormInput<TFieldValues> {
	label: string
	currencyName: FieldPath<TFieldValues>
	watch: UseFormWatch<TFieldValues>
}

/**
 * This is a combination of `NumericInputField` and `SelectionInputField` with currency
 * @param props
 * @constructor
 */
function PriceInputField<TFieldValues extends FieldValues>(props: IInputFieldProps<TFieldValues>): JSX.Element {
	const { label, control, name, currencyName, className, watch } = props
	const currency = watch(currencyName)
	const currencySymbol = getCurrencySymbol(currency)

	return (
		<FormInputWrapper className={className} label={label}>
			<div className={styles.wrapper}>
				<NumericInputFieldController
					className={styles.numericInput}
					prefix={`${currencySymbol} `}
					thousandSeparator={true}
					control={control}
					name={name}
				/>
				<SelectInputFieldController
					control={control}
					name={currencyName}
					options={currencies.map((currency: TCurrencyStrings) => ({
						value: currency,
						label: Currency[currency].split(" ")[1],
					}))}
				/>
			</div>
		</FormInputWrapper>
	)
}

export default PriceInputField
