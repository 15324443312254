import * as yup from "yup"
import { ICreateItem } from "@typed/api/requests/CreateItem"
import { IFormFields } from "@containers/WorkDetailsContainer"
import { IImageItem } from "@components/ArtworkImages"
import { IDetailsForm } from "@containers/WorkDetailsContainer/types"
import { inchToCm } from "@utilities"
import { IOrganisation } from "@typed/api/responses/Organisation"

export const locationEntityValidationSchema = yup.object().shape({
	name: yup.string().required(),
	details: yup.string(),
	notes: yup.string(),
	date: yup.string().nullable(),
})

export const temporaryAdmissionEntityValidationSchema = yup.object().shape({
	importNumber: yup.string(),
	exportNumber: yup.string(),
	entryDate: yup.string().nullable(),
	returnDate: yup.string().nullable(),
	declaredValue: yup.string(),
	importDuty: yup.string(),
})

export const validationSchema = yup.object().shape({
	fields: yup.object().shape({
		artist: yup.string().required(),
		title: yup.string(),
		medium: yup.string(),
		year: yup.string(),
		dimWidth: yup.string(),
		dimHeight: yup.string(),
		dimDepth: yup.string(),
		notes: yup.string(),
		accessDistance: yup.string(),
		sharedWithOrganisation: yup.boolean(),
		source: yup.string(),
		costPrice: yup.string(),
		retailPrice: yup.string(),
		provenance: yup.string(),
		exhibitionHistory: yup.string(),
		literature: yup.string(),

		stockCode: yup.string(),
		globalCurrency: yup.string(),
		insurancePrice: yup.string(),
		confidentialNote: yup.string(),
		location: yup.string(),
		workType: yup.string(),
		series: yup.string(),
		statusId: yup.string(),
		availabilityId: yup.string(),
		editionDetails: yup.string(),

		locationHistory: yup.array(locationEntityValidationSchema),
		temporaryAdmission: yup.array(temporaryAdmissionEntityValidationSchema),
		temporaryAdmissionNotes: yup.string(),
	}),
})

export type TImageNameExtractFn = (imageItem: IImageItem) => string

export function transformFormRequest(
	clientId: string,
	data: IDetailsForm,
	images: IImageItem[],
	getImageName: TImageNameExtractFn,
	relatedOrganisation?: IOrganisation
): ICreateItem {
	const { fields, temporary } = data

	const [width, height, depth] = [fields.dimWidth, fields.dimHeight, fields.dimDepth].map((value) => {
		const valueAsNumber = +value
		if (isNaN(valueAsNumber)) {
			return ""
		}

		return temporary.dimUnit == "inch" ? inchToCm(valueAsNumber).toString() : value
	})

	return {
		client_id: clientId,
		artist: fields.artist,
		title: fields.title,
		year: fields.year,
		medium: fields.medium,
		dim_width: width,
		dim_height: height,
		dim_depth: depth,
		dims_other: fields.dimsOther,
		cost_price: fields.costPrice,
		retail_price: fields.retailPrice,
		owner: fields.source,
		description: fields.notes,
		ownership: fields.accessDistance,
		is_deleted: false,
		image_names: images.map((item) => getImageName(item)),
		provenance: fields.provenance,
		exhibition_history: fields.exhibitionHistory,
		literature: fields.literature,

		stock_code: fields.stockCode,
		insurance_price: fields.insurancePrice,
		insurance_price_currency: fields.globalCurrency,
		confidential_note: fields.confidentialNote,
		location: fields.location,
		work_type: fields.workType,
		cost_price_currency: fields.globalCurrency,
		retail_price_currency: fields.globalCurrency,
		series: fields.series,

		status: fields.statusId,
		availability: fields.availabilityId,
		edition: fields.editionDetails,
		location_history: fields.locationHistory,
		temporary_admission_history: fields.temporaryAdmission,
		temporary_admission_notes: fields.temporaryAdmissionNotes,

		org_uids: fields.sharedWithOrganisation && relatedOrganisation?.uid ? [relatedOrganisation.uid] : [],
	}
}

export type TTabs = "overview" | "additionalDetails" | "provenance" | "financial"

export function deriveTabIndex(tab: TTabs): number {
	switch (tab) {
		case "overview":
			return 0
		case "additionalDetails":
			return 1
		case "provenance":
			return 2
		case "financial":
			return 3
	}
}

export type TTabsFields = {
	[property in TTabs]: (keyof IFormFields)[]
}

export const tabFields: TTabsFields = {
	overview: [
		"artist",
		"title",
		"year",
		"medium",
		"workType",
		"dimHeight",
		"dimWidth",
		"dimDepth",
		"source",
		"accessDistance",
	],
	additionalDetails: ["stockCode", "notes", "dimsOther", "confidentialNote", "location"],
	provenance: ["provenance", "exhibitionHistory", "literature"],
	financial: ["globalCurrency", "costPrice", "retailPrice", "insurancePrice"],
}
