// todo - cache breaking
import { ImageType } from "@typed/entities"
import { environment } from "@services/environment"

const imageExtension = "png"

export const getImagePath = (
	client_id: string,
	user_identity: string,
	itemName: string,
	imageType: ImageType = ImageType.fullscreen
): string => {
	return `${environment.IMAGES_CDN}/protected/${user_identity}/${client_id}/${imageType}_${encodeURIComponent(
		itemName
	)}`
}

// file naming convention for S3
export const patchFileName = (fileName: string): string => {
	return `${encodeURIComponent(fileName.replace(/[^\w\s]/gi, ""))}_web.${imageExtension}`
}

/**
 * In order to handle multiple files with same names we introduce incremental file naming
 * File can either have `_{number}.png` or just `.png` format
 * In the first case we simply increment counter, in second case we append `_1` before the extension
 * @param patchedFileName
 */
export const nextFileName = (patchedFileName: string): string => {
	const suffixIndex = patchedFileName.lastIndexOf(`.${imageExtension}`)
	const fileName = patchedFileName.substring(0, suffixIndex)

	const hasMatch = fileName.match(/_(\d*)$/)
	if (hasMatch) {
		const replaced = fileName.replace(/_(\d*)$/, (_, x) => `_${+x + 1}`)
		return `${replaced}.${imageExtension}`
	}

	return `${fileName}_1.${imageExtension}`
}

// obtain file name from path
export const getFileName = (path: string): string => {
	return path.replace(/^.*[\\/]/, "")
}
