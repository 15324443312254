import React, { useCallback, useMemo, useState } from "react"
import * as styles from "./FieldArrayTable.module.scss"
import { TDialogNode } from "@components/ButtonWithDialog"
import * as animationStyles from "@components/ButtonWithDialog/Animations.module.scss"
import { FieldValues } from "react-hook-form/dist/types/fields"
import { FieldArrayPath } from "react-hook-form/dist/types/utils"
import { useFieldArray } from "react-hook-form"
import { IFieldArrayTableProps, TEntityRemoveCallback, TEntitySubmitCallback } from "./types"
import { Dialog, Transition } from "@headlessui/react"
import * as dialogStyles from "@components/ButtonWithDialog/ButtonWithDialog.module.scss"
import AsButton from "@components/AsButton"

/**
 * This component works natively with ReactHookForms' FieldArray
 * It build a table and allows creating, updating and removing records straight within ReactHookForm model
 */

export function FieldArrayTable<
	TDialogEntity,
	TFieldValues extends FieldValues = FieldValues,
	TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>
>(props: IFieldArrayTableProps<TDialogEntity, TFieldValues, TFieldArrayName>): JSX.Element | null {
	const { captions, entityAppearance, dialog, defaultEntity, mappers, sort, className } = props
	const outerOnSubmitHandler = props.onSubmit || (() => undefined)
	const header = props.header || (() => null)

	const { fields, prepend, update, remove } = useFieldArray({
		control: props.formControl,
		name: props.formName,
	})

	const sortedFields = useMemo(() => fields.slice().sort(sort), [fields])
	const getEntityIndexById = useCallback((givenId: string) => fields.findIndex(({ id }) => id === givenId), [fields])

	const onSubmitCallback = useCallback<TEntitySubmitCallback<TDialogEntity>>(
		(dialogEntity, entityId) => {
			const mappedEntity = mappers.toEntity(dialogEntity)
			if (typeof entityId === "undefined") {
				prepend(mappedEntity)
			} else {
				update(getEntityIndexById(entityId), mappedEntity)
			}

			outerOnSubmitHandler(mappedEntity)
		},
		[getEntityIndexById, prepend, update]
	)
	const onRemoveCallback = useCallback<TEntityRemoveCallback>(
		(entityId) => {
			remove(getEntityIndexById(entityId))
		},
		[getEntityIndexById, remove]
	)

	/**
	 * We can't use our `ButtonWithDialog` because actions within this dialog can destroy the element which contains
	 * the dialog component as it's child. That's why we have a plain structure here:
	 * - <button /> <button /> ... - buttons
	 * - <Dialog /> - dialog is independent and won't be destroyed if some button is destroyed
	 */
	const [isDialogVisible, setIsDialogVisible] = useState(false)
	const [dialogNode, setDialogNode] = useState<TDialogNode | undefined>(undefined)
	const showDialog = useCallback(
		(mappedDialogEntity, entityId) => {
			setDialogNode(() => dialog(mappedDialogEntity, onSubmitCallback, onRemoveCallback, entityId))
			setIsDialogVisible(true)
		},
		[dialog, setDialogNode, setIsDialogVisible, onSubmitCallback, onRemoveCallback]
	)
	const handleAfterDialogLeave = useCallback(() => {
		setDialogNode(undefined)
	}, [setDialogNode])

	return (
		<>
			<div className={styles.tableControls}>
				<span className={styles.title}>{captions.title}</span>
				<button className={styles.addEntityButton} onClick={() => showDialog(defaultEntity, undefined)}>
					Add
				</button>
			</div>
			<div className={className}>
				{header({ entities: sortedFields })}
				{sortedFields.length > 0 ? (
					sortedFields.map((entity) => {
						const mappedDialogEntity = mappers.toDialogEntity(entity)
						return (
							<AsButton
								key={entity.id}
								onClick={() => showDialog(mappedDialogEntity, entity.id)}
								button={(props) => entityAppearance(entity, props)}
							/>
						)
					})
				) : (
					<div className={styles.emptyState}>{captions.empty}</div>
				)}
			</div>
			<Transition appear show={isDialogVisible} afterLeave={handleAfterDialogLeave}>
				<Dialog onClose={() => setIsDialogVisible(false)} className={dialogStyles.dialog}>
					<Dialog.Overlay />
					<Transition.Child {...animationStyles}>
						{dialogNode &&
							dialogNode({
								closeDialog: () => setIsDialogVisible(false),
							})}
					</Transition.Child>
				</Dialog>
			</Transition>
		</>
	)
}
