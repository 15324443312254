import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react"
import * as styles from "./InventoryContainer.module.scss"
import ItemsListContainer from "@containers/ItemsListContainer"
import ItemsGridContainer from "@containers/ItemsGridContainer"
import { ReactComponent as GridIcon } from "@icons/GridIcon.svg"
import { ReactComponent as ListIcon } from "@icons/ListIcon.svg"
import IconButton from "@components/IconButton"
import { TProps } from "./types"
import { setFilterItemsSelected } from "@store/ducks/inventory"
import { connect } from "react-redux"
import * as inventorySelectors from "@store/ducks/inventory/selectors"
import * as collectionsSelectors from "@store/ducks/collections/selectors"
import * as animationStyles from "./Animations.module.scss"
import { CSSTransition } from "react-transition-group"
import { useTranslation } from "react-i18next"
import { getBulkSelectionTitle } from "@containers/InventoryContainer/functions"
import Checkbox, { CheckboxState } from "@components/Checkbox"
import { formatCount } from "@utilities/strings/formatting"
import FilterPopover from "./FilterPopover"
import SortPopover from "./SortPopover"
import { ImportArtworksPopover } from "./ImportArtworksPopover/ImportArtworksPopover"
import { StaticRoutes } from "@routes"
import { Link } from "react-router-dom"
import SearchContainer from "@containers/SearchContainer"
import SkeletonLoader from "@containers/ItemsListContainer/SkeletonLoader"
import * as inventoryActionSets from "@store/ducks/inventory/actionSets"
import * as collectionsActionSets from "@store/ducks/collections/actionSets"
import classNames from "classnames"

// we need `ToList` and `ToGrid` states in order to show "disappear" animation
enum ViewMode {
	None,
	Loading,
	ToGrid,
	Grid,
	ToList,
	List,
}

const EmptyInventory: React.FC = () => {
	const { t } = useTranslation()

	return (
		<div className={styles.emptyResultsWrapper}>
			<div className={styles.emptyResults}>
				<h2 className={styles.title}>{t("inventory.noWorksTitle")}</h2>
				<div className={styles.description}>{t("inventory.noWorksDescription")}</div>
				<Link to={StaticRoutes.CreateNewWork} className={styles.addWorkButton}>
					{t("inventory.addWork")}
				</Link>
			</div>
		</div>
	)
}

const EmptyFilterResults: React.FC = () => {
	const { t } = useTranslation()

	return (
		<div className={styles.emptyResultsWrapper}>
			<div className={styles.emptyResults}>
				<h2 className={styles.title}>{t("inventory.emptyFilterTitle")}</h2>
				<div className={styles.description}>{t("inventory.emptyFilterDescription")}</div>
			</div>
		</div>
	)
}

const displayStyle = (shouldDisplay: boolean) => ({
	display: shouldDisplay ? "block" : "none",
})

const InventoryContainer: React.FC<TProps["Combined"]> = (props) => {
	const { areAllFilterItemsSelected, areAnyFilterItemsSelected, itemsCount, filteredItemsCount, hadInitialFetch } =
		props
	const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.None)

	const hadInitialFetchMemoized = useMemo(() => hadInitialFetch, [props.isLoading])
	const isLoading = !hadInitialFetchMemoized && props.isLoading

	const { t, ready } = useTranslation(undefined, { useSuspense: false })
	const isEmptyState = !isLoading && itemsCount === 0
	const isEmptyFiltersState = !isLoading && itemsCount !== 0 && filteredItemsCount === 0

	useEffect(() => {
		// we only show loading skeleton on initial opening
		setViewMode(isLoading ? ViewMode.Loading : ViewMode.List)
	}, [isLoading])

	// setting default state (right after we loaded any items)
	useEffect(() => {
		if (!isLoading && viewMode === ViewMode.Loading) {
			setViewMode(ViewMode.List)
		}
	}, [isLoading, viewMode])

	const toggleViewMode = () => {
		if (viewMode === ViewMode.List) {
			setViewMode(ViewMode.ToGrid)
		} else if (viewMode === ViewMode.Grid) {
			setViewMode(ViewMode.ToList)
		}
	}

	const shouldDisplayGrid = () => [ViewMode.Grid, ViewMode.ToList].includes(viewMode)
	const shouldDisplayList = () => [ViewMode.List, ViewMode.ToGrid].includes(viewMode)

	const gridRef = useRef() as MutableRefObject<HTMLDivElement>
	const listRef = useRef() as MutableRefObject<HTMLDivElement>

	return (
		<div className={classNames(styles.container, props.className)}>
			{ready && (
				<>
					<SearchContainer className="" />
					<div className={styles.filterOperations}>
						<Checkbox
							className={styles.checkbox}
							disabled={![ViewMode.Grid, ViewMode.List].includes(viewMode)}
							checked={
								areAnyFilterItemsSelected
									? areAllFilterItemsSelected
										? CheckboxState.Checked
										: CheckboxState.Indeterminate
									: CheckboxState.Unchecked
							}
							title={(checked) => getBulkSelectionTitle(checked)(t)}
							onChange={(checked) => {
								props.setFilterItemsSelected(checked)
							}}
						/>
						{!isLoading && (
							<span className={styles.itemsCount}>
								{formatCount(filteredItemsCount)} {t("inventory.numberOfWorks", { count: filteredItemsCount })}
							</span>
						)}
						<FilterPopover className={styles.filterButton} />
						<ImportArtworksPopover className={styles.importArtworksButton} />
						<Link to={StaticRoutes.CreateNewWork} className={styles.addWorkButton}>
							{t("inventory.addWork")}
						</Link>
						<SortPopover className={styles.sortButton} />
						<div className={styles.viewToggleWrapper}>
							<IconButton
								icon={shouldDisplayGrid() ? <ListIcon /> : <GridIcon />}
								onClick={toggleViewMode}
								label={shouldDisplayGrid() ? t("bulkActions.switchToList") : t("bulkActions.switchToGrid")}
								className={styles.viewToggleButton}
								disabled={![ViewMode.Grid, ViewMode.List].includes(viewMode)}
							/>
						</div>
					</div>

					<div className={styles.viewWrapper} style={displayStyle(!isEmptyState && !isEmptyFiltersState)}>
						<CSSTransition
							in={viewMode === ViewMode.Grid}
							onExited={() => setViewMode(ViewMode.List)}
							nodeRef={gridRef}
							timeout={250}
							classNames={{ ...animationStyles }}
						>
							<div ref={gridRef} className={styles.viewContainer}>
								{shouldDisplayGrid() && <ItemsGridContainer />}
							</div>
						</CSSTransition>
						<CSSTransition
							in={viewMode === ViewMode.List || viewMode === ViewMode.Loading}
							appear={true}
							onExited={() => setViewMode(ViewMode.Grid)}
							nodeRef={listRef}
							timeout={250}
							classNames={{ ...animationStyles }}
						>
							<div ref={listRef} className={styles.viewContainer}>
								{shouldDisplayList() ? <ItemsListContainer /> : <SkeletonLoader />}
							</div>
						</CSSTransition>
					</div>
					{isEmptyState && <EmptyInventory />}
					{isEmptyFiltersState && <EmptyFilterResults />}
				</>
			)}
		</div>
	)
}

const mapStateToProps: TProps["State"] = (state) => {
	const hadInitialFetch = typeof inventorySelectors.getLastFetchTimestamp(state) !== "undefined"
	const isInventoryLoading = inventorySelectors.getLoadingStatus(inventoryActionSets.fetchInventory.request)(state)
	const areCollectionsLoading = collectionsSelectors.getLoadingStatus(collectionsActionSets.getCollections.request)(
		state
	)
	// console.log({ isInventoryLoading, areCollectionsLoading, hadInitialFetch })

	return {
		areAllFilterItemsSelected: inventorySelectors.areAllFilterItemsSelected(state),
		areAnyFilterItemsSelected: inventorySelectors.areAnyFilterItemsSelected(state),
		itemsCount: inventorySelectors.getInventoryItemsCount(state),
		filteredItemsCount: inventorySelectors.getFilteredItems(state).length,
		isLoading: isInventoryLoading || areCollectionsLoading,
		hadInitialFetch,
	}
}

const mapDispatchToProps: TProps["Dispatch"] = (dispatch) => {
	return {
		setFilterItemsSelected: (selectedStatus: boolean) => dispatch(setFilterItemsSelected(selectedStatus)),
	}
}

export const InventoryContainerConnected = connect(mapStateToProps, mapDispatchToProps)(InventoryContainer)
