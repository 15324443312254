import { useEffect, useState } from "react"
import { IAutocompleteEntity, ISearchResults } from "@services/autocomplete/definitions"

export interface ISuggestionsSearchResult {
	suggestions: IAutocompleteEntity[]
	hasExactMatch: boolean
}

export interface ISuggestionSearchHookProps {
	query: string
	sortFn?: (entities: IAutocompleteEntity[]) => IAutocompleteEntity[]
	searchFn: (query: string) => Promise<ISearchResults>
	getAllFn?: () => Promise<IAutocompleteEntity[]>
}

export default function useSuggestionsSearch(props: ISuggestionSearchHookProps): ISuggestionsSearchResult {
	const { searchFn, getAllFn, query } = props
	const sortFn = props.sortFn ? props.sortFn : (arr: IAutocompleteEntity[]) => arr

	const [suggestions, setSuggestions] = useState<IAutocompleteEntity[]>([])
	const [hasExactMatch, setExactMatch] = useState(false)

	const sanitizedQuery = query.trim()
	const isEmptyQuery = sanitizedQuery.length == 0

	useEffect(() => {
		// when query is changed, we request new results
		if (!isEmptyQuery) {
			searchFn(sanitizedQuery).then((result) => {
				setSuggestions(sortFn(result.entities))
				setExactMatch(result.hasExactMatch)
			})
		} else {
			if (typeof getAllFn !== "undefined") {
				getAllFn().then((results) => {
					setSuggestions(sortFn(results))
					setExactMatch(false)
				})
			} else {
				setSuggestions([])
				setExactMatch(false)
			}
		}
	}, [sanitizedQuery])

	return { suggestions, hasExactMatch }
}
