import React, { MutableRefObject, useMemo, useRef } from "react"
import * as tabsStyles from "@containers/WorkDetailsContainer/tabs/Tabs.module.scss"
import * as locationTabStyles from "@containers/WorkDetailsContainer/tabs/LocationTab/LocationTab.module.scss"
import TextInputField from "@components/FormInputs/TextInputField"
import DateInputField from "@components/FormInputs/DateInputField"
import { IFieldArrayDialogProps } from "@components/FieldArrayTable/types"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { temporaryAdmissionEntityValidationSchema } from "@containers/WorkDetailsContainer/form"
import classNames from "classnames"
import PriceInputField from "@components/FormInputs/PriceInputField"
import { ITemporaryAdmissionEntityForm } from "./types"
import ButtonWithDialog from "@components/ButtonWithDialog"
import warnConfirmDialog from "@components/WarnConfirmDialog"
import * as animationStyles from "@components/ButtonWithDialog/Animations.module.scss"
import { useTranslation } from "react-i18next"

type TTemporaryAdmissionDialogProps = IFieldArrayDialogProps<ITemporaryAdmissionEntityForm>

export const TemporaryAdmissionDialog: React.FC<TTemporaryAdmissionDialogProps> = (props) => {
	const { onSubmit, onDelete, onClose, entity, entityId } = props
	const { control, handleSubmit, watch } = useForm<ITemporaryAdmissionEntityForm>({
		resolver: yupResolver(temporaryAdmissionEntityValidationSchema),
		defaultValues: entity,
	})
	const isNewEntity = typeof entityId === "undefined"
	const dialogRef = useRef() as MutableRefObject<HTMLDivElement>
	const { t } = useTranslation()

	const deleteDialog = useMemo(
		() =>
			warnConfirmDialog({
				title: t("warnings.deleteTmpAdmissionTitle"),
				description: t("warnings.deleteTmpAdmissionDesc"),
				cancelText: t("common.cancel"),
				confirmText: t("common.delete"),
				onConfirm: () => {
					if (typeof entityId !== "undefined") {
						onDelete(entityId)
					}
					onClose()
				},
			}),
		[t, entityId, onDelete, onClose]
	)

	return (
		<div className={classNames(tabsStyles.dialogWrapper, locationTabStyles.dialogWrapper)} ref={dialogRef}>
			<header>{isNewEntity ? "New temporary admission" : "Edit temporary admission"}</header>
			<section>
				<TextInputField label="Import #" control={control} name="importId" className={locationTabStyles.inputWrapper} />
				<TextInputField label="Export #" control={control} name="exportId" className={locationTabStyles.inputWrapper} />
				<DateInputField
					label="Entry date"
					control={control}
					name="entryDate"
					dateFormat="MMM d, yyyy"
					calendarStartDay={1}
					className={classNames(tabsStyles.inputWrapper, tabsStyles.narrow, locationTabStyles.inputWrapper)}
				/>
				<DateInputField
					label="Return date"
					control={control}
					name="returnDate"
					dateFormat="MMM d, yyyy"
					calendarStartDay={1}
					className={classNames(tabsStyles.inputWrapper, tabsStyles.narrow, locationTabStyles.inputWrapper)}
				/>
				<PriceInputField
					label="Declared value"
					control={control}
					name="declaredValue"
					currencyName="declaredValueCurrency"
					watch={watch}
					className={locationTabStyles.inputWrapper}
				/>
				<PriceInputField
					label="Import duty"
					control={control}
					name="importDuty"
					currencyName="importDutyCurrency"
					watch={watch}
					className={locationTabStyles.inputWrapper}
				/>
			</section>
			<section className={locationTabStyles.actions}>
				{!isNewEntity && typeof entityId !== "undefined" && (
					<ButtonWithDialog
						dialogNode={deleteDialog}
						transitionClasses={animationStyles}
						button={(props) => (
							<button className={locationTabStyles.deleteBtn} {...props}>
								Delete
							</button>
						)}
					/>
				)}
				<button onClick={() => onClose()} className={locationTabStyles.closeBtn}>
					Cancel
				</button>
				<button
					onClick={handleSubmit((entity) => {
						onSubmit(entity, entityId)
						onClose()
					})}
					className={locationTabStyles.saveBtn}
				>
					{isNewEntity ? "Add" : "Save"}
				</button>
			</section>
		</div>
	)
}
