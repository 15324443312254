import startOfDay from "date-fns/startOfDay"
import { IFieldArrayTableMappers } from "@components/FieldArrayTable"
import { IDetailsForm } from "@containers/WorkDetailsContainer/types"
import formatISO from "date-fns/formatISO"
import { ILocationEntityForm } from "./types"

export const getDefaultLocationEntity = (): ILocationEntityForm => ({
	name: "",
	details: "",
	notes: "",
	date: startOfDay(new Date()), // we want "today" to be default date for new records
})

export const locationHistoryMappings: IFieldArrayTableMappers<
	IDetailsForm,
	"fields.locationHistory",
	ILocationEntityForm
> = {
	toDialogEntity: (entity) => {
		return {
			...entity,
			date: !entity.date ? undefined : new Date(entity.date),
		}
	},
	toEntity: (dialogEntity) => {
		return { ...dialogEntity, date: dialogEntity.date && formatISO(new Date(dialogEntity.date)) }
	},
}

export { sortLocationEntities } from "@utilities/workProperties/locationEntities"
