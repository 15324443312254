import { useCallback, useEffect, useState } from "react"

/**
 * Simple hook for detecting `onmousemove`
 * Here, we also check that actual mouse positing was changed (https://transitory.technology/mouse-move-in-safari/)
 * @param target
 * @param handler
 */
export function useMouseMove(target: HTMLElement, handler: (event: MouseEvent) => void): void {
	const [lastClientX, setLastClientX] = useState<number | undefined>(undefined)
	const [lastClientY, setLastClientY] = useState<number | undefined>(undefined)

	const handleMouseMove = useCallback(
		(event: MouseEvent) => {
			if (event.clientX === lastClientX && event.clientY === lastClientY) {
				return
			}

			handler(event)
			setLastClientX(event.clientX)
			setLastClientY(event.clientY)
		},
		[handler, lastClientX, lastClientY]
	)

	useEffect(() => {
		target?.addEventListener("mousemove", handleMouseMove)

		return () => target?.removeEventListener("mousemove", handleMouseMove)
	}, [handler])
}
