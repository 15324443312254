import React from "react"
import Image from "@components/Image"
import * as styles from "./ThumbnailImage.module.scss"
import { ReactComponent as DefaultImageIcon } from "@icons/DefaultImageIcon.svg"

interface IThumbnailImageProps {
	src?: string
	alt: string
	width: number | string
	height: number | string
	className: string
	emptyImageWidth: number | string
}

/**
 * Basically a wrapper over `Image` component with fallback when there is thumbnail
 */
export const ThumbnailImage: React.FC<IThumbnailImageProps> = (props) => {
	const { src, alt, width, height, className, emptyImageWidth } = props

	return src ? (
		<Image src={src} alt={alt} width={width} height={height} className={className} key={src} />
	) : (
		<div className={className} aria-label={alt}>
			<div className={styles.emptyImage} style={{ width, height }}>
				<DefaultImageIcon style={{ width: emptyImageWidth }} />
			</div>
		</div>
	)
}
