import { Currency, TCurrencyStrings } from "@typed/entities"
import { getCurrencySymbol } from "@utilities/currency/getCurrency"
import { formatCount } from "@utilities/strings/formatting"

/**
 * Unified price is a string of a format "42.00 USD"
 * Here we have tools to deal with such format
 */

type TUnpackedPrice = {
	value: number
	currency: TCurrencyStrings
}

export function packPrice(value?: number, currency?: TCurrencyStrings): string | null {
	if (typeof value !== "undefined") {
		return `${Number(value).toFixed(2)} ${currency}`
	} else {
		return null
	}
}

export function unpackPrice(packed: string): TUnpackedPrice | null {
	const splitted = packed.split(" ")
	if (splitted.length !== 2) {
		return null
	}

	const [valueStr, currencyStr] = splitted
	const value = parseFloat(valueStr)
	if (isNaN(value)) {
		return null
	}

	// this one seems unsafe, but we later check that such currency actually exsits
	const currency: TCurrencyStrings = currencyStr as TCurrencyStrings
	if (!(currency in Currency)) {
		return null
	}

	return { value, currency }
}

/**
 * Shows price in a format "$320,433.43"
 * @param unpackedPrice
 */
export function formatPrice(unpackedPrice: TUnpackedPrice | null | undefined): string | null {
	if (!unpackedPrice) {
		return null
	}
	const { value, currency } = unpackedPrice

	return `${getCurrencySymbol(currency)}${formatCount(value)}`
}
