import React, { ForwardedRef, MutableRefObject, useMemo, useRef, useState } from "react"
import { usePopper } from "react-popper"
import { Popover, Transition } from "@headlessui/react"
import * as styles from "@containers/PreviewContainer/AddCollectionPopover/AddCollectionPopover.module.scss"
import { TransitionClasses } from "@headlessui/react/dist/components/transitions/transition"
import { Placement } from "@popperjs/core"

export type TPopoverNode = React.FC<{
	closePopover: () => void
	isShown: boolean
}>

interface IButtonProps {
	ref: ForwardedRef<HTMLButtonElement>
}

interface IButtonWithPopoverProps {
	button: React.FC<IButtonProps>
	popoverClassName: string
	popoverNode: TPopoverNode
	transitionClasses: TransitionClasses
	placement?: Placement
	offset?: [number, number]
}

/**
 * While having a button with some appearing popup is quite demanded
 * @param props
 * @constructor
 */
export const ButtonWithPopover: React.FC<IButtonWithPopoverProps> = (props) => {
	const { popoverNode, transitionClasses, placement, offset, button } = props

	const popperElRef = useRef() as MutableRefObject<HTMLDivElement>
	const referenceElement = useRef() as MutableRefObject<HTMLButtonElement>
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
	const [isShown, setIsShown] = useState(false)

	// we use popper to set position
	const { styles: currentStyles, attributes: currentAttributes } = usePopper(referenceElement.current, popperElement, {
		placement: placement || "auto",
		...(typeof offset !== "undefined" && {
			modifiers: [
				{
					name: "offset",
					options: { offset },
				},
				{
					name: "eventListeners",
					options: {
						scroll: false,
						resize: true,
					},
				},
			],
		}),
	})

	// memorizing not to create forwardRef on every rerender
	const ButtonWithRef = useMemo(() => {
		return React.forwardRef<HTMLButtonElement>((props, ref) => {
			return button({ ref, ...props })
		})
	}, [button])

	return (
		<Popover as={React.Fragment}>
			{({ open }) => (
				<>
					<Popover.Button as={ButtonWithRef} ref={referenceElement} />

					<div ref={popperElRef} className={styles.popover} style={currentStyles.popper} {...currentAttributes.popper}>
						<Transition
							as={React.Fragment}
							show={open}
							beforeEnter={() => setPopperElement(popperElRef.current)}
							afterEnter={() => setIsShown(true)}
							beforeLeave={() => setIsShown(false)}
							afterLeave={() => setPopperElement(null)}
							{...transitionClasses}
						>
							<Popover.Panel static>
								{({ close }) =>
									// inserting passed component
									popoverNode({ isShown, closePopover: close })
								}
							</Popover.Panel>
						</Transition>
					</div>
				</>
			)}
		</Popover>
	)
}
