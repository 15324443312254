import React, { MutableRefObject, useEffect, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group"
import classNames from "classnames"
import * as styles from "./NestedList.module.scss"
import * as listStyles from "./List.module.scss"
import { IGenericItemFCProps, TBasicListItem, TGenericItemWithClickFC } from "./types"
import useKeyDown from "@enhancers/useKeyDown"
import useKeyboardNavigation from "@enhancers/useKeyboardNavigation"

type TListItemWithLink = {
	linkToList?: string
}

// in NestedList, we also tell whether current list located in head list, or in a child one
interface IItemFCProps<TListItem> extends IGenericItemFCProps<TListItem> {
	isHead: boolean
}

export type TItemFC<TListItem extends TBasicListItem> = React.FC<IItemFCProps<TListItem>>

interface IListProps<TListItem extends TBasicListItem> {
	lists: Record<string, TListItem[]>
	onItemClicked: (path: TListItem[]) => void
	itemContents: TItemFC<TListItem>
	headerContents: TGenericItemWithClickFC<TListItem>
	className?: string
}

function applyFocusOnButton(element: HTMLElement): void {
	const firstButtonElement = element.getElementsByTagName("button")[0]
	firstButtonElement?.focus()
}

export function NestedList<TListItem extends TBasicListItem>(props: IListProps<TListItem>): JSX.Element {
	const { lists, itemContents, headerContents, onItemClicked, className } = props

	const listsAsArray = Object.entries(lists)
	const [activeListId, setActiveListId] = useState("head")
	const [headItem, setHeadItem] = useState<TListItem | undefined>(undefined)
	// path of nested lists, from left to right
	const [currentPath, setCurrentPath] = useState<TListItem[]>([])

	const headListRef = useRef() as MutableRefObject<HTMLDivElement>
	const nestedListRef = useRef() as MutableRefObject<HTMLDivElement>
	const wrapperRef = useRef() as MutableRefObject<HTMLDivElement>

	const [activeNavState, setActiveNavState] = useKeyboardNavigation(lists[activeListId], wrapperRef.current)
	const [wrapperHeight, setWrapperHeight] = useState(0)
	useEffect(() => {
		setWrapperHeight(headListRef.current.clientHeight)
	}, [])

	const getItemIdxById = (itemId: string) => lists[activeListId].findIndex((item) => item.id == itemId)
	const getItemIdFromElement = (el: HTMLElement) => el.closest("[data-item-id]")?.getAttribute("data-item-id")

	const prevListHandler = () => {
		setActiveListId("head")
		setCurrentPath((path) => path.slice(0, -1))
		setHeadItem(undefined)
	}

	const itemActionHandler = (item: TListItem) => {
		const itemWithLink = item as TListItemWithLink
		if (typeof itemWithLink.linkToList !== "undefined") {
			setHeadItem(item)
			setCurrentPath((path) => path.concat(item))
			setActiveListId(itemWithLink.linkToList)
		} else {
			onItemClicked(currentPath.concat(item))
		}
	}

	const globalClickHandler = (event: React.MouseEvent<HTMLElement>) => {
		const itemId = getItemIdFromElement(event.target as HTMLElement)

		if (itemId) {
			const itemIdx = getItemIdxById(itemId)
			const item = lists[activeListId][itemIdx]
			itemActionHandler(item)
		}
	}

	const globalMouseMoveHandler = (event: React.MouseEvent<HTMLElement>) => {
		const itemId = getItemIdFromElement(event.target as HTMLElement)
		if (itemId) {
			setActiveNavState({
				idx: getItemIdxById(itemId),
				reason: "mousemove",
			})
		}
	}

	useKeyDown("ArrowRight", () => itemActionHandler(lists[activeListId][activeNavState.idx]), wrapperRef.current)
	useKeyDown("ArrowLeft", () => prevListHandler(), wrapperRef.current)
	useKeyDown("Enter", () => itemActionHandler(lists[activeListId][activeNavState.idx]))

	useEffect(() => {
		applyFocusOnButton(headListRef.current)
	}, [])

	return (
		<div
			className={classNames(styles.wrapper, {
				[`${className}`]: !!className,
			})}
			style={{ height: wrapperHeight }}
			onClick={globalClickHandler}
			onMouseMove={globalMouseMoveHandler}
			ref={wrapperRef}
		>
			{listsAsArray.map(([listId, listItems]) => {
				const isHeadList = listId === "head"
				const ref = isHeadList ? headListRef : nestedListRef
				const listClassName = isHeadList ? styles.headMenu : styles.nestedMenu
				const transitions = {
					enter: styles.enter,
					enterActive: styles.enterActive,
					exit: styles.exit,
					exitActive: styles.exitActive,
				}

				return (
					<CSSTransition
						in={activeListId === listId}
						timeout={500}
						classNames={transitions}
						unmountOnExit
						key={listId}
						nodeRef={ref}
						onEntering={() => setWrapperHeight(ref.current.clientHeight)}
						onEntered={() => applyFocusOnButton(ref.current)}
					>
						<div className={classNames(styles.menu, listClassName)} ref={ref}>
							{!isHeadList &&
								typeof headItem !== "undefined" &&
								headerContents({
									item: headItem,
									onClick: () => prevListHandler(),
								})}
							<ul className={listStyles.ulContainer}>
								{listItems.map((item, index) => {
									return (
										<li key={item.id} data-active={activeNavState.idx == index} data-item-id={item.id}>
											{itemContents({
												item,
												isHead: isHeadList,
											})}
										</li>
									)
								})}
							</ul>
						</div>
					</CSSTransition>
				)
			})}
		</div>
	)
}
