import React from "react"
import Header from "@components/Header"
import Footer from "@components/Footer"

export interface IPageLayoutProps {
	className?: string
	withFooter?: boolean
}

export const PageLayout: React.FC<IPageLayoutProps> = (props) => {
	const { className, withFooter } = props
	return (
		<div className={className}>
			<Header />
			{props.children}
			{withFooter ? <Footer /> : ""}
		</div>
	)
}
