import React from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { signupRequest } from "@store/ducks/auth/actions"
import { ISignUpDetails } from "@typed/entities"
import PageLayout from "@layouts/PageLayout"
import Loader from "@components/Loader"
import ErrorMessage from "@components/ErrorMessage"
import RegisterForm from "@components/RegisterForm"
import { TRootState } from "@store/rootReducer"
import * as authSelectors from "@store/ducks/auth/selectors"
import * as authActionSets from "@store/ducks/auth/actionSets"
import * as styles from "./Register.module.scss"
import classNames from "classnames"

interface IReduxProps {
	signupRequest: (details: ISignUpDetails) => void
}

interface IRegisterProps {
	errorMsg: string | undefined
	isLoading: boolean
}

type TComponentProps = IRegisterProps & IReduxProps & RouteComponentProps

const Register: React.FC<TComponentProps> = (props) => {
	const { errorMsg, isLoading } = props

	const performRegistration = (details: ISignUpDetails) => {
		props.signupRequest(details)
	}

	return (
		<PageLayout>
			<div className="container d-flex justify-content-center">
				<div className="authForm fade-in-bottom">
					<h1 className={classNames("display-4", styles.signUpHeading)}>Sign Up</h1>
					<RegisterForm submit={performRegistration} />
					{errorMsg && <ErrorMessage message={errorMsg} />}
					{isLoading && <Loader />}
				</div>
			</div>
		</PageLayout>
	)
}

function mapStateToProps(state: TRootState): IRegisterProps {
	return {
		isLoading: authSelectors.getLoadingStatus(authActionSets.signup.request)(state),
		errorMsg: authSelectors.getErrorMsg(authActionSets.signup.request)(state),
	}
}

export const RegisterConnected = connect(mapStateToProps, { signupRequest })(withRouter(Register))
