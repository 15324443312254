import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux"
import { TAnalyticsEventCreator, TAnalyticsEventEmitter } from "@services/analytics/definitions"
import createMappedDebounce from "@utilities/eventLoop/createMappedDebounce"

/**
 * Basic Redux middleware that emits analytics event based on actions and current redux state
 * Each event is wrapped over debounce function for it's particular `name`
 *
 * @param analyticsEventCreator - function that derives analytics event from redux action and state
 * @param analyticsEventEmitter - function that emits analytics event
 * @param eventDelay - delay to be used within debounce
 * @param debug
 */
export default function createAnalyticsMiddleware<S, K extends AnyAction>(
	analyticsEventCreator: TAnalyticsEventCreator<S, K>,
	analyticsEventEmitter: TAnalyticsEventEmitter,
	eventDelay = 500,
	debug = false
): Middleware {
	const debounce = createMappedDebounce(eventDelay)
	return (middlewareAPI: MiddlewareAPI) => (next: Dispatch) => (action) => {
		const returnValue = next(action)

		const derivedAnalyticsEvent = analyticsEventCreator(middlewareAPI.getState(), action)
		if (typeof derivedAnalyticsEvent !== "undefined") {
			debounce(
				derivedAnalyticsEvent.name,
				() => {
					if (debug) {
						console.log(`[createAnalyticsMiddleware] Emitting event ${derivedAnalyticsEvent.name}`, {
							payload: { ...derivedAnalyticsEvent.payload },
							reduxAction: action,
						})
					}

					analyticsEventEmitter(derivedAnalyticsEvent)
				},
				derivedAnalyticsEvent.delay
			)
		}

		return returnValue
	}
}
