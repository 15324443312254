import React from "react"
import ArtworkImagesControlled from "@containers/WorkDetailsContainer/ArtworkImagesControlled"
import TextInputField from "@components/FormInputs/TextInputField"
import SelectInputField from "@components/FormInputs/SelectInputField"
import ToggleInputField from "@components/FormInputs/ToggleInputField"
import * as styles from "./Tabs.module.scss"
import { IImageItem } from "@components/ArtworkImages"
import { ITabProps } from "@containers/WorkDetailsContainer/types"
import MagicFill from "@containers/WorkDetailsContainer/MagicFill"
import NumericInputField from "@components/FormInputs/NumericInputField"
import SuggestionTextInputField from "@components/FormInputs/SuggestionTextInputField"
import { getOwnershipDistanceOptions } from "@typed/entities/OwnershipDistance"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import CheckboxInputField from "@components/FormInputs/CheckboxInputField"
import { IOrganisation } from "@typed/api/responses/Organisation"

interface IOverviewTabProps extends ITabProps {
	images: IImageItem[]
	updateImagePaths: (updated: IImageItem[]) => void
	markImageAsUploaded: (imageName: string) => void
	onMagicFillSubmit: (contents: string) => void
	artworkId: string
	canEditImages: boolean
	isDraggingFile: boolean
	isNewWork?: boolean
	relatedOrganisation?: IOrganisation
	isOwnedWork: boolean
}

const magicPastePlaceholder = `Damien Hirst
Kindness (Diamond Dust), 2011
Prints and multiples, Silkscreen with Diamond Dust
91.2 x 91.4 cm.`

const OverviewTab: React.FC<IOverviewTabProps> = (props) => {
	const {
		images,
		updateImagePaths,
		markImageAsUploaded,
		artworkId,
		canEditImages,
		isDraggingFile,
		onMagicFillSubmit,
		relatedOrganisation,
		isOwnedWork,
	} = props
	const { control, watch, setValue } = props
	const { t } = useTranslation()

	return (
		<div className={styles.tab}>
			<ArtworkImagesControlled
				images={images}
				onImagesUpdate={updateImagePaths}
				markImageAsUploaded={markImageAsUploaded}
				artworkId={artworkId}
				readonly={!canEditImages}
				shouldHighlight={isDraggingFile}
			/>
			<h2 className={styles.sectionHeader}>{t("artwork.detailsSectionTitle")}</h2>
			<section className={styles.section}>
				<MagicFill
					onSubmit={(c) => onMagicFillSubmit(c)}
					className={styles.magicPasteWrapper}
					placeholder={magicPastePlaceholder}
					open={watch("temporary.isMagicPasteOpened")}
					setOpen={(state) => setValue("temporary.isMagicPasteOpened", state)}
					contents={watch("temporary.magicPasteContents")}
					setContents={(contents) => setValue("temporary.magicPasteContents", contents)}
				/>
				<SuggestionTextInputField
					label={t("artwork.artist")}
					control={control}
					autocompleteUnitKey="artists"
					name="fields.artist"
					className={styles.inputWrapper}
				/>
				<TextInputField
					label={t("artwork.title")}
					control={control}
					name="fields.title"
					className={styles.inputWrapper}
				/>
				<TextInputField
					label={t("artwork.year")}
					control={control}
					name="fields.year"
					className={classNames(styles.inputWrapper, styles.narrow)}
				/>
				<TextInputField
					label={t("artwork.medium")}
					control={control}
					name="fields.medium"
					className={styles.inputWrapper}
				/>
				<SuggestionTextInputField
					label={t("artwork.typeOfWork")}
					control={control}
					name="fields.workType"
					autocompleteUnitKey="workType"
					showAll
					className={styles.inputWrapper}
				/>
				<div className={classNames(styles.inputWrapper, styles.dimensionsWrapper)}>
					<NumericInputField
						label={t("artwork.height")}
						control={control}
						thousandSeparator={false}
						prefix=""
						name="fields.dimHeight"
						className={styles.dimensionsInput}
					/>
					<NumericInputField
						label={t("artwork.width")}
						control={control}
						thousandSeparator={false}
						prefix=""
						name="fields.dimWidth"
						className={styles.dimensionsInput}
					/>
					<NumericInputField
						label={t("artwork.depth")}
						control={control}
						thousandSeparator={false}
						prefix=""
						name="fields.dimDepth"
						className={styles.dimensionsInput}
					/>
					<ToggleInputField
						control={control}
						name="temporary.dimUnit"
						options={[
							{
								value: "inch",
								label: "in",
							},
							{
								value: "cm",
								label: "cm",
							},
						]}
						className={classNames(styles.dimensionsInput, styles.unitToggle)}
					/>
				</div>
			</section>
			<h2 className={styles.sectionHeader}>{t("artwork.accessSectionTitle")}</h2>
			<section className={styles.section}>
				<TextInputField
					label={t("artwork.source")}
					control={control}
					name="fields.source"
					className={styles.inputWrapper}
				/>
				<SelectInputField
					label={t("artwork.connectionToWork")}
					control={control}
					name="fields.accessDistance"
					options={getOwnershipDistanceOptions(t)}
					className={styles.inputWrapper}
				/>
			</section>
			{/* We only this section if user owns an artwork and if there is at least one related organisation */}
			{/* Therefore user can share and share this artwork with organisation */}
			{isOwnedWork && relatedOrganisation && (
				<>
					<h2 className={styles.sectionHeader}>{t("artwork.privacySectionTitle")}</h2>
					<section className={styles.section}>
						<CheckboxInputField
							id="shared-with-organisation"
							control={control}
							name="fields.sharedWithOrganisation"
							label={t("artwork.shareArtworkOption", {
								organisationTitle: relatedOrganisation.name,
							})}
						/>
					</section>
				</>
			)}
		</div>
	)
}

export default OverviewTab
