import React from "react"

interface IComponentProps {
	verified: boolean
}

export const VerifiedBadge: React.FC<IComponentProps> = (props) => {
	return props.verified ? (
		<span>
			<img src="/graphics/verified.png" width="25px" alt="Verified property" />
		</span>
	) : (
		<div></div>
	)
}
