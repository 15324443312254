import React, { useEffect } from "react"
import { connect } from "react-redux"
import { fetchInventoryRequest, applyFilterRequest } from "@store/ducks/inventory/actions"
import PageLayout from "@layouts/PageLayout"
import * as styles from "./InventoryNew.module.scss"
import InventoryLayout from "@layouts/InventoryLayout"
import { TSearchState } from "@store/ducks/inventory"

interface IReduxProps {
	fetchInventoryRequest: () => void
	applyFilterRequest: (params: TSearchState) => void
}

const InventoryNew: React.FC<IReduxProps> = (props) => {
	useEffect(() => {
		props.fetchInventoryRequest()
	}, [])

	return (
		<PageLayout className={styles.inventoryVerticalLayout}>
			<InventoryLayout />
		</PageLayout>
	)
}

export const InventoryNewConnected = connect(null, { fetchInventoryRequest, applyFilterRequest })(InventoryNew)
