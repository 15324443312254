import { Reducer } from "redux"
import { StatusesActionTypes, TStatusesAction, TStatusesActionsSets } from "./types"
import { defaultRequestState, commonReducerCreator, TStateWithRequestStateGroup } from "@store/shared/requestState"
import * as actionSets from "./actionSets"
import { IStatus } from "@typed/api/responses/Workflows"

export interface IStatusesStoreState extends TStateWithRequestStateGroup<TStatusesActionsSets> {
	readonly statuses: IStatus[] | undefined
}

export const initStatusesStoreState: IStatusesStoreState = {
	statuses: undefined,
	requests: defaultRequestState(actionSets),
}

const commonReducer = commonReducerCreator<IStatusesStoreState, TStatusesAction>(actionSets)

export const StatusesReducer: Reducer<IStatusesStoreState, TStatusesAction> = (
	state = initStatusesStoreState,
	action: TStatusesAction
) => {
	switch (action.type) {
		case StatusesActionTypes.FetchStatusesSuccess:
			return {
				...commonReducer(state, action),
				statuses: action.payload.statuses,
			}
		default:
			return commonReducer(state, action)
	}
}
