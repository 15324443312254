import { FieldValues } from "react-hook-form/dist/types/fields"
import { Controller } from "react-hook-form"
import * as styles from "./FormInputs.module.scss"
import React from "react"
import { IHookFormInput, TOption } from "@components/FormInputs/types"
import classNames from "classnames"

interface IToggleInputFieldProps<TFieldValues extends FieldValues> extends IHookFormInput<TFieldValues> {
	options: [TOption, TOption]
}

const getNextOption = (options: TOption[], currentIndex: number) => {
	if (currentIndex == options.length - 1) {
		return options[0]
	}

	return options[currentIndex + 1]
}

function ToggleInputField<TFieldValues extends FieldValues>(props: IToggleInputFieldProps<TFieldValues>): JSX.Element {
	const { control, name, options, className } = props

	return (
		<Controller
			render={({ field }) => {
				const currentOptionIndex = Math.max(
					options.findIndex((option) => option.value === field.value),
					0
				)

				return (
					<div
						className={classNames(styles.inputWrapper, {
							[`${className}`]: !!className,
						})}
					>
						<button
							onClick={() => {
								field.onChange(getNextOption(options, currentOptionIndex).value)
							}}
							className={styles.toggleButton}
						>
							{options[currentOptionIndex].label}
						</button>
					</div>
				)
			}}
			name={name}
			control={control}
		/>
	)
}

export default ToggleInputField
