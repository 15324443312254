import React, { useCallback, useState } from "react"
import API from "@services/api"
import PageLayout from "@layouts/PageLayout"
import Loader from "@components/Loader"
import ErrorMessage from "@components/ErrorMessage"
import { useDropzone } from "react-dropzone"
import filesize from "filesize"
import { StaticRoutes } from "@routes"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { emitAnalyticsEvent } from "@store/ducks/analytics/actions"
import { TAnalyticsEventEmitter } from "@services/analytics/definitions"
import { joinStrings } from "@utilities/strings/formatting"
import { useTranslation } from "react-i18next"

// eslint-disable-next-line @typescript-eslint/naming-convention
const MAX_FILE_SIZE = 100 * 1024 ** 2 // 100 MB
// eslint-disable-next-line @typescript-eslint/naming-convention
const DISCORD_SUPPORT_WEBHOOK =
	"https://discord.com/api/webhooks/867713162346496041/5jfZMShHlVJT35q2rjEJ7qcBoe-vssrlj4TXfkmAlXY__bEGIsoP3KMuUIH9Ec2bKPu9"

const discordWebhook = async (userName: string, identityId: string, fileName: string) => {
	return fetch(DISCORD_SUPPORT_WEBHOOK, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			username: "Captain Hook",
			color: "14177041",
			content: "New inventory import",
			avatar_url:
				"https://www.orellana.io/authors/admin/avatar_hu421cc313a7a5b46252fc1bd929536ba5_59569_250x250_fill_q90_lanczos_center.jpg",
			embeds: [
				{
					title: fileName,
					color: 53380,
					timestamp: "2021-07-21T00:00:00+00:00",
					author: {
						name: userName,
						url: "https://discordapp.com",
						icon_url: "https://pics.freeicons.io/uploads/icons/png/17817709021579252566-512.png",
					},
					footer: {
						text: identityId,
					},
				},
			],
		}),
	})
}

type TReduxDispatchProps = {
	emitAnalyticsEvent: TAnalyticsEventEmitter
}

const ImportFile: React.FC<TReduxDispatchProps> = (props) => {
	const { emitAnalyticsEvent } = props
	const { t, ready } = useTranslation(undefined, { useSuspense: false })

	const [isLoading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | undefined>("")
	const [done, setDone] = useState(false)
	const [currentFile, setCurrentFile] = useState<File | undefined>(undefined)

	const clearState = () => {
		setDone(false)
		setErrorMessage(undefined)
		setLoading(false)
	}

	const onDrop = useCallback((acceptedFiles: File[]) => {
		const newAcceptedFile = acceptedFiles[0]
		if (newAcceptedFile) {
			clearState()
			setCurrentFile(newAcceptedFile)
		}
	}, [])

	const { getRootProps, getInputProps } = useDropzone({ onDrop })

	const submit = async () => {
		if (!currentFile) {
			return
		}

		clearState()

		if (currentFile.size > MAX_FILE_SIZE) {
			setErrorMessage(
				t("importFiles.fileSizeError", {
					maxFileSize: filesize(MAX_FILE_SIZE),
				})
			)
			return
		}

		setLoading(true)

		const uploadPath = `bulk-import/${currentFile.name}`
		const { status } = await API.amplify.uploadFile({
			file: currentFile,
			uploadPath: uploadPath,
			level: "private",
			contentType: currentFile.type,
			updateProgress: (progress) => setLoading(progress !== 1),
		})

		setDone(status)
		if (!status) {
			setErrorMessage(t("common.unknownError"))
			return
		}

		const authenticatedUser = await API.amplify.getAuthenticatedUser()
		const cognitoIdentity = await API.amplify.getCognitoIdentity()

		await discordWebhook(
			joinStrings([authenticatedUser.attributes.name, authenticatedUser.attributes.family_name], " "),
			cognitoIdentity?.identityId,
			uploadPath
		)
		emitAnalyticsEvent({
			name: "Import Works",
			payload: {
				"Import Type": "Uploaded File",
			},
		})
	}

	if (!ready) {
		return <></>
	}

	return (
		<PageLayout>
			<div className="container pt-5">
				<h1 className="display-4 mb-3">{t("importFiles.importFromFileTitle")}</h1>
				<div className="row pt-3">
					<div className="col-6">
						{done && !errorMessage ? (
							<>
								<div className="alert alert-success mb-5" role="alert">
									<div className="font-weight-bold">{t("importFiles.fileImportProcessing")}</div>
									<div className="mt-3">{t("importFiles.fileImportFeedback")}</div>
								</div>
								<Link to={StaticRoutes.UserInventory} className="btn btn-dark">
									{t("common.close")}
								</Link>
							</>
						) : (
							<>
								<p>{t("importFiles.importFromFileDesc")}</p>
								<section>
									<ul className="list-inline">
										<li className="list-inline-item">
											<div {...getRootProps({ className: "dropzone" })}>
												<input {...getInputProps()} />
												<button className="btn btn-outline-dark" disabled={isLoading}>
													{t("importFiles.chooseFile")}
												</button>
											</div>
										</li>
										{currentFile && (
											<>
												<li className="list-inline-item">{currentFile.name}</li>
												<li className="list-inline-item text-secondary">{filesize(currentFile.size)}</li>
											</>
										)}
									</ul>
								</section>
								<section>
									<button
										type="submit"
										className="btn btn-dark"
										onClick={() => submit()}
										disabled={isLoading || !currentFile}
									>
										{t("importFiles.import")}
									</button>
									{isLoading && <Loader />}
									{errorMessage && <ErrorMessage message={errorMessage} />}
									{done && !errorMessage && (
										<div className="alert alert-success mt-5" role="alert">
											{t("importFiles.importSuccess")}
										</div>
									)}
								</section>
							</>
						)}
					</div>
				</div>
			</div>
		</PageLayout>
	)
}

export default connect(undefined, { emitAnalyticsEvent })(ImportFile)
