import { FieldValues } from "react-hook-form/dist/types/fields"
import { Controller } from "react-hook-form"
import * as selectOverrideStyles from "./SelectInputField.module.scss"
import React, { useState } from "react"
import { IHookFormInput, TOption } from "@components/FormInputs/types"
import Select from "react-select"
import { FormInputWrapper } from "@components/FormInputs/FormInputWrapper"
import { useTranslation } from "react-i18next"

interface ISelectInputFieldProps<TFieldValues extends FieldValues> extends IHookFormInput<TFieldValues> {
	options: TOption[]
}

interface ISelectInputFieldLabelledProps<TFieldValues extends FieldValues>
	extends ISelectInputFieldProps<TFieldValues> {
	label: string
}

export function SelectInputFieldController<TFieldValues extends FieldValues>(
	props: ISelectInputFieldProps<TFieldValues>
): JSX.Element {
	const { control, name, options, disabled } = props
	const { t } = useTranslation()
	const [menuIsOpen, setMenuIsOpen] = useState(false)

	return (
		<Controller
			render={({ field }) => (
				<Select
					placeholder={t("artwork.selectPlaceholder")}
					options={options}
					value={options.find((option) => option.value == field.value)}
					onChange={(newValue: TOption | null) => newValue && field.onChange(newValue.value)}
					className={selectOverrideStyles.selectWrapper}
					classNamePrefix="selectInputField"
					isSearchable={false}
					isDisabled={disabled}
					onKeyDown={(event) => {
						if (menuIsOpen) {
							event.stopPropagation()
						}
					}}
					onMenuOpen={() => setMenuIsOpen(true)}
					onMenuClose={() => setMenuIsOpen(false)}
				/>
			)}
			name={name}
			control={control}
		/>
	)
}

function SelectInputField<TFieldValues extends FieldValues>(
	props: ISelectInputFieldLabelledProps<TFieldValues>
): JSX.Element {
	const { label, control, name, options, className, disabled } = props

	return (
		<FormInputWrapper className={className} label={label}>
			<SelectInputFieldController control={control} name={name} options={options} disabled={disabled} />
		</FormInputWrapper>
	)
}

export default SelectInputField
