import * as autocompleteService from "@services/autocomplete"
import React from "react"
import { IAutocompleteEntity, ISearchResults, TResultFilterFn } from "@services/autocomplete/definitions"

/**
 * This enhancer binds (pure) Functional Components responsible for property selection with
 * global `autocompleteService`, which gives ability to access precalculated indexes for better performance
 */

// these functions should call `autocompleteService` or other similar service that provides search results
export interface IAutocompleteHandlerProps {
	searchFn: (query: string) => Promise<ISearchResults>
	getAllFn: () => Promise<IAutocompleteEntity[]>
}

export default function withAutocomplete<TComponentProps>(
	autocompleteUnitKey: string,
	component: React.FC<TComponentProps & IAutocompleteHandlerProps>,
	filter?: TResultFilterFn
): React.FC<TComponentProps> {
	const searchFn = (query: string) => autocompleteService.search(autocompleteUnitKey, query, filter)
	const getAllFn = () => autocompleteService.getAll(autocompleteUnitKey, filter)

	// using partial function application
	return (props: TComponentProps) =>
		component({
			...props,
			searchFn,
			getAllFn,
		})
}
