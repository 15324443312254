import React, { RefObject, useCallback, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { TransitionClasses } from "@headlessui/react/dist/components/transitions/transition"
import * as styles from "./ButtonWithDialog.module.scss"
import AsButton from "@components/AsButton"

export type TDialogNode = React.FC<{
	closeDialog: () => void
}>

interface IButtonWithDialogProps {
	dialogNode: TDialogNode
	transitionClasses: TransitionClasses
	button: React.FC<{
		ref: RefObject<HTMLButtonElement>
	}>
}

export const ButtonWithDialog: React.FC<IButtonWithDialogProps> = (props) => {
	const { dialogNode, transitionClasses, button } = props
	const [isOpen, setIsOpen] = useState(false)

	const handleButtonClick = useCallback(() => setIsOpen(true), [])

	return (
		<>
			<AsButton button={button} onClick={handleButtonClick} />
			<Transition appear show={isOpen}>
				<Dialog onClose={() => setIsOpen(false)} className={styles.dialog}>
					<Dialog.Overlay />
					<Transition.Child {...transitionClasses}>
						{dialogNode({
							closeDialog: () => setIsOpen(false),
						})}
					</Transition.Child>
				</Dialog>
			</Transition>
		</>
	)
}
