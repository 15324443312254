import { Dispatch, SetStateAction, useEffect, useState } from "react"
import useKeyDown from "@enhancers/useKeyDown"

type TKeyboardNavigationHookResult<S> = [S, Dispatch<SetStateAction<S>>]

interface IListNavigationState {
	idx: number
	reason: "initialisation" | "keydown" | "mousemove"
}

const defaultLisNavigationState: IListNavigationState = {
	idx: 0,
	reason: "initialisation",
}

/**
 * Simple hook that uses keyboard hooks in order to provide list navigation for lists
 * @param items
 * @param target
 */
export default function useKeyboardNavigation<T>(
	items: T[],
	target?: HTMLElement | Document | Window
): TKeyboardNavigationHookResult<IListNavigationState> {
	const [activeIdx, setActiveIdx] = useState<IListNavigationState>(defaultLisNavigationState)

	useEffect(() => {
		setActiveIdx(defaultLisNavigationState)
	}, [items.length])

	useKeyDown(
		"ArrowUp",
		() =>
			setActiveIdx((prev) => ({
				idx: Math.max(prev.idx - 1, 0),
				reason: "keydown",
			})),
		target
	)
	useKeyDown(
		"ArrowDown",
		() =>
			setActiveIdx((prev) => ({
				idx: Math.min(prev.idx + 1, items.length - 1),
				reason: "keydown",
			})),
		target
	)

	return [activeIdx, setActiveIdx]
}
