import React, { MutableRefObject, useRef } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { locationEntityValidationSchema } from "@containers/WorkDetailsContainer/form"
import useKeyDown from "@enhancers/useKeyDown"
import classNames from "classnames"
import * as tabsStyles from "@containers/WorkDetailsContainer/tabs/Tabs.module.scss"
import * as locationTabStyles from "@containers/WorkDetailsContainer/tabs/LocationTab/LocationTab.module.scss"
import SuggestionTextInputField from "@components/FormInputs/SuggestionTextInputField"
import TextInputField from "@components/FormInputs/TextInputField"
import DateInputField from "@components/FormInputs/DateInputField"
import { ILocationEntityForm } from "./types"
import { IFieldArrayDialogProps } from "@components/FieldArrayTable/types"

type TLocationDialogProps = IFieldArrayDialogProps<ILocationEntityForm>

export const LocationDialog: React.FC<TLocationDialogProps> = (props) => {
	const { onSubmit, onDelete, onClose, entity, entityId } = props
	const { control, handleSubmit } = useForm<ILocationEntityForm>({
		resolver: yupResolver(locationEntityValidationSchema),
		defaultValues: entity,
	})
	const isNewLocation = typeof entityId === "undefined"
	const dialogRef = useRef() as MutableRefObject<HTMLDivElement>
	useKeyDown("Escape", () => onClose(), dialogRef.current)

	return (
		<div className={classNames(tabsStyles.dialogWrapper, locationTabStyles.dialogWrapper)} ref={dialogRef}>
			<header>{isNewLocation ? "Add location" : "Edit location"}</header>
			<section>
				<SuggestionTextInputField
					label="Location"
					control={control}
					autocompleteUnitKey="locationNames"
					name="name"
					className={locationTabStyles.inputWrapper}
				/>
				<TextInputField
					label="Location details"
					control={control}
					name="details"
					className={locationTabStyles.inputWrapper}
				/>
				<TextInputField
					label="Location notes"
					control={control}
					name="notes"
					className={locationTabStyles.inputWrapper}
				/>
				<DateInputField
					label="Date"
					control={control}
					name="date"
					dateFormat="MMM d, yyyy"
					calendarStartDay={1}
					className={classNames(tabsStyles.inputWrapper, tabsStyles.narrow, locationTabStyles.inputWrapper)}
				/>
			</section>
			<section className={locationTabStyles.actions}>
				{!isNewLocation && typeof entityId !== "undefined" && (
					<button
						onClick={() => {
							onDelete(entityId)
							onClose()
						}}
						className={locationTabStyles.deleteBtn}
					>
						Delete
					</button>
				)}
				<button onClick={() => onClose()} className={locationTabStyles.closeBtn}>
					Cancel
				</button>
				<button
					onClick={handleSubmit((entity) => {
						onSubmit(entity, entityId)
						onClose()
					})}
					className={locationTabStyles.saveBtn}
				>
					{isNewLocation ? "Add location" : "Save"}
				</button>
			</section>
		</div>
	)
}
