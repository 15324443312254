import { AutocompleteIndex } from "@services/autocomplete/AutocompleteIndex"
import { IAutocompleteEntity, ISearchResults, TResultFilterFn } from "@services/autocomplete/definitions"
import { IItemToInsert } from "@services/search/defintions"

interface IPropsForAutocomplete<TState> {
	[autocompleteUnitKey: string]: {
		// tells how to select exact object from application state
		selector: (state: TState) => Record<string, unknown>
		// gives ability to omit some of keys
		// if value test fails (filter returns false), this value won't be added to search index
		baseFilter: (key: string, state: TState) => boolean
		// while object's keys are automatically index's keys, we can tell how to derive an actual value from a key
		deriveValue: (key: string, value: unknown) => string
		sort?: (leftKey: IAutocompleteEntity, rightKey: IAutocompleteEntity) => number
	}
}

/**
 * Given a particular application state, this class can build search indexes for various sub-states
 * in order to provide search
 */
export class AutocompleteService<TState> {
	private readonly indexes: Map<string, AutocompleteIndex>
	private readonly unitsForAutocomplete: IPropsForAutocomplete<TState>

	constructor(unitsForAutocomplete: IPropsForAutocomplete<TState>) {
		this.indexes = new Map()
		this.unitsForAutocomplete = unitsForAutocomplete

		for (const unitKey in this.unitsForAutocomplete) {
			if (Object.prototype.hasOwnProperty.call(this.unitsForAutocomplete, unitKey)) {
				this.indexes.set(unitKey, new AutocompleteIndex())
			}
		}
	}

	public async clear(): Promise<void> {
		for (const unitKey in this.unitsForAutocomplete) {
			if (Object.prototype.hasOwnProperty.call(this.unitsForAutocomplete, unitKey)) {
				const currentIndex = this.indexes.get(unitKey)
				if (typeof currentIndex !== "undefined") {
					await currentIndex.clear()
				}
			}
		}
	}

	/**
	 * Updates all indexes from new application state
	 * @param state
	 */
	public async update(state: TState): Promise<void> {
		for (const unitKey in this.unitsForAutocomplete) {
			if (Object.prototype.hasOwnProperty.call(this.unitsForAutocomplete, unitKey)) {
				const currentIndex = this.indexes.get(unitKey)
				if (typeof currentIndex !== "undefined") {
					const subState = this.unitsForAutocomplete[unitKey].selector(state)

					for (const itemKey in subState) {
						if (Object.prototype.hasOwnProperty.call(subState, itemKey)) {
							if (this.unitsForAutocomplete[unitKey].baseFilter(itemKey, state)) {
								const derivedValue = this.unitsForAutocomplete[unitKey].deriveValue(itemKey, subState[itemKey])
								await currentIndex.put(itemKey, derivedValue)
							}
						}
					}
				}
			}
		}
	}

	/**
	 * Current function allows to load additional data to autocomplete
	 * Note that filtering will be skipped in this case
	 * @param unitKey
	 * @param items
	 */
	public async insert(unitKey: string, items: IItemToInsert[]): Promise<void> {
		const currentIndex = this.indexes.get(unitKey)
		if (typeof currentIndex === "undefined") {
			throw `[AutocompleteService] No such unitKey: "${unitKey}"`
		}

		for (const item of items) {
			await currentIndex.put(item.id, item.value)
		}
	}

	public async search(autocompleteUnitKey: string, query: string, filter?: TResultFilterFn): Promise<ISearchResults> {
		const currentIndex = this.indexes.get(autocompleteUnitKey)
		if (typeof currentIndex === "undefined") {
			throw `[AutocompleteService] No unit with key "${autocompleteUnitKey}" found`
		}

		return currentIndex.search(query, filter, this.unitsForAutocomplete[autocompleteUnitKey].sort)
	}

	public async getAll(autocompleteUnitKey: string, filter?: TResultFilterFn): Promise<IAutocompleteEntity[]> {
		const currentIndex = this.indexes.get(autocompleteUnitKey)
		if (typeof currentIndex === "undefined") {
			throw `[AutocompleteService] No unit with key "${autocompleteUnitKey}" found`
		}

		return currentIndex.getAll(filter, this.unitsForAutocomplete[autocompleteUnitKey].sort)
	}
}
