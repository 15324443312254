import { Currency, TCurrencyStrings } from "@typed/entities"

const defaultCurrency = "USD"

export function isValidCurrency(currency?: TCurrencyStrings | "UNKNOWN"): boolean {
	return typeof currency !== "undefined" && currency !== "UNKNOWN" && currency in Currency
}

/**
 * This function will return provided currency, or default one if none or UNKNOWN is provided
 * @param initialCurrency
 */
export default function getCurrency(initialCurrency?: TCurrencyStrings | "UNKNOWN"): TCurrencyStrings {
	if (!isValidCurrency(initialCurrency)) {
		return defaultCurrency
	}

	return initialCurrency as TCurrencyStrings
}

export const getCurrencySymbol = (currency: TCurrencyStrings): string => {
	return Currency[currency].split(" ")[0]
}
