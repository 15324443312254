import { environment } from "@services/environment"
import { IPredictedArtwork } from "@typed/api/responses/MLPredictArtwork"
import { TPredictArtworkEntitiesRequest } from "@services/api/definitions/ml/requests"
import { createAxiosRequest } from "@services/api/implementations/rest/requestWrapper"

export const predictArtworkEntities: TPredictArtworkEntitiesRequest = async (props) => {
	try {
		const { data } = await createAxiosRequest({
			method: "POST",
			withAuth: true,
			url: environment.ML_ENTITY_PREDICT_ENDPOINT,
			data: { query: props.query },
		})
		const details: IPredictedArtwork = data

		return { status: true, details, error: null }
	} catch (error) {
		return { status: false, details: null, error: error }
	}
}
