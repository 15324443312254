import format from "date-fns/format"

export const prettyDate = (date: Date): string => {
	const m = new Date(date)
	const dateString =
		m.getUTCFullYear() +
		" " +
		("0" + (m.getUTCMonth() + 1)).slice(-2) +
		" " +
		("0" + m.getUTCDate()).slice(-2) +
		", " +
		("0" + m.getUTCHours()).slice(-2) +
		":" +
		("0" + m.getUTCMinutes()).slice(-2)

	return dateString
}

export function getFormattedTimestamp(date: Date): string {
	return format(date, "yyyy-MM-dd'T'HH:mm:ssxxx")
}
