import React, { useContext } from "react"
import PageLayout from "@layouts/PageLayout"
import EmbeddedContext from "@enhancers/EmbeddedContext"

const PostRegister: React.FC = () => {
	const { isEmbedded } = useContext(EmbeddedContext)

	return isEmbedded ? (
		<div className="d-flex justify-content-center align-items-center h-100">
			<div className="text-center">
				<h4>Welcome to Views</h4>
				<p>Close this window to sign in.</p>
			</div>
		</div>
	) : (
		<PageLayout className="container-fluid p-0">
			<div className="container h-100 ">
				<div className="row pt-5">
					<div className="col-sm-12 col-md-6 text-center">
						<img src="./graphics/app-preview.png" className="mock-app" alt="views app screenshot" />
						<h4 className="pt-4">Welcome to Views</h4>
						<p>You are all ready to go.</p>
					</div>
					<div className="col-sm-12 col-md-6 text-center my-auto">
						<a href="https://apps.apple.com/gb/app/views-art/id1538166066" rel="noreferrer nofollow" target="_blank">
							<img className="app-icon" src="./graphics/views-icon.png" alt="Views app icon" />
						</a>
						<h4 className="pt-5">
							Click{" "}
							<a href="https://apps.apple.com/gb/app/views-art/id1538166066" rel="noreferrer nofollow" target="_blank">
								here
							</a>{" "}
							to download Views app from the Appple Store
						</h4>
						<p className="pt-4">or</p>
						<p className="pt-4">
							<a href="./inventory">Continue to Views web platform</a>
						</p>
					</div>
				</div>
			</div>
		</PageLayout>
	)
}

export default PostRegister
