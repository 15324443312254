import React from "react"
import { CouldNotFind } from "@components/CouldNotFind/CouldNotFind"
import PageLayout from "@layouts/PageLayout"

const Page404: React.FC = () => {
	return (
		<PageLayout>
			<div className="container">
				<CouldNotFind />
			</div>
		</PageLayout>
	)
}

export default Page404
