/**
 * Returns an intersection of all provided arrays
 * @param arrays
 */
export default function getIntersection<T>(arrays: T[][]): T[] {
	if (arrays.length === 0) {
		return []
	}

	return arrays.reduce((a, b) => a.filter((c) => b.includes(c)))
}
