import { IAvailabilityWithActions, IStatus } from "@typed/api/responses/Workflows"

export function removeActionsFromStatuses(statuses: IStatus[]): IStatus[] {
	const result = statuses.map((status) => {
		return {
			...status,
			availabilities: status.availabilities.slice(),
		}
	})

	for (const status of result) {
		for (const availability of status.availabilities) {
			const availabilityWithActions = availability as IAvailabilityWithActions
			delete availabilityWithActions.actions
		}
	}

	return result
}
